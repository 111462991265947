import React, {useEffect} from 'react';

import {
  Button,
  Switch,
  FormControl,
  FormLabel,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import {GetPhotographerSearchDataType} from '../types';

const distances = [30, 60, 90, 120];

export default function ExtendedSearchTableMenu(props: {
  refetch: GetPhotographerSearchDataType;
  radius: number;
  setRadius: React.Dispatch<React.SetStateAction<number>>;
  alreadyBriefed: boolean;
  setAlreadyBriefed: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {radius, setRadius, alreadyBriefed, setAlreadyBriefed, refetch} = props;

  useEffect(() => {
    refetch(radius, alreadyBriefed);
  }, [radius, alreadyBriefed]);

  return (
    <Flex w='100%' justifyContent='end' direction={{base: 'column', md: 'row'}}>
      <FormControl display='flex' alignItems='center' w='210px'>
        <Switch
          mx='10px'
          id='custom-switch'
          size='md'
          colorScheme='teal'
          onChange={() => setAlreadyBriefed((prev) => !prev)}
          sx={{
            '.chakra-switch__track': {
              backgroundColor: '#6b52ff',
            },
            '.chakra-switch__thumb': {
              backgroundColor: 'white',
            },
          }}
        />
        <FormLabel htmlFor='custom-switch' mb='0'>
          {alreadyBriefed ? 'Already briefed' : 'Waiting for briefing'}
        </FormLabel>
      </FormControl>
      <Flex>
        {distances.map((d) => (
          <Button
            key={d}
            m='5px'
            variant={d === radius ? 'photonLight' : 'photonMedium'}
            onClick={() => setRadius(d)}
          >
            {d + 'km'}
          </Button>
        ))}
      </Flex>

      <Tooltip label="Feature is not available yet" hasArrow>
        <span>
          <Button m='5px' w='200px' variant='photonDark' isDisabled>
            Send message
          </Button>
        </span>
      </Tooltip>

    </Flex>
  );
}
