import {ListQueryProvider} from 'contexts/useListQueryContext';
import MenuLayout from '../../layouts/MenuLayout';
import RoleGuard from '../../layouts/RoleGuard';
import {adminRoles} from '../../variables/roles';
import PackageList from './PackageList';
import {PackageListFilterTypes} from './types';

export const defaultPackageFilters = {
  search: '',
  client: '',
  status: '',
  category: '',
  shootingType: '',
};

export default function PackageListPage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonJury.key,
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <ListQueryProvider<PackageListFilterTypes>
        defaultFilters={defaultPackageFilters}
      >
        <MenuLayout page={< PackageList />} />
      </ListQueryProvider>
    </RoleGuard>
  );
}
