import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {useSendAdminComment} from '../../../api/staff-api';
import {CreativeEvent} from '../types';
import moment from 'moment';


// eslint-disable-next-line
export default function Comments(props: { creativeData: any; callGetCreative: Function }) {
  const {creativeId} = useParams();
  const {creativeData, callGetCreative} = props;
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [comment, setComment] = useState('');
  const toast = useToast();

  const [{loading}, sendAdminComment] = useSendAdminComment(creativeId as string);

  const handleSendComment = async () => {
    try {
      await sendAdminComment({data: {comment}});
      toast({
        title: 'Comment sent',
        status: 'success',
        duration: 3000,
      });
      callGetCreative();
      onClose();
    } catch {
      toast({
        title: 'Error during sending comment',
        status: 'error',
        duration: 3000,
      });
    }
  };

  return (
    <Flex direction='column'>
      <Button variant='photonMedium' alignSelf='end' onClick={onOpen}>
        Add comment
      </Button>
      <Box my='20px' alignItems='center'>
        <Text fontWeight='bold' ml='10px'>
          Comments
        </Text>
        {creativeData.events
          // eslint-disable-next-line
          ?.filter((e: any) => e.event === 'admin-comment')
          ?.map((e: CreativeEvent) => {
            return (
              <Box bg='white' borderRadius='15px' m='10px' p='10px'>
                <Text>{e.details.comment}</Text>
                <Text align='right' color='lightgrey'>
                  {`${moment(e.time).format('HH:mm - DD.MM.YYYY')} by ${e.details.updatedBy.name}`}
                </Text>
              </Box>
            );
          })}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add comment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea onChange={(e) => setComment(e.target.value)}></Textarea>
          </ModalBody>

          <ModalFooter>
            <Button variant='photonMedium' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button variant='photonDark' isLoading={loading} w='auto' onClick={handleSendComment}>
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
