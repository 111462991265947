import {Icon} from '@chakra-ui/react';

import {MdBarChart} from 'react-icons/md';
import {FaCamera} from 'react-icons/fa';
import ActiveCreativesPage from './views/creative/ActiveCreativesPage';
import NewApplicantCreativesPage from 'views/creative/NewApplicantCreativesPage';
import InactiveCreativesPage from 'views/creative/InactiveCreativesPage';
import {VscLayers} from 'react-icons/vsc';
import ProjectListPage from 'views/projects/ProjectListPage';
import ProjectCalendarPage from 'views/projects/ProjectCalendarPage';
import {RiMoneyEuroCircleFill} from 'react-icons/ri';
import {RiUserSettingsFill} from 'react-icons/ri';
import DashboardPage from 'views/dashboard/DashboardPage';
import AdminUsersListPage from './views/admin/AdminUsersListPage';
import React from 'react';
import CreativeRatingsPage from './views/creative/ratings/RatingsPage';
import ClientListPage from 'views/clients/ClientListPage';
import RecruitmentPage from 'views/recruitment/RecruitmentPage';
import UnmatchedCitiesListPage from './views/settings/cities/UnmatchedCitiesListPage';
import ClientFinancesPage from 'views/finances/client/ClientFinancesPage';
import CreativeFinancesPage from 'views/finances/creative/CreativeFinancesPage';
import PhotoroomTemplatesPage from 'views/photoroom-templates/PhotoroomTemplatesPage';
import CountriesListPage from './views/settings/countries/CountriesListPage';
import EditorListPage from 'views/editor/EditorListPage';
import {IoColorWandSharp} from 'react-icons/io5';
import ClientUserListPage from 'views/client-user/ClientUserListPage';
import TransfersListPage from './views/settings/transfers/TransfersListPage';
import {FaUserTie} from 'react-icons/fa6';
import EditorFinancesPage from 'views/finances/editor/EditorFinancesPage';
import OffCreativesPage from 'views/creative/OffCreativesPage';
import PackageListPage from 'views/packages/PackageListPage';
import {TbPackages} from 'react-icons/tb';


const routes = [
  {
    name: 'Dashboard',
    layout: '/menu',
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: '/intel/dashboard',
    component: <DashboardPage />,
  },
  {
    name: 'Projects',
    layout: '/menu',
    icon: <Icon as={VscLayers} width='20px' height='20px' color='inherit' />,
    path: '/intel/projects',
    subRoutes: [
      {
        name: 'Calendar',
        layout: '/menu',
        path: '/intel/projects/calendar',
        component: <ProjectCalendarPage />,
      },
      {
        name: 'Project list',
        layout: '/menu',
        path: '/intel/projects/list',
        component: <ProjectListPage />,
      },
    ],
  },
  {
    name: 'Clients',
    layout: '/menu',
    icon: <Icon as={VscLayers} width='20px' height='20px' color='inherit' />,
    path: '/intel/clients',
    subRoutes: [
      {
        name: 'Client companies',
        layout: '/menu',
        path: '/intel/clients/companies',
        component: <ClientListPage />,
      },
      {
        name: 'Client users',
        layout: '/menu',
        icon: <Icon as={FaUserTie} width='20px' height='20px' color='inherit' />,
        path: '/intel/clients/users',
        component: <ClientUserListPage />,
      },
    ],
  },
  {
    name: 'Photographers',
    layout: '/menu',
    icon: <Icon as={FaCamera} width='20px' height='20px' color='inherit' />,
    path: '/intel/creatives',
    subRoutes: [
      {
        name: 'Onboarded Photographers',
        layout: '/menu',
        path: '/intel/creatives/active',
        component: <ActiveCreativesPage />,
      },
      {
        name: 'New Applicants',
        layout: '/menu',
        path: '/intel/creatives/new-applicant',
        component: <NewApplicantCreativesPage />,
      },
      {
        name: 'Deactivated photographers',
        layout: '/menu',
        path: '/intel/creatives/inactive',
        component: <InactiveCreativesPage />,
      },
      {
        name: 'Off photographers',
        layout: '/menu',
        path: '/intel/creatives/off',
        component: <OffCreativesPage />,
      },
      {
        name: 'Photographer ratings',
        layout: '/menu',
        path: '/intel/creatives/ratings',
        component: <CreativeRatingsPage />,
      },
    ],
  },
  {
    name: 'Packages',
    layout: '/menu',
    icon: <Icon as={TbPackages} width='20px' height='20px' color='inherit' />,
    path: '/intel/packages/list',
    component: <PackageListPage />,
  },
  {
    name: 'Editors',
    layout: '/menu',
    icon: <Icon as={IoColorWandSharp} width='20px' height='20px' color='inherit' />,
    path: '/intel/editors',
    component: <EditorListPage />,
  },
  {
    name: 'Finances',
    layout: '/menu',
    icon: <Icon as={RiMoneyEuroCircleFill} width='20px' height='20px' color='inherit' />,
    path: '/intel/finances',
    subRoutes: [
      {
        name: 'Client finances',
        layout: '/menu',
        path: '/intel/finances/client',
        component: <ClientFinancesPage />,
      },
      {
        name: 'Photographers finances',
        layout: '/menu',
        path: '/intel/finances/creative',
        component: <CreativeFinancesPage />,
      },
      {
        name: 'Editor finances',
        layout: '/menu',
        path: '/intel/finances/editor',
        component: <EditorFinancesPage />,
      },
    ],
  },
  {
    name: 'Settings',
    layout: '/menu',
    icon: <Icon as={RiUserSettingsFill} width='20px' height='20px' color='inherit' />,
    path: '/intel/settings',
    subRoutes: [
      {
        name: 'Admin Users',
        layout: '/menu',
        path: '/intel/settings/admins',
        component: <AdminUsersListPage />,
      },
      {
        name: 'Recruitment',
        layout: '/menu',
        path: '/intel/settings/recruitment',
        component: <RecruitmentPage />,
      },
      {
        name: 'Countries',
        layout: '/menu',
        path: '/intel/settings/countries',
        component: <CountriesListPage />,
      },
      {
        name: 'Unknown Cities',
        layout: '/menu',
        path: '/intel/settings/cities',
        component: <UnmatchedCitiesListPage />,
      },
      {
        name: 'Wise Transfers',
        layout: '/menu',
        path: '/intel/settings/transfers',
        component: <TransfersListPage />,
      },
      {
        name: 'Photoroom templates',
        layout: '/menu',
        path: '/intel/settings/photoroom-templates',
        component: <PhotoroomTemplatesPage />,
      },
    ],
  },
];

export default routes;
