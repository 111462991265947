import {createContext, ReactElement, useContext, useMemo, useState} from 'react';

import {useLocalStorage} from './useLocalStorage';
import ServerClient from '../api/basic/serverClient';


const AuthContext = createContext(null);

export const AuthProvider = ({
  children,
  userData = null,
}: {
  children: ReactElement;
  userData?: unknown;
}) => {
  const [userInStorage, saveUser] = useLocalStorage('user', userData);
  const [user, setUser] = useState(userInStorage);

  const login = (user: unknown) => {
    setUser(user);
    saveUser(user);
  };

  const logout = () => login(null);

  const getUser = async () => {
    try {
      // eslint-disable-next-line new-cap
      await ServerClient().get('/admin/user/profile');
    } catch {
      logout();
      return null;
    }

    return user;
  };

  const value = useMemo(
    () => ({
      user,
      getUser,
      login,
      logout,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
