import React from 'react';
import {Box, Text} from '@chakra-ui/react';

function ProfilePicture(props: { name: string }) {
  const monogram = props.name
    .split(' ')
    .map((word) => {
      const specialCases = ['Sz', 'Zs', 'Cs', 'Dz', 'Gy', 'Ny', 'Ty'];
      const firstTwoChars = word.slice(0, 2);
      if (specialCases.includes(firstTwoChars)) {
        return firstTwoChars;
      } else {
        return word.charAt(0);
      }
    })
    .join('');

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      h='100%'
      w='100%'
      borderRadius='50%'
      backgroundColor={'#6b52ff'}
      color={'white'}
      opacity={0.4}
    >
      <Text fontWeight='bold' fontSize='20px'>
        {monogram}
      </Text>
    </Box>
  );
}

export default ProfilePicture;
