import React, {useCallback, useState} from 'react';
import {Flex} from '@chakra-ui/react';
import {useParams} from 'react-router-dom';

import {
  useGetCreativesInWorkingAreaLists,
  useGetCreativesWithHomeTownInRadius,
} from 'api/projects';
import AvailableCreativesTable from './AvailableCreativesTable';
import BusyCreativesTable from './BusyCreativesTable';

export default function PhotographerSearch() {
  const {projectId} = useParams();
  const [radius, setRadius] = useState(30);
  const [alreadyBriefed, setAlreadyBriefed] = useState(true);

  const [
    {data: workingAreaListData},
    getCreativesInWorkingArea,
  ] = useGetCreativesInWorkingAreaLists(projectId);

  const [
    {data: homeTownListData},
    getCreativesWithHomeTownInRadius,
  ] = useGetCreativesWithHomeTownInRadius(projectId);

  const getHomeTownTableData = useCallback(
    async (radius: number, alreadyBriefed: boolean) => {
      try {
        await getCreativesWithHomeTownInRadius({
          params: {
            radius,
            alreadyBriefed,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getCreativesWithHomeTownInRadius],
  );

  const refetchTables = async ()=> {
    getCreativesInWorkingArea();
    getHomeTownTableData(radius, alreadyBriefed);
  };

  return (
    <Flex direction='column'>
      {workingAreaListData?.availableCreatives && (
        <AvailableCreativesTable
          data={workingAreaListData.availableCreatives}
          label={'Available photographers in the area'}
          currency={workingAreaListData.currency}
          refetch={refetchTables}
        />
      )}
      {workingAreaListData?.newCreatives && (
        <AvailableCreativesTable
          data={workingAreaListData?.newCreatives}
          label={'New photographers in the area'}
          currency={workingAreaListData.currency}
          refetch={refetchTables}
        />
      )}
      {workingAreaListData?.busyCreativesInWorkingArea && (
        <BusyCreativesTable
          data={workingAreaListData?.busyCreativesInWorkingArea}
          label={'Photographers who are busy'}
        />
      )}
      {homeTownListData && (
        <AvailableCreativesTable
          data={homeTownListData}
          label={'Extend search'}
          refetch={refetchTables}
          currency={workingAreaListData?.currency}
          radius={radius}
          setRadius={setRadius}
          alreadyBriefed={alreadyBriefed}
          setAlreadyBriefed={setAlreadyBriefed}
        />
      )}
    </Flex>
  );
}
