import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import {
  Box,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import momentPlugin from '@fullcalendar/moment';
import {useState} from 'react';
import {
  statusColors,
  StyleWrapper,
} from '../variables/styles';
import {useSearchParams} from 'react-router-dom';
import config from 'config';
import {ProjectListFilterTypes} from '../types';
import {ProjectState} from '../constants';


// eslint-disable-next-line
const transformCalendarData = (data: any) => {
  // eslint-disable-next-line
  return data.map((project: any) => {
    const status = project.status as keyof typeof statusColors;

    return {
      title: project.projectName,
      start: new Date(project.shootingTime),
      end: new Date(project.endOfShooting),
      display: 'block',
      backgroundColor: statusColors[status].primary,
      textColor: statusColors[status].secondary,
      borderColor: project.status === ProjectState.CREATED ? '#031c21' : 'transparent',
      id: project._id,
      extendedProps: project,
    };
  });
};

const Calendar = ({
  projectsData,
  refetchData,
  filters,
  setFilters,
}: {
  // eslint-disable-next-line
  projectsData: any;
  // eslint-disable-next-line
  refetchData: Function;
  filters: ProjectListFilterTypes;
  // eslint-disable-next-line
  setFilters: Function;
}) => {
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState(() => {
    return searchParams.get('view') || 'timeGridWeek';
  });
  const [date, setDate] = useState(() => {
    const storedDate = searchParams.get('date');
    return searchParams.get('date') || storedDate ? new Date(storedDate) : new Date();
  });

  // eslint-disable-next-line
  const handleDateChange = (dateInfo: any) => {
    const newViewType = dateInfo.view.type;
    let newDate = moment(dateInfo.start);
    if (newViewType === 'dayGridMonth') {
      newDate = moment(newDate).add(7, 'days').startOf('month');
    }
    setDate(newDate.toDate());
    setView(newViewType);
    searchParams.set('date', newDate.toString());

    const updatedFilters = {
      ...filters,
      from: moment(newDate),
      to: moment(dateInfo.end),
    };
    setFilters(updatedFilters);

    searchParams.set('view', newViewType);
    setSearchParams(searchParams.toString());

    if (shouldRefetch) {
      const toDate = newViewType === 'dayGridMonth'
        ? moment(newDate).add(7, 'days').endOf('month')
        : moment(dateInfo.end);
      refetchData({...filters, from: newDate, to: toDate});
    }
    setShouldRefetch(true);
  };

  // eslint-disable-next-line
  const handleEventClick = (clickInfo: any) => {
    window.open(
      `${config.newAdminUrl}/intel/project/${clickInfo.event.extendedProps._id}`,
      '_blank',
    );
  };

  return (
    <>
      <StyleWrapper>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, momentPlugin]}
          contentHeight='400'
          events={transformCalendarData(projectsData)}
          editable={true}
          height='900px'
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          slotMinTime='00:00:00'
          titleFormat='MMMM D, YYYY'
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
          }}
          eventContent={(arg) => {
            return (
              <Box p='2px' fontSize='15px'>
                <Text fontWeight='bold' whiteSpace='normal' overflow='visible'>
                  {arg.event.title}
                </Text>
                {view !== 'timeGridWeek' && (
                  <Text>
                    {`${moment(arg.event.start).format('HH:mm')}
                     - ${moment(arg.event.end).format('HH:mm')} `}
                  </Text>
                )}
              </Box>
            );
          }}
          eventMaxStack={1}
          eventClick={handleEventClick}
          initialView={view}
          initialDate={date}
          datesSet={handleDateChange}
          fixedWeekCount={false}
          firstDay={1}
        />
      </StyleWrapper>
    </>
  );
};

export default Calendar;
