import React, {useState} from 'react';

import {Box, Button, IconButton, Flex, Tag} from '@chakra-ui/react';
import {AiOutlineDelete} from 'react-icons/ai';

import DevelopmentTable from '../../components/DevelopmentTable';
import {useGetAdminUsers} from '../../api/adminUsers';
import {adminRoles} from '../../variables/roles';
import RoleGuard from '../../layouts/RoleGuard';
import {Cell} from 'react-table';
import {useAuth} from '../../hooks/useAuth';
import DeleteAdminUserModal from './DeleteAdminUserModal';
import UpdateAdminUserModal, {
  defaultForm,
  roleOptions,
  roleKeyToLabelMap,
} from './UpdateOrCreateAdminUserModal';
import AdminListMenu from './AdminListMenu';

export default function AdminUsersTable({status}: { status: 'deleted' | 'active' }) {
  const isDeleted = status === 'deleted';

  const {user} = useAuth();

  const [
    {data: adminsData, loading: getAdminUsersLoading, error: getAdminUsersError},
    getAdminUsers,
  ] = useGetAdminUsers(status);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({name: '', id: ''});
  const [formData, setFormData] = useState(defaultForm);

  const openEditModal = (id: string) => {
    const data = Object.assign(
      {},
      // eslint-disable-next-line
      adminsData.find((admin: any) => admin._id === id),
    );
    data.roles
      = data.roles?.map((role: string) =>
        roleOptions.find((roleOption) => roleOption.value === role),
      ) ?? [];

    setFormData(data);
    setIsEditModalOpen(true);
  };

  const openDeleteModal = (id: string) => {
    const data = Object.assign(
      {},
      // eslint-disable-next-line
      adminsData.find((admin: any) => admin._id === id),
    );
    setDeleteData({name: data.name, id});
    setIsDeleteModalOpen(true);
  };

  const columnsDataAdminUser = {
    appearance: {
      columnDef: {
        Header: 'Name',
        accessor: 'name',
      },
    },
    email: {
      columnDef: {
        Header: 'Email',
        accessor: 'email',
      },
    },
    phone: {
      columnDef: {
        Header: 'Phone',
        accessor: 'phone',
      },
    },
    roles: {
      columnDef: {
        Header: 'Roles',
        accessor: 'roles',
        width: 500,
      },
      // eslint-disable-next-line
      renderer: (cell: any) => (
        <Flex align='center' gap={1} wrap='wrap'>
          {cell.value.map((tagTitle: string) => (
            <Tag size='sm' fontSize='sm' fontWeight='700' key={tagTitle} bg='#a6a3ff'>
              {roleKeyToLabelMap.get(tagTitle) || tagTitle}
            </Tag>
          ))}
        </Flex>
      ),
    },
    _id: {
      columnDef: {
        Header: 'Actions',
        accessor: '_id',
        width: 250,
      },
      // eslint-disable-next-line
      renderer: (cell: Cell<any, any>) => {
        const id = cell.value;
        return (
          <RoleGuard
            roles={[adminRoles.RolePhotonAdmin.key, adminRoles.RolePhotonSuperAdmin.key]}
            additionalCondition={
              (cell.row.original.roles.includes(adminRoles.RolePhotonSuperAdmin.key)
                && user?.roles.includes(adminRoles.RolePhotonSuperAdmin.key))
              || !cell.row.original.roles.includes(adminRoles.RolePhotonSuperAdmin.key)
            }
          >
            <Flex gap={10} justifyContent='flex-start' width={200}>
              <Button variant='photonDark' onClick={() => openEditModal(id)}>
                Edit
              </Button>
              {id !== user.userId && !isDeleted && (
                <IconButton
                  onClick={() => openDeleteModal(id)}
                  colorScheme='photonOffWhite'
                  color='photonDeepBlue'
                  aria-label='Delete user'
                  icon={<AiOutlineDelete />}
                />
              )}
            </Flex>
          </RoleGuard>
        );
      },
    },
  };

  return (
    <Box pt={{base: '30px', md: '80px', xl: '80px'}}>
      {getAdminUsersError && <div>Error during getting data: {getAdminUsersError.message}</div>}
      <DevelopmentTable
        variant='underlineRows'
        menuComp={<AdminListMenu onRefresh={getAdminUsers} />}
        label={`${status[0].toUpperCase() + status.substring(1)} Admins`}
        columnsData={
          // eslint-disable-next-line
          Object.values(columnsDataAdminUser).map((column: any) => column.columnDef)
        }
        renderersData={columnsDataAdminUser}
        tableData={adminsData}
        total={adminsData?.length}
        isLoading={getAdminUsersLoading}
      />
      <UpdateAdminUserModal
        selectedAdminDoc={formData}
        isOpen={isEditModalOpen}
        onClose={(successful?: boolean) => {
          setIsEditModalOpen(false);
          if (successful) {
            getAdminUsers();
          }
        }}
      />
      <DeleteAdminUserModal
        userData={deleteData}
        isOpen={isDeleteModalOpen}
        onClose={(successful?: boolean) => {
          setIsDeleteModalOpen(false);
          setDeleteData({name: '', id: ''});
          if (successful) {
            getAdminUsers();
          }
        }}
      />
    </Box>
  );
}
