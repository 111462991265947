import {
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import CreateEditorModal from './CreateEditorModal';

export default function EditorListMenu(props: { [x: string]: any }) {
  const {onRefresh} = props;
  const {isOpen: isOpenCreateModal, onOpen: onOpenCreateModal, onClose: onCloseCreateModal} = useDisclosure();

  return <>
    <Button
      variant='photonLight'
      onClick={() => onOpenCreateModal()}
    >
      Add
    </Button>
    <CreateEditorModal
      isOpen={isOpenCreateModal}
      onClose={
        (success: any) => {
          onCloseCreateModal();
          if (success) {
            onRefresh();
          }
        }
      }
    />
  </>;
}
