import React, {useEffect} from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Text,
  useClipboard,
  Select,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {LuCopy} from 'react-icons/lu';
import {LuCopyCheck} from 'react-icons/lu';
import {useUpdateClient} from 'api/clients';
import {OrderAmountCategory, orderAmountCategoryLabels, ProjectCategory, projectCategoryLabels} from '../constants';
import useCountryList from 'hooks/useCountryList';
import Bugsnag from '@bugsnag/js';

export default function ClientBasicData(props: { clientData: any }) {
  const {clientData} = props;
  const {clientId} = useParams();
  const toast = useToast();
  const {onCopy, hasCopied} = useClipboard(clientData._id);
  const {countryList} = useCountryList();


  const defaultValues = {
    name: clientData.name,
    location: clientData.location,
    country: clientData.country,
    legalContact: clientData.legalContact,
    industryCategory: clientData.industryCategory,
    orderAmountCategory: clientData.orderAmountCategory,
    comment: clientData.comment,
  };

  const {
    handleSubmit,
    register,
    watch,
  } = useForm({
    defaultValues,
  });

  const [{error: updateClientError, loading}, updateClient] = useUpdateClient(clientId);

  const handleSaveClick = async (data: any) => {
    try {
      await updateClient({data});
      toast({
        title: 'Client updated',
        status: 'success',
        duration: 3000,
      });
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  useEffect(
    () => {
      if (updateClientError) {
        toast(
          {
            title: 'Update is unsuccessful!',
            status: 'error',
            duration: 3000,
          },
        );
      }
    },
    [updateClientError, toast],
  );

  const watchCountry = watch('country');

  return (
    <Box mt='50px'>
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <Flex justifyContent='space-around' direction={{base: 'column', md: 'row'}} alignItems='start'>
          <Flex direction='column' alignItems={'center'} justifyContent={'center'}>
            <br/>
            <FormControl id='name' mb={4}>
              <FormLabel>Company name</FormLabel>
              <Input variant='main' {...register('name')} />
            </FormControl>

            <Flex direction='column'>
              <FormLabel>Id</FormLabel>
              <Flex w={{base: '90vw', md: '500px'}}>
                <Input variant='main' disabled value={clientData._id} />
                <Button ml='5px' onClick={onCopy}>
                  {hasCopied ? <LuCopyCheck size={25} /> : <LuCopy size={25} />}
                </Button>
              </Flex>
            </Flex>

            <FormControl id='location' mb={4}>
              <FormLabel>Address</FormLabel>
              <Input variant='main' {...register('location')} />
            </FormControl>

            <FormControl id='country' mb={4}>
              <FormLabel>Country</FormLabel>
              <Select alignItems='center' bg='white' borderRadius='15px' value={watchCountry} {...register('country')}>
                {countryList.map((c)=> <option value={c.value} key={c.value}>{c.label} </option>)}
              </Select>
            </FormControl>

            <FormControl id='industryCategory' mb={4}>
              <FormLabel>Industry category</FormLabel>
              <Select {...register('industryCategory')} alignItems='center' defaultValue='en' bg='white' borderRadius='15px'>
                {Object.values(ProjectCategory).map((c)=> <option key={c} value={c}>{projectCategoryLabels[c]}</option>)}
              </Select>
            </FormControl>

            <FormControl id='orderAmountCategory' mb={4}>
              <FormLabel>Order amount category</FormLabel>
              <Select {...register('orderAmountCategory')} alignItems='center' defaultValue='en' bg='white' borderRadius='15px'>
                {Object.values(OrderAmountCategory).map((c)=> <option key={c} value={c}>{orderAmountCategoryLabels[c]}</option>)}
              </Select>
            </FormControl>


          </Flex>

          <Flex direction='column' alignItems={'center'} justifyContent={'center'}>
            <Text>Contact</Text>
            <FormControl id='name' mb={4}>
              <FormLabel>Name</FormLabel>
              <Input variant='main' {...register('legalContact.name')} />
            </FormControl>

            <FormControl id='location' mb={4}>
              <FormLabel>Phone</FormLabel>
              <Input variant='main' {...register('legalContact.phone')} />
            </FormControl>

            <FormControl id='country' mb={4}>
              <FormLabel>email</FormLabel>
              <Input variant='main' {...register('legalContact.email')} />
            </FormControl>

            <FormControl id='comment' mb={4}>
              <FormLabel>Message</FormLabel>
              <Input variant='main' {...register('comment')} />
            </FormControl>
          </Flex>
        </Flex>
        <Flex justifyContent='center' m='50px'>
          <Button isLoading={loading} type='submit' variant='photonDark' w='200px'>
            Save
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
