import {useEffect, useMemo, useState} from 'react';
import {Box, Button, Divider, Flex, FormControl, FormLabel, Switch, Text, useToast} from '@chakra-ui/react';
import {useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {useUpdateProject, useUpdateProjectLateFeeCalculation} from 'api/projects';
import {CustomFieldStyle} from '../variables/styles';
import {useFormContext} from 'contexts/useFormContext';
import {ProjectDrivers} from '../../../constants';
import {CustomField} from './ProjectBasicData';
import {ProjectEvent, ProjectPrice, ProjectState} from '../constants';
import {ProjectEventType} from '../types';
import ProjectPriceEditorField from './ProjectPriceEditorField';
import CreativeFeeEditorField from './CreativeFeeEditorField';
import ContractualFeeHandler from './CreativeContractualFeeField';


// eslint-disable-next-line
export default function ProjectImagesAndFinances(props: { projectData: any; refetch: Function }) {
  const {projectData, refetch} = props;

  const {projectId} = useParams();
  const toast = useToast();
  const [editField, setEditField] = useState('');
  const {setIsFormDirty, shouldReset, setShouldReset} = useFormContext();

  const defaultValues = useMemo(
    () => ({
      uploadedPhotosNumber: projectData.uploadedPhotosNumber,
      uploadedPhotosByEditorNumber: projectData.uploadedPhotosByEditorNumber,
      editedPhotosNumber: projectData.editedPhotosNumber,
      expectedPhotoNumber: projectData.expectedPhotoNumber,
    }),
    [projectData],
  );

  const {
    handleSubmit,
    register,
    formState: {isDirty},
    getValues,
    reset,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (isDirty) {
      setShouldReset(false);
      setIsFormDirty(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    if (shouldReset) reset(defaultValues);
  }, [shouldReset]);

  const [
    {
      loading: updateProjectInProgress,
      error: updateProjectError,
    },
    updateProject,
  ] = useUpdateProject(projectId);

  const [
    {
      loading: setupLateFeeCalculationInProgress,
      error: setupLateFeeCalculationError,
    },
    setupLateFeeCalculation,
  ] = useUpdateProjectLateFeeCalculation(projectId);

  useEffect(() => {
    if (setupLateFeeCalculationError) {
      toast({
        title: 'Error during manage late fee calculation.',
        status: 'error',
      });
    }
  }, [setupLateFeeCalculationError]);

  useEffect(() => {
    if (updateProjectError) {
      toast({
        title: updateProjectError.response.data.message,
        status: 'error',
      });
    }
  }, [updateProjectError]);

  // eslint-disable-next-line
  const handleSaveClick = async (data: any) => {
    if (updateProjectInProgress) {
      return;
    }

    const convertedData = {
      ...data,
      uploadedPhotosNumber: data.uploadedPhotosNumber && Number(data.uploadedPhotosNumber),
      uploadedPhotosByEditorNumber: data.uploadedPhotosByEditorNumber && Number(data.uploadedPhotosByEditorNumber),
      editedPhotosNumber: data.editedPhotosNumber && Number(data.editedPhotosNumber),
      expectedPhotoNumber: data.expectedPhotoNumber && Number(data.expectedPhotoNumber),
    };

    await updateProject({data: convertedData});

    toast({
      title: 'Project updated',
      status: 'success',
      duration: 3000,
    });

    refetch();
    setIsFormDirty(false);
  };

  return <Box>
    <form onSubmit={handleSubmit(handleSaveClick)}>
      <Flex
        justifyContent='space-around'
        alignItems={{base: 'center', lg: 'start'}}
        direction={{base: 'column', lg: 'row'}}
      >
        <Flex direction='column' p='10px' m='5px' w={{base: '90vw'}} maxW='420px'>
          <Text align='center' fontWeight='bold'>
            Numbers
          </Text>
          {projectData.expectedPhotoNumber !== undefined && (
            <CustomField
              label='Expected number of images'
              id='expectedPhotoNumber'
              getValues={getValues}
              register={register}
              setEditField={setEditField}
              editField={editField}
              shouldReset={shouldReset}
              defaultValue={defaultValues.expectedPhotoNumber}
            />
          )}
          <CustomField
            label='Uploaded images by photographer'
            id='uploadedPhotosNumber'
            getValues={getValues}
            register={register}
            setEditField={setEditField}
            editField={editField}
            shouldReset={shouldReset}
            defaultValue={defaultValues.uploadedPhotosNumber}
          />
          {
            projectData.uploadedPhotosByEditorNumber !== undefined
            && <CustomField
              label='Uploaded images by editor'
              id='uploadedPhotosByEditorNumber'
              getValues={getValues}
              register={register}
              setEditField={setEditField}
              editField={editField}
              shouldReset={shouldReset}
              defaultValue={defaultValues.uploadedPhotosByEditorNumber}
            />
          }
          {
            projectData.editedPhotosNumber !== undefined
            && projectData.backgroundRemovalEnabled
            && <FormControl>
              <FormLabel>Images edited by AI</FormLabel>
              <Flex {...CustomFieldStyle}>
                <Text>{projectData.editedPhotosNumber}</Text>
              </Flex>
            </FormControl>
          }
          {
            projectData.dynamicExtraPicsNum !== undefined
            && projectData.dynamicPricing
            && <FormControl>
              <FormLabel>Extra photos</FormLabel>
              <Flex {...CustomFieldStyle}>
                <Text>{projectData.dynamicExtraPicsNum}</Text>
              </Flex>
            </FormControl>
          }
          {
            projectData.extras.map(
              // eslint-disable-next-line
              (e: any, i: any) => <FormControl key={i}>
                <FormLabel>{e.name}</FormLabel>
                <Flex {...CustomFieldStyle}>
                  <Text>{`${e.quantity} ${e.unit}`}</Text>
                </Flex>
              </FormControl>,
            )
          }
        </Flex>

        <Flex direction='column' p='10px' m='5px' w={{base: '90vw'}} maxW='420px'>
          <Text align='center' fontWeight='bold'>
            {`Prices (${projectData.currency})`}
          </Text>
          <ProjectPriceEditorField
            label='Client price'
            type={
              projectData.status === ProjectState.CANCELED
                ? ProjectPrice.CLIENT_CANCELLATION_FEE
                : ProjectPrice.CLIENT_PRICE
            }
            projectData={projectData}
            refetch={refetch}
          />
          <ProjectPriceEditorField
            label='Photographer price'
            type={ProjectPrice.CREATIVE_PRICE}
            projectData={projectData}
            refetch={refetch}
          />
          {
            projectData.manualEditingEnabled
            && <ProjectPriceEditorField
              label='Editor price'
              type={ProjectPrice.EDITOR_PRICE}
              projectData={projectData}
              refetch={refetch}
            />
          }

          {
            projectData.events?.some(
              (e: ProjectEventType) => e.event === ProjectEvent.CANCEL_BY_CREATIVE && e.details.creativeFee > 0,
            ) && <CreativeFeeEditorField
              label="Photographer's cancellation penalty"
              type={ProjectPrice.CREATIVE_FEE}
              projectData={projectData}
              refetch={refetch}
            />
          }

          {
            projectData.events?.some(
              (e: ProjectEventType) => e.event === ProjectEvent.CREATIVE_LATE_FEE && e.details.creativeFee > 0,
            ) && <CreativeFeeEditorField
              label="Photographer's late fee"
              type={ProjectPrice.CREATIVE_LATE_FEE}
              projectData={projectData}
              refetch={refetch}
            />
          }

          <ContractualFeeHandler
            label="Photographer's contractual penalty"
            projectData={projectData}
            refetch={refetch}
          />
        </Flex>
      </Flex>
      <Flex justifyContent='center' m='50px'>
        <Button isLoading={updateProjectInProgress} type='submit' variant='photonDark' w='200px'>
          Save
        </Button>
      </Flex>
    </form>

    <Divider/>

    {/* Enable late fee calculation? */}
    <Flex dir='column' p='20px 10px' m='5px'>
      <FormControl display='flex' alignItems='center'>
        <FormLabel htmlFor='late-fee-calculation' mb='0'>
          Checking for delay in photographer delivery
        </FormLabel>
        <Switch
          id='late-fee-calculation'
          colorScheme='brand'
          onChange={async (e) => {
            await setupLateFeeCalculation({
              data: {lateFeeCalculationDisabled: !e.target.checked},
            });
            toast({
              title: 'Late fee calculation updated',
              status: 'success',
              duration: 3000,
            });
            refetch();
          }}
          isDisabled={setupLateFeeCalculationInProgress}
          isChecked={!projectData.projectDrivers?.includes(ProjectDrivers.IGNORE_LATE_FEE)}
        />
      </FormControl>
    </Flex>
  </Box>;
}
