import {Box, Flex, Input, Link, Text, Textarea, Tooltip, useToast} from '@chakra-ui/react';
import SimpleTable from 'components/SimpleTable';
import {NavLink, useParams} from 'react-router-dom';
import {Cell} from 'react-table';
import {MdDone, MdOutlineCancel} from 'react-icons/md';
import {IoMdClose} from 'react-icons/io';
import ExtendedSearchTableMenu from './ExtendedSearchCreativeTableMenu';
import {useUpdateCreativeContact} from 'api/projects';
import {useEffect, useState} from 'react';
import Bugsnag from '@bugsnag/js';
import {Contact, GetPhotographerSearchDataType} from '../types';
import {FaRegSave} from 'react-icons/fa';
import {onboardingOrPackageStatus} from '../constants';
import {AiFillStar, AiOutlineExclamation} from 'react-icons/ai';
import {TbPackageOff} from 'react-icons/tb';

const CustomIcon = ({value}: { value: boolean }) => {
  return value ? <MdDone size={30} color='green' /> : <IoMdClose size={30} color='red' />;
};

const CustomInput = ({
  initialValue,
  name,
  id,
  onUpdate,
  handleSave,
  currency,
}: {
  initialValue: string | number;
  name?: string;
  id?: string;
  onUpdate: (value: string | number, name?: string, id?: string) => void;
  handleSave: (id: string) => void
  currency?: string;
}) => {
  const [value, setValue] = useState(initialValue);
  const [showSave, setShowSave] = useState(true);

  const handleBlur = () => {
    onUpdate(value, name, id);
  };


  const handleChange =(value: string | number )=>{
    setValue(value);
    setShowSave(true);
  };

  return (<Flex alignItems='center'>
    {name === 'alternativeDates' ? (
      <Textarea
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
        value={value}
        width='300px'
        borderColor='secondaryGray.100'
        height='50px'
        color='black'
      />
    ) : (
      <Flex color='black' alignItems='center'>
        <Input
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleBlur}
          variant='main'
          value={value}
          width='80px'
          type='number'
          padding='10px'
          textAlign='right'
          m='5px'
        />
        {currency === 'HUF' ? 'Ft' : '€'}
      </Flex>
    )}
    <Flex
      direction='column'
      w='40px'
      h='50px'
      alignItems='center'
      justifyContent='space-around'
      display={initialValue !== value && showSave ? 'flex': 'none'}>
      <FaRegSave
        size='20'
        onClick={()=>{
          handleSave(id);
          setShowSave(false);
        }}
        cursor='pointer'
      />
      <MdOutlineCancel
        size='20'
        onClick={()=>{
          handleChange(initialValue);
          setShowSave(false);
        }}
        cursor='pointer'
      />
    </Flex>
  </Flex>);
};

export default function AvailableCreativesTable({
  data,
  label,
  refetch,
  currency,
  radius,
  setRadius,
  alreadyBriefed,
  setAlreadyBriefed,
}: {
  data: Contact[];
  label: string;
  refetch?: GetPhotographerSearchDataType;
  currency: string;
  radius?: number
  setRadius?: React.Dispatch<React.SetStateAction<number>>
  alreadyBriefed?: boolean
  setAlreadyBriefed?: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const toast = useToast();
  const {projectId} = useParams();
  const [rows, setRows] = useState(data);

  const isSearchByWorkingArea = !!data[0]?.closestArea;
  const [{error}, updateContact] = useUpdateCreativeContact(projectId);

  const handleSave = async (id: string, adminFlagChange?: boolean) => {
    try {
      const contact = rows.find((r: Contact) => r.creativeId === id);
      await updateContact({
        data: {
          creativeId: contact.creativeId,
          name: contact.name,
          travelCosts: Number(contact.travelCosts) || 0,
          alternativeDates: contact.alternativeDates || '',
          adminFlag: adminFlagChange ? !contact.adminFlag : contact.adminFlag,
        },
      });
      toast({
        title: `${contact.name} contact info updated`,
        status: 'success',
        isClosable: true,
      });
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
    refetch();
  };

  useEffect(() => {
    if (error) {
      toast({
        title: 'Error updating contact info',
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  const handleAdminFlagChange = (id: string, value: boolean)=> {
    setRows((prev: Contact[]) =>
      prev.map((r: Contact) => r.creativeId === id ? {...r, adminFlag: value} : r,
      ));
    handleSave(id, true);
  };

  const columnsData = {
    adminFlag: {
      columnDef: {
        Header: 'Flag',
        accessor: 'adminFlag',
      },
      renderer: (cell: Cell<Contact>) => {
        const {creativeId} = cell.row.original;
        const row = rows.find((r)=> r.creativeId === creativeId);

        return (
          <AiFillStar
            color={row?.adminFlag? '#6b52ff' : 'lightgrey'}
            size='25'
            onClick={() => {
              handleAdminFlagChange(creativeId, row.adminFlag? false : true);
            }}
          />
        );
      },
    },
    name: {
      columnDef: {
        Header: 'Photographer',
        accessor: 'name',
      },
      renderer: (cell: Cell<Contact>) => (
        <Link
          as={NavLink} to={`/intel/creative/${cell.row.original.creativeId}`}
          fontWeight='bold'
          color='black'
        >
          {cell.value}
        </Link>
      ),

    },
    tags: {
      columnDef: {
        Header: 'To do',
        accessor: 'tags',
      },
      renderer: (cell: Cell) => {
        return (
          <Flex>
            {cell.value.map((tag: string) => {
              if (tag === onboardingOrPackageStatus.REGISTRATION_NOT_FINISHED) {
                return (
                  <Tooltip label="Registration is not finished" key={tag} hasArrow>
                    <span>
                      <AiOutlineExclamation size={25} />
                    </span>
                  </Tooltip>
                );
              } else if (tag === onboardingOrPackageStatus.NOT_ON_PACKAGE) {
                return (
                  <Tooltip label="Not on package" key={tag} hasArrow>
                    <span>
                      <TbPackageOff size={25} />
                    </span>
                  </Tooltip>
                );
              } else {
                return null;
              }
            })}
          </Flex>
        );
      },
    },
    closestArea: {
      columnDef: {
        Header: isSearchByWorkingArea ? 'Closest area': 'Home town',
        accessor: 'closestArea',
      },
      renderer: (cell: Cell<Contact>) => {
        const {closestArea, distance, homeTown} = cell.row.original;
        return closestArea ? (
          <Box color='black'>
            <Text>{`${closestArea?.name} + ${closestArea?.radius} km`}</Text>
            <Text>{`distance: ${closestArea?.distance} km`}</Text>
          </Box>
        ) : (
          <Box color='black'>
            <Text>{homeTown}</Text>
            <Text>{`distance: ${distance} km`}</Text>
          </Box>
        );
      },
    },
    travelCosts: {
      columnDef: {
        Header: 'Travel costs',
        accessor: 'travelCosts',
      },
      renderer: (cell: Cell<Contact>) => {
        const {creativeId, travelCosts} = cell.row.original;
        return (
          <CustomInput
            key={creativeId}
            initialValue={travelCosts || ''}
            name='travelCosts'
            id={creativeId}
            currency={currency}
            onUpdate={(value, name, id) => {
              setRows((prev: Contact[]) =>
                prev.map((r: Contact) => (r.creativeId === id ? {...r, [name]: value} : r)),
              );
            }}
            handleSave={handleSave}
          />
        );
      },
    },
    alternativeDates: {
      columnDef: {
        Header: 'Alternative dates',
        accessor: 'alternativeDates',
      },
      renderer: (cell: Cell<Contact>) => {
        const {creativeId, alternativeDates} = cell.row.original;

        return (
          <CustomInput
            key={creativeId}
            initialValue={alternativeDates || ''}
            name='alternativeDates'
            id={creativeId}
            onUpdate={(value, name, id) => {
              setRows((prev: Contact[]) =>
                prev.map((r: Contact) => (r.creativeId === id ? {...r, [name]: value} : r)),
              );
            }}
            handleSave={handleSave}
          />
        );
      },
    },
    whatsApp: {
      columnDef: {
        Header: 'WhatsApp',
        accessor: 'whatsApp',
      },
      renderer: (cell: Cell) => <CustomIcon value={cell.value} />,
    },

    sentEmail: {
      columnDef: {
        Header: 'Email',
        accessor: 'sentEmail',
      },
      renderer: (cell: Cell) => <CustomIcon value={cell.value} />,
    },
    interested: {
      columnDef: {
        Header: 'Interested',
        accessor: 'interested',
      },
      renderer: (cell: Cell) => (
        <Flex>
          {cell.value === true && <MdDone size={30} color='green' />}
          {cell.value === false && <IoMdClose size={30} color='red' />}
        </Flex>
      ),
    },
  };

  return (
    <Box mb='30px'>
      <SimpleTable
        variant='underlineRows'
        menuComp={!isSearchByWorkingArea ? <ExtendedSearchTableMenu
          refetch={refetch}
          radius={radius}
          setRadius={setRadius}
          alreadyBriefed = {alreadyBriefed}
          setAlreadyBriefed = {setAlreadyBriefed}

        /> : null}
        label={label}
        columnsData={Object.values(columnsData).map((column: any) => column.columnDef)}
        renderersData={columnsData}
        tableData={data}
        total={data?.length}
      />
    </Box>
  );
}
