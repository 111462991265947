import useAxios from './basic/use-axios';


export const useGetAnnualIncome = () =>
  useAxios(
    {
      url: '/admin/dashboard/annual-income',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetAnnualProjects = () =>
  useAxios(
    {
      url: '/admin/dashboard/annual-projects',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetProjectStatistics = () =>
  useAxios(
    {
      url: '/admin/dashboard/statistics',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetTopClientsByProjectNumber = () =>
  useAxios(
    {
      url: '/admin/dashboard/top-clients-by-project-number',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetTopClientsByIncome = () =>
  useAxios(
    {
      url: '/admin/dashboard/top-clients-by-income',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetAverageStats = () =>
  useAxios(
    {
      url: '/admin/dashboard/average-stats',
      method: 'GET',
    },
    {manual: true},
  );
