import React, {useCallback, useEffect} from 'react';
import {Flex, Select} from '@chakra-ui/react';
import {useGetClientCountries, useGetClientNames} from '../../../api/clients';
import useCountryList from 'hooks/useCountryList';
import {sortCountries} from 'utils/sort-countries';

export default function CountryAndClientSelector(props: {
  country: string;
  setCountry: Function;
  client: string;
  setClient: Function;
}) {
  const {country, setCountry, client, setClient} = props;
  const [{data: clientNames}, getClientNames] = useGetClientNames();
  const [{data: countries}] = useGetClientCountries();
  const {getCountryName} = useCountryList();
  const sortedCountries = sortCountries(countries);

  const callGetClientNames = useCallback(async () => {
    try {
      await getClientNames({
        params: {
          country,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [getClientNames, country]);

  useEffect(() => {
    callGetClientNames();
  }, [callGetClientNames, country]);

  const handleCountryChange = (country: string) => {
    setClient('');
    setCountry(country);
  };
  return (
    <Flex>
      <Select
        placeholder='All countries'
        value={country}
        bg='white'
        onChange={(e) => handleCountryChange(e.target.value)}
        w='170px'
        m='5px'
      >
        {sortedCountries?.map((countryCode: string) => {
          return (
            countryCode && (
              <option value={countryCode} key={countryCode}>
                {getCountryName(countryCode)}
              </option>
            )
          );
        })}
      </Select>
      <Select
        placeholder='All clients'
        bg='white'
        m='5px'
        w='170px'
        onChange={(e) => setClient(e.target.value)}
        value={client}
      >
        {clientNames
          && clientNames.map((client: string) => {
            return (
              <option value={client} key={client}>
                {client}
              </option>
            );
          })}
      </Select>
    </Flex>
  );
}
