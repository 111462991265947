import React from 'react';

import MenuLayout from '../../layouts/MenuLayout';
import RoleGuard from '../../layouts/RoleGuard';

import {adminRoles} from '../../variables/roles';
import AdminUsersTabs from './AdminUsersTabs';


export default function AdminUsersListPage() {
  return <RoleGuard
    page
    roles={
      [
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]
    }
  >
    <MenuLayout page={<AdminUsersTabs />} />
  </RoleGuard>;
}
