import {Box, FormLabel, Grid, Text, Image, Tag} from '@chakra-ui/react';
import React from 'react';


// eslint-disable-next-line
export default function CreativeServices(props: { creativeData: any }) {
  const {creativeData} = props;

  return (
    <Box m='20px 10px'>
      <FormLabel>Services</FormLabel>
      {Object.entries(creativeData.photos).length === 0
        ? creativeData.services?.map((service: string) => (
          <Tag key={service} mx={{lg: '1px'}}>
            {service}
          </Tag>
        ))
        : Object.entries(creativeData.photos).map(([service, photos]: [string, any], i: number) => {
          return (
            <Box m='10px' key={i}>
              <Text>{service.toUpperCase()}</Text>
              <Grid templateColumns='repeat(auto-fit, minmax(270px, 1fr))'>
                {photos.map((url: string, i: number) => {
                  return <Image key={i} h='250px' w='250px' src={url} m='10px' />;
                })}
              </Grid>
            </Box>
          );
        })}

      <Box id='notes' margin='30px 0'>
        <FormLabel>Notes</FormLabel>
        <Text> {creativeData.notes}</Text>
      </Box>

      <Box margin='30px 0'>
        <FormLabel>Equipments</FormLabel>
        <Text>{creativeData.equipments}</Text>
      </Box>

      <Box margin='20px 0'>
        <FormLabel>Equipment list</FormLabel>
        {creativeData.equipmentList?.map((equipment: string) => (
          <Tag key={equipment} mx={{lg: '1px'}}>
            {equipment}
          </Tag>
        ))}
      </Box>
    </Box>
  );
}
