import useAxios from './basic/use-axios';


export const useGetUser = () => useAxios({url: '/admin/user/profile'});

export const useUpdateProfileSettings = () =>
  useAxios(
    {
      url: '/admin/user/profile',
      method: 'PUT',
    },
    {manual: true},
  );

export const useChangePassword = () =>
  useAxios(
    {
      url: '/admin/user/change-password',
      method: 'PUT',
    },
    {manual: true},
  );
