import {adminRoles} from '../../variables/roles';
import RoleGuard from '../../layouts/RoleGuard';
import MenuLayout from '../../layouts/MenuLayout';
import InactiveCreativeList from './InactiveCreativeList';

export default function InactiveCreativesPage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <MenuLayout page={<InactiveCreativeList />} />
    </RoleGuard>
  );
}
