import React, {useEffect, useState} from 'react';
import {Box, Flex, Select, Text, useToast} from '@chakra-ui/react';

import {CreativeTags, newApplicantManualTags, tagColors} from '../../constants';
import {useUpdateCreative} from 'api/staff-api';
import {useParams} from 'react-router-dom';
import {TiDeleteOutline} from 'react-icons/ti';


interface Props {
  creativeTags: string[];
}

export const LabelManager: React.FC<Props> = ({creativeTags}) => {
  const {creativeId} = useParams();
  const toast = useToast();
  const [tags, setTags] = useState([]);
  const [manualTags, setManualTags] = useState(
    newApplicantManualTags.filter((t) => !tags.includes(t)),
  );

  const [{error: updateCreativeError}, updateCreative] = useUpdateCreative(creativeId);

  useEffect(
    () => setTags(
      (creativeTags ?? []).filter(((t) => t !== CreativeTags.INACTIVE)),
    ),
    [creativeTags],
  );

  useEffect(
    () => {
      if (updateCreativeError) {
        toast({
          title: 'Unable to update labels on creative',
          status: 'error',
          duration: 3000,
        });
      }
    },
    [updateCreativeError],
  );

  useEffect(
    () => {
      updateCreative({data: {tags}});
    },
    [updateCreative, tags],
  );

  const handleSelectTag = (tag: any) => {
    setTags((prevState) => [...prevState, tag]);
    setManualTags((prevState) => prevState.filter((t) => t !== tag));
  };

  const handleRemoveTag = (tag: any) => {
    setManualTags((prevState) => [...prevState, tag]);
    setTags((prevState) => prevState.filter((t) => t !== tag));
  };

  function Tag(props: { tag: string }) {
    const {tag} = props;
    return (
      <Flex
        bg={tagColors[tag].primary}
        borderRadius='20px'
        padding='5px 10px'
        m='5px'
        color={tagColors[tag].secondary}
      >
        <Text fontWeight='bold'>{tag}</Text>
        {newApplicantManualTags.includes(tag) && (
          <Box m='4px 0px 0px 4px' cursor='pointer'>
            <TiDeleteOutline onClick={() => handleRemoveTag(tag)}/>
          </Box>
        )}
      </Flex>
    );
  }

  return (
    <Box
      bg='white'
      borderRadius='20px'
      p='10px'
      width={{base: 'auto', md: '1000px'}}
      mb='20px'
    >
      <Text fontWeight='bold' m='10px' mr='20px' fontSize='20px'>
        Labels
      </Text>
      <Flex m='10px'>
        <Select w='250px' onChange={(e) => handleSelectTag(e.target.value)} value='' mr='20px'>
          <option value='' disabled>
            Choose manual label
          </option>
          {manualTags.map((l) => (
            <option value={l} key={l}>
              {l}
            </option>
          ))}
        </Select>
        {tags.map((t: string) => (
          <Tag key={t} tag={t}/>
        ))}
      </Flex>
    </Box>
  );
};
