import React, {useCallback, useEffect, useState} from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Select,
} from '@chakra-ui/react';
import {z} from 'zod';
import {CreateTemplate, UpdateTemplate, useCreateTemplate, useUpdateTemplate} from 'api/photoroom-templates';
import Bugsnag from '@bugsnag/js';


export const defaultForm = {
  _id: '',
  name: '',
  templateId: '',
  status: '',
  version: '',
};

const validators = {
  name: z.string().min(1, 'Name is required, minimum 3 characters'),
  templateId: z.string().uuid('TemplateID must be a valid UUID'),
  status: z.enum(['active', 'deactivated'], {errorMap: () => ({message: 'Must select a valid status'})}),
  version: z.enum(['v1', 'v2'], {errorMap: () => ({message: 'Must select a valid version'})}),
};

const formValidator = z.object({
  name: validators.name,
  templateId: validators.templateId,
  status: validators.status,
  version: validators.version,
});

export default function CreateOrUpdateTemplateModal({
  selectedTemplate,
  isOpen,
  onClose,
  toCreate,
}: {
  selectedTemplate?: {
    _id?: string
    name: string;
    templateId: string;
    status: string;
    version: string;
  };
  isOpen: boolean;
  onClose: (successfulUpdate?: boolean) => void;
  toCreate?: boolean
}) {
  const toast = useToast();
  const [formData, setFormData] = useState({
    _id: selectedTemplate?._id || defaultForm._id,
    name: selectedTemplate?.name || defaultForm.name,
    templateId: selectedTemplate?.templateId || defaultForm.templateId,
    status: selectedTemplate?.status || defaultForm.status,
    version: selectedTemplate?.version || defaultForm.version,
  });
  const [errors, setErrors] = useState('');

  const [
    {
      loading: isCreateInProgress,
      error: createErrors,
    },
    createTemplate,
  ] = useCreateTemplate();

  const [
    {
      loading: isUpdateInProgress,
      error: updateErrors,
    },
    updateTemplate,
  ] = useUpdateTemplate(selectedTemplate?._id);

  useEffect(() => {
    if (selectedTemplate) {
      setFormData({
        _id: selectedTemplate?._id || defaultForm._id,
        name: selectedTemplate?.name || defaultForm.name,
        templateId: selectedTemplate?.templateId || defaultForm.templateId,
        status: selectedTemplate?.status || defaultForm.status,
        version: selectedTemplate?.version || defaultForm.version,
      });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (createErrors || updateErrors) {
      toast({
        title: `${createErrors ? 'Create' : 'Update'} was unsuccessful!`,
        description: createErrors.response?.data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [createErrors, updateErrors, toast]);

  const callCreate = useCallback(async () => {
    try {
      const templateData = formData as CreateTemplate;
      try {
        formValidator.parse(templateData);
      } catch (error: any) {
        setErrors((prevErrors: string) =>
          prevErrors
            ? `${prevErrors}\n${error.errors?.map((e: any) => e.message).join('\n')}`
            : error.errors?.map((e: any) => e.message).join('\n'),
        );
        return;
      }

      await createTemplate({data: templateData});
      toast({
        title: `Template created successfully`,
        status: 'success',
        duration: 3000,
      });
      setFormData(defaultForm);
      onClose(true);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  }, [formData, createTemplate, setFormData, onClose]);


  const callUpdate = useCallback(async () => {
    try {
      const templateDate = formData as UpdateTemplate;
      try {
        formValidator.parse(formData);
      } catch (error: any) {
        setErrors(error.errors.map((e: any) => e.message).join('\n'));
        return;
      }

      await updateTemplate({data: templateDate});
      toast({
        title: `Template updated successfully`,
        status: 'success',
        duration: 3000,
      });
      setFormData(defaultForm);
      onClose(true);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  }, [formData, updateTemplate, setFormData, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setFormData(defaultForm);
        setErrors('');
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {`${toCreate ? 'Create' : 'Update'} photoroom template`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id='version' mb='10px'>
            <FormLabel>Version</FormLabel>
            <Select
              onChange={(e) =>
                setFormData((prev: any) => ({
                  ...prev,
                  version: e.target.value,
                }))
              }
              placeholder='Select version'
              value={formData.version}
            >
              <option key={'v1'} value={'v1'}>
                  V1
              </option>
              <option key={'v2'} value={'v2'}>
                  V2
              </option>
            </Select>
          </FormControl>

          <FormControl id='name' mb='10px'>
            <FormLabel>Name</FormLabel>
            <Input
              value={formData.name}
              onChange={(e) =>
                setFormData((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              placeholder='Select name'

            />
          </FormControl>

          <FormControl id='templateId' mb='10px'>
            <FormLabel>Template ID</FormLabel>
            <Input
              value={formData.templateId}
              onChange={(e) =>
                setFormData((prev: any) => ({
                  ...prev,
                  templateId: e.target.value,
                }))
              }
              placeholder='Select templateID'
            />
          </FormControl>

          <FormControl id='status' mb='10px'>
            <FormLabel>Status</FormLabel>
            <Select
              onChange={(e) =>
                setFormData((prev: any) => ({
                  ...prev,
                  status: e.target.value,
                }))
              }
              placeholder='Select status'
              value={formData.status}
            >
              <option key={'active'} value={'active'}>
                  Active
              </option>
              <option key={'deactivated'} value={'deactivated'}>
                  Deactivated
              </option>
            </Select>
          </FormControl>

          {errors && (
            <Alert status='error' my={2}>
              <AlertIcon />
              <AlertTitle mr={2}>{errors}</AlertTitle>
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            isLoading={isCreateInProgress || isUpdateInProgress}
            onClick={toCreate ? callCreate : callUpdate}
            variant='photonLight'
            isDisabled = {Object.entries(formData).some(([k, v])=> k !== '_id' && !v)}
          >
            Save
          </Button>
          <Button
            variant='photonMedium'
            onClick={() => {
              setFormData(defaultForm);
              setErrors('');
              onClose();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
