import {
  Box,
  FormLabel,
  Flex,
  FormControl,
  Button,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import {useUpdateEditor} from 'api/editors';
import useCountryList from 'hooks/useCountryList';
import {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {MdModeEdit} from 'react-icons/md';
import {CountryItem} from 'services/country-list';
import Bugsnag from '@bugsnag/js';


export const CustomFieldStyle = {
  alignItems: 'center',
  bg: 'white',
  borderRadius: '15px',
  p: '5px',
  pl: '15px',
  h: '2.5rem',
  mb: 4,
  w: {base: '100%', lg: '500px'},
};


export default function EditorBillingData(props: { editorData: any }) {
  const {editorData} = props;
  const [editField, setEditField] = useState('');
  const {countryList, getCountryName} = useCountryList();
  const [{loading, error}, updateEditor] = useUpdateEditor(editorData._id);
  const toast = useToast();


  useEffect(() => {
    if (error) {
      toast({
        title: `Could not update editor`,
        status: 'error',
      });
    }
  }, [error]);

  const defaultValues = useMemo(
    () => ({
      country: editorData.editor.billing?.address.country,
      lang: editorData.editor.billing?.lang,
    }),
    [],
  );
  const {
    register,
    watch,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });

  const handleSaveClick = async (data: any) => {
    try {
      await updateEditor({data});
      toast({
        title: 'Editor updated',
        status: 'success',
        duration: 3000,
      });
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  const watchCountry = watch('country');
  const watchBillingLanguage = watch('lang');

  return <Box mt='50px'>
    <form onSubmit={handleSubmit(handleSaveClick)}>
      <Flex
        justifyContent='space-around'
        alignItems={{base: 'center', md: 'start'}}
        direction={{base: 'column', md: 'row'}}
      >
        <Flex direction='column' w='500px'>
          <>
            <FormLabel>Billing name</FormLabel>
            <Flex {...CustomFieldStyle}>
              {editorData.editor.billing?.name}
            </Flex>
          </>

          <FormControl>
            <FormLabel display='flex' justifyContent={'space-between'}>
                Billing country
            </FormLabel>
            <Flex>
              {editField === 'country' ? (
                <Select
                  {...register('country')}
                  placeholder='Select country'
                  value={watchCountry}
                  bg='white'
                  mb={4}
                  borderRadius='15px'
                  border= 'none'
                  onBlur={()=>setEditField('')}
                  onChange={(e)=>setValue('country', e.target.value)}
                >
                  {countryList.map((country: CountryItem) => (
                    <option key={country.value} value={country.value}>
                      {country.label}
                    </option>
                  ))}
                </Select>
              ) : (
                <>
                  <Flex {...CustomFieldStyle}>
                    <Text>{getCountryName(watchCountry)}</Text>
                  </Flex>
                  <Button ml='5px' onClick={() => setEditField('country')}>
                    {<MdModeEdit size={20}/>}
                  </Button>
                </>
              )}
            </Flex>
          </FormControl>

          <>
            <FormLabel>ZIP code</FormLabel>
            <Flex {...CustomFieldStyle}>
              {editorData.editor.billing?.address?.zipCode}
            </Flex>
          </>

          <>
            <FormLabel>City</FormLabel>
            <Flex {...CustomFieldStyle}>
              {editorData.editor.billing?.address?.city}
            </Flex>
          </>

          <>
            <FormLabel>Street</FormLabel>
            <Flex {...CustomFieldStyle}>
              {editorData.editor.billing?.address?.street}
            </Flex>
          </>

          <FormControl>
            <FormLabel display='flex' justifyContent={'space-between'}>
                Billing language
            </FormLabel>

            <Flex>
              {editField === 'lang' ? (
                <Select
                  {...register('lang')}
                  placeholder='Select billing language'
                  value={watchBillingLanguage}
                  bg='white'
                  mb={4}
                  borderRadius='15px'
                  border= 'none'
                  onBlur={()=>setEditField('')}
                  onChange={(e)=>setValue('lang', e.target.value)}
                >
                  <option key={'hu'} value={'hu'}>
                    {'Hungarian'}
                  </option>
                  <option key={'en'} value={'en'}>
                    {'English'}
                  </option>
                </Select>
              ) : (
                <>
                  <Flex {...CustomFieldStyle}>
                    <Text>
                      {watchBillingLanguage === 'hu' ? 'Hungarian': 'English'}
                    </Text>
                  </Flex>
                  <Button ml='5px' onClick={() => setEditField('lang')}>
                    {<MdModeEdit size={20}/>}
                  </Button>
                </>
              )}
            </Flex>
          </FormControl>

        </Flex>
        <Flex direction='column' w='500px'>
          <>
            <FormLabel>Tax number</FormLabel>
            <Flex {...CustomFieldStyle}>
              {editorData.editor.billing?.taxNumber}
            </Flex>
          </>

          <>
            <FormLabel>GIRO</FormLabel>
            <Flex {...CustomFieldStyle}>
              {editorData.editor.billing?.bankAccountGiro}
            </Flex>
          </>

          <>
            <FormLabel>Swift</FormLabel>
            <Flex {...CustomFieldStyle}>
              {editorData.editor.billing?.bankSwift}
            </Flex>
          </>

          <>
            <FormLabel>IBAN</FormLabel>
            <Flex {...CustomFieldStyle}>
              {editorData.editor.billing?.bankAccountIban}
            </Flex>
          </>

          <>
            <FormLabel>Bank name</FormLabel>
            <Flex {...CustomFieldStyle}>
              {editorData.editor.billing?.bankName}
            </Flex>
          </>

        </Flex>
      </Flex>
      <Flex justifyContent='center' m='50px'>
        <Button isLoading={loading} type='submit' variant='photonDark' w='200px'>
            Save
        </Button>
      </Flex>
    </form>
  </Box>;
}
