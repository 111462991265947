export const PackageCategory: Record<string, string> = {
  EVENT: 'event',
  PORTRAIT: 'portrait',
  PROPERTY: 'property',
  WEDDING: 'wedding',
  FAMILY_AND_GROUP: 'familyAndGroup',
  FOOD: 'food',
  PRODUCT: 'product',
  CUSTOM: 'custom',
  HOSPITALITY: 'hospitality',
  E_COMMERCE: 'ecommerce',
  OTHER: 'other',
};

export const packageCategoryLabels: Record<string, string> = {
  [PackageCategory.EVENT]: 'Event',
  [PackageCategory.PORTRAIT]: 'Portrait',
  [PackageCategory.WEDDING]: 'Wedding',
  [PackageCategory.FAMILY_AND_GROUP]: 'Family & group',
  [PackageCategory.FOOD]: 'Food',
  [PackageCategory.PRODUCT]: 'Product',
  [PackageCategory.CUSTOM]: 'Custom',
  [PackageCategory.HOSPITALITY]: 'Hospitality',
  [PackageCategory.E_COMMERCE]: 'E-commerce',
};

export const PackageType: Record<string, string> = {
  PHOTO: 'photo',
  VIDEO: 'video',
};

export const packageTypeLabels: Record<string, string> = {
  [PackageType.PHOTO]: 'Photo',
  [PackageType.VIDEO]: 'Video',
};

export const PackageStatus: Record<string, string> = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  PENDING: 'pending',
};

export const ManagePackageOption: Record<string, string> = {
  ACTIVATE: 'activate',
  CANCEL: 'cancel',
  DELETE: 'delete',
};

export const packageStatusLabels: Record<string, string> = {
  [PackageStatus.ENABLED]: 'Active',
  [PackageStatus.DISABLED]: 'Canceled',
  [PackageStatus.PENDING]: 'Draft',
};
