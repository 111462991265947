import React, {useState} from 'react';
import {Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast} from '@chakra-ui/react';

import {useGetCreative, useUpdateCreative} from '../../api/staff-api';
import CreativeBasicData from './components/CreativeBasicData';
import CreativeBillingData from './components/CreativeBillingData';
import CreativeServices from './components/CreativeServices';
import CreativeRating from './components/CreativeRating';
import ProfilePicture from './components/ProfilePicture';
import {useParams} from 'react-router-dom';
import CreativeManage from './components/CreativeManage';
import Comments from './components/Comments';
import {useFormContext} from 'contexts/useFormContext';
import {
  CreativeEventType,
  CreativeTags,
  DeactivateReasons,
  newApplicantStates,
  statusColors,
} from './constants';
import useCountryList from 'hooks/useCountryList';
import {AiFillStar} from 'react-icons/ai';
import {CreativeEvent} from './types';


const tabLabels = [
  'Basic data',
  'Services',
  'Billing',
  'Projects and rating',
  'Manage',
  'Comments',
];

export default function Creative() {
  const {handleTabChange} = useFormContext();
  const {creativeId} = useParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const {getCountryName} = useCountryList();

  const [{data: creativeData}, getCreative] = useGetCreative(creativeId);
  const [, updateCreative] = useUpdateCreative(creativeId);
  const toast = useToast();

  const offNewCreative = [
    DeactivateReasons.OFF,
    DeactivateReasons.BLACKLIST,
  ].includes(
    creativeData?.events?.find((e: CreativeEvent)=> e.event === CreativeEventType.DEACTIVATE)?.details.reason,
  );


  const displayCreativeState = [
    ...newApplicantStates,
    {value: 'inactive', label: 'Deactivated'},
    {value: 'deleted', label: 'Deleted'},
  ].find((state: {value: string}) => state.value === creativeData?.status);

  const handleAdminFlagChange = async (value: boolean) => {
    await updateCreative({data: {adminFlag: value}});
    toast({
      title: 'Project updated',
      status: 'success',
      duration: 3000,
    });
    getCreative();
  };

  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      {creativeData && (
        <>
          <Flex
            bg='white'
            alignItems='center'
            w={{base: '90vw', md: '500px'}}
            borderRadius='20px'
            m='10px'
          >
            <Box h={{base: '50px', md: '75px'}} w={{base: '50px', md: '75px'}} m='10px'>
              <ProfilePicture name={creativeData.name} />
            </Box>
            <Box m='10px 20px' w='80%'>
              <Flex>
                <Text fontSize='25px' fontWeight='bold'>
                  {creativeData.name}
                </Text>
              </Flex>
              <Flex justifyContent='space-between'>
                <Flex direction='column'>
                  <Text>{creativeData.email}</Text>
                  <Text>{creativeData.phone}</Text>
                  <Text>{getCountryName(creativeData.billingAddressObj?.country)}</Text>
                </Flex>
                <Flex direction='column' alignItems='end'>
                  <Box m='5px'>
                    <AiFillStar
                      onClick={() => {
                        handleAdminFlagChange(!creativeData.adminFlag);
                      }}
                      cursor='pointer'
                      color={creativeData.adminFlag ? '#6b52ff' : 'lightgrey'}
                      size='25'
                    />
                  </Box>
                  {displayCreativeState ? (
                    <Box
                      borderRadius='20px'
                      p='5px 10px'
                      fontWeight='bold'
                      textAlign='center'
                      bg={ offNewCreative ? 'grey' : statusColors[creativeData.status]?.primary}
                      color={ offNewCreative ? 'white' : statusColors[creativeData.status]?.secondary}
                    >
                      {!offNewCreative && <Text>{displayCreativeState?.label || creativeData.status}</Text>}
                      {offNewCreative && <Text>{'OFF'}</Text>}
                    </Box>
                  ) : (
                    <Box
                      bg={creativeData.tags?.includes(CreativeTags.INACTIVE) ? '#ffb03b' : '#3cc427'}
                      fontWeight='bold'
                      color='black'
                      p='10px'
                      borderRadius='20px'
                      w='80px'
                    >
                      <Text align='center'>
                        {creativeData.tags?.includes(CreativeTags.INACTIVE) ? 'Inactive' : 'Active'}
                      </Text>
                    </Box>
                  )}
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <Tabs index={selectedTabIndex}>
            <TabList ml='0px' mr='20px'>
              {tabLabels.map((label, index) => (
                <Tab
                  key={index}
                  w={{base: '20%', md: 'auto'}}
                  onClick={() => handleTabChange(index, setSelectedTabIndex)}
                >
                  {label}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel>
                <CreativeBasicData creativeData={creativeData} />
              </TabPanel>

              <TabPanel>
                <CreativeServices creativeData={creativeData} />
              </TabPanel>

              <TabPanel>
                <CreativeBillingData creativeData={creativeData} />
              </TabPanel>

              <TabPanel>
                <CreativeRating creativeData={creativeData} />
              </TabPanel>

              <TabPanel>
                <CreativeManage creativeData={creativeData} callGetCreative={getCreative} />
              </TabPanel>

              <TabPanel>
                <Comments creativeData={creativeData} callGetCreative={getCreative} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Box>
  );
}
