import {
  Button,
  InputGroup,
  ButtonGroup,
  Input,
  useToast,
  Box,
} from '@chakra-ui/react';
import {ReactNode, useEffect, useRef, useState} from 'react';
import {FaRegSave} from 'react-icons/fa';
import {MdOutlineCancel} from 'react-icons/md';
import {ResponseValues} from 'axios-hooks';

import styled from '@emotion/styled';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import InlineEditorProvider from './InlineEditorProvider';
import {useUpdateProject} from 'api/projects';
import {datePickerStyles} from 'views/projects/variables/styles';

const StyledInput = styled(Input)`
  width: 60px;
  margin-right: 20px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  svg {
    color: #718096;
  }
`;

function InlineEditor({
  children,
  initialValue,
  contentType,
  projectId,
  refetch,
}: {
  children: ReactNode;
  initialValue: string;
  contentType: 'date' | 'number';
  projectId: string;
  refetch: () => Promise<void>;
}) {
  const [isEditing, setEditing] = useState(false);
  const [isConfirming, setConfirming] = useState(false);
  const [value, setValue] = useState<number | Date>();

  const inputRef = useRef<HTMLInputElement>(null);

  const [, updateProject] = useUpdateProject(projectId);

  const toast = useToast();

  useEffect(() => {
    inputRef.current?.focus();
  });

  useEffect(() => {
    switch (contentType) {
      case 'date':
        setValue(new Date(initialValue));

        break;
      case 'number':
        setValue(parseInt(initialValue));

        break;
    }
  }, []);

  if (isEditing) {
    return (
      <>
        <InputGroup>
          {value instanceof Date && (
            <Box
              as={DateTimePicker}
              value={value}
              onChange={(newValue: Date | null) => setValue(newValue)}
              clearIcon={null}
              calendarIcon={null}
              autoFocus
              isCalendarOpen
              disableClock
              showLeadingZeros
              sx={{...datePickerStyles,
                'w': '180px',
                'h': '40px',
                'bg': 'white',
                'borderRadius': '15px',
                'pl': '10px',
                'fontSize': '13px',
                'font-weight': 'bold',
                'color': 'black',
                'border': 'none',
              }}
            />
          )}

          {typeof value === 'number' && (
            <StyledInput
              type="number"
              ref={inputRef}
              value={value}
              onChange={(e) => setValue(parseInt(e.target.value))}
            />
          )}

          <StyledButtonGroup size="md" variant="ghost" isAttached>
            <Button onClick={() => setConfirming(true)}>
              <FaRegSave />
            </Button>

            <Button onClick={() => setEditing(false)}>
              <MdOutlineCancel />
            </Button>
          </StyledButtonGroup>
        </InputGroup>

        {isConfirming && (
          <InlineEditorProvider
            projectId={projectId}
            value={value}
            handleClose={() => setConfirming(false)}
            handleSave={async (data: any) => { // TODO specify type
              try {
                await updateProject({
                  data,
                });
                toast({
                  title: 'Project updated',
                  status: 'success',
                  duration: 3000,
                });
                await refetch();

                setConfirming(false);
                setEditing(false);
              } catch (e) {
                toast({
                  title: (e as ResponseValues<{ message: string }, null, null>)
                    .response.data.message,
                  status: 'error',
                  duration: null,
                  isClosable: true,
                });
              }
            }}
          />
        )}
      </>
    );
  }

  return <div onClick={() => setEditing(true)}>{children}</div>;
}

export default InlineEditor;
