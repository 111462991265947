type Client = {
  name: string;
  status: string;
};

type Package = {
  name: string;
  _id: string;
  client: Client;
  status: string;
};

type PackageOption = {
  value: string;
  label: string;
};

type GetPackagesFunction = (searchText: string) => Promise<Package[]>;

export const getCurrentPackageName = (id: string, packages: Package[]) => {
  const current = packages.find((p: Package) => p._id === id);
  return `${current?.name}${current?.status !== 'enabled' ? ' / (NA)' : ''}, (${current?.client?.name})`;
};

export const loadPackageOptionsWithDebounce = () => {
  let timer: ReturnType<typeof setTimeout> | undefined;

  return (
    input: string,
    callback: (options: PackageOption[]) => void,
    showInactive: boolean,
    getPackages: GetPackagesFunction,
  ) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      getPackages(input).then((packages) => {
        const filteredPackages = packages
          .filter((pkg) => showInactive || pkg.status === 'enabled')
          .map((pkg) => ({
            value: pkg._id,
            label: `${pkg.name}${pkg.status !== 'enabled' ? ' / (NA)' : ''}, (${pkg.client.name})`,
          }));
        callback(filteredPackages);
      });
    }, 500);
  };
};


