import {Box, Text, Grid} from '@chakra-ui/react';
import ProjectStats from './components/ProjectStats';
import AnualIncome from './components/AnualIncome';
import TopClients from './components/TopClients';
import AverageStats from './components/AverageStats';
import AnualProjects from './components/AnualProjects';

export default function Dashboard() {
  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      <Text fontSize="25px" fontWeight="bold" m="20px">
        Dashboard
      </Text>
      <AnualIncome />
      <AnualProjects />
      <ProjectStats />
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <TopClients />
        <AverageStats />
      </Grid>
    </Box>
  );
}
