import {useState} from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react';
import {DATE_CHANGE_REASON} from '../constants';
import moment from 'moment';


const selectReasonLabel = 'Please specify who is responsible for the date change!';

export default function TimeChangeModals({
  handleSave,
  isOpen,
  onClose,
  data,
}: {
  handleSave: Function;
  isOpen: boolean;
  onClose: (successfulDelete?: boolean) => void;
  data: any;
}) {
  const [dateChangeReason, setDateChangeReason] = useState('');
  const now = moment();
  const isShootingIn24H = moment(data?.shootingTimeLocal).diff(now, 'hours') <= 24;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>
          <Text mx='20px' align='center'>{isShootingIn24H ? 'Attention!' : selectReasonLabel}</Text>
        </ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          {isShootingIn24H
            && <Flex direction='column' alignItems='center' m='20px' mt='0px'>
              <Text align='center'>
                  The new local shooting time is less than 24 hours away. Are you sure you want
                  to modify the project with such a short notice? We recommend you to cancel the project
                  on behalf of the client instead and advise them to create a new one.
              </Text>
            </Flex>
          }
          <Flex justifyContent='center' direction='column' alignItems='center'>
            {isShootingIn24H && <Text fontSize='18px' fontWeight='bold' mb='10px'>{selectReasonLabel}</Text>}
            <Select
              placeholder='Select reason'
              value={dateChangeReason}
              bg='white'
              onChange={(e) => setDateChangeReason(e.target.value)}
              w='300px'
              m='5px'
            >
              {Object.values(DATE_CHANGE_REASON)?.map((reason: { value: string; label: string }) => {
                return (
                  <option value={reason.value} key={reason.value}>
                    {reason.label}
                  </option>
                );
              })}
            </Select>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex w='100%' justifyContent='center'>
            <Button mr='5px' w='140px' variant='photonMedium' onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              w='140px'
              variant='photonDark'
              onClick={() => {
                if (dateChangeReason) {
                  handleSave({...data, dateChangeReason});
                }
                onClose();
              }}
              isDisabled={!dateChangeReason}
            >
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
