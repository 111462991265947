import React, {useCallback, useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';
import {useLocalStorage} from '../hooks/useLocalStorage';


declare global {
  interface Document {
    hasVisibilityChangeListener: boolean;
  }
}

export const ProtectedLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {getUser} = useAuth();
  const [, setPreviousPath] = useLocalStorage('previousPath', '/intel/projects');

  const refreshCredentials = useCallback(
    () => {
      if (document.visibilityState === 'visible') {
        getUser().then(
          // eslint-disable-next-line
          (user: any) => {
            if (!user) {
              if (['no-permission', 'login'].every((urlPart) => !location.pathname.includes(urlPart))) {
                setPreviousPath(location.pathname);
              }
              navigate('/login');
            }
          },
        );
      }
    },
    [],
  );

  useEffect(
    refreshCredentials,
    [location],
  );

  useEffect(
    () => {
      if (document.hasVisibilityChangeListener) {
        return;
      }

      document.addEventListener('visibilitychange', refreshCredentials);
      document.hasVisibilityChangeListener = true;
    },
    [],
  );

  return <Outlet/>;
};
