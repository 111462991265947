import React from 'react';
import ActiveCreativeList from './ActiveCreativeList';
import {adminRoles} from '../../variables/roles';
import RoleGuard from '../../layouts/RoleGuard';
import MenuLayout from '../../layouts/MenuLayout';


const ActiveCreativesPage: React.FC = () => <RoleGuard
  page
  roles={[
    adminRoles.RolePhotonManager.key,
    adminRoles.RolePhotonAdmin.key,
    adminRoles.RolePhotonSuperAdmin.key,
  ]}
>
  <MenuLayout page={<ActiveCreativeList/>}/>
</RoleGuard>;

export default ActiveCreativesPage;
