import React, {useEffect} from 'react';

import {
  Box,
  IconButton,
  Link,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import {NavLink} from 'react-router-dom';
import {useGetCountries} from '../../../api/presence-countries';
import * as loc from '../../../lib/localized-countries/en.json';
import {AddIcon} from '@chakra-ui/icons';
import Card from 'components/card/Card';


const getCountryName = (code: string): string => {
  // eslint-disable-next-line
  // @ts-ignore
  return loc[code] || code;
};

interface CountryCardProps {
  isoCode: string;
  countryName: string;
}

const CountryCard: React.FC<CountryCardProps> = ({isoCode, countryName}) => {
  return (
    <Box
      color="gray.200"
      background="#6b52ff"
      borderWidth="2px"
      overflow="hidden"
      width="150px"
      height="150px"
      margin="10px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
      transition="all 0.3s ease-in-out"
      _hover={{
        width: '170px',
        height: '170px',
        background: '#4b32d1',
      }}
    >

      <Box
        padding="10px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        w="100%"
        h="100%"
        backgroundColor="#0008"
      >
        <Text fontSize="4xl" fontWeight="bold">
          {isoCode}
        </Text>
        <Text fontSize="sm">{countryName}</Text>
      </Box>
    </Box>
  );
};

export default function CountriesList() {
  const [
    {
      data: presenceCountries,
      loading: getCountriesLoading,
      error: getCountriesError,
    },
  ] = useGetCountries();

  const toast = useToast();

  useEffect(
    () => {
      if (getCountriesError) {
        toast({
          title: 'Error getting presence countries',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [getCountriesError],
  );


  return <Card mt='100px'>
    <Text mb='20px' fontSize='20px' fontWeight='bold'>Country settings list</Text>
    <Box
      display='flex'
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
    >
      {
        getCountriesError
      && <Text fontSize="xl" fontWeight="extrabold">
        Error during getting data: {getCountriesError.message}
      </Text>
      }

      {
        getCountriesLoading
      && <Box minH="30vh">
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='brand.600'
          size='xl'
        />
      </Box>
      }
      {
        !getCountriesLoading
      && !getCountriesError
      && <>
        <Box
          position="fixed"
          bottom="5vh"
          right="5vw"
          zIndex="100"
        >
          <Link as={NavLink} to='/intel/settings/country/new'>
            <IconButton
              icon={<AddIcon />}
              aria-label="Add new country"
              borderRadius="full"
              boxShadow="lg"
              size="lg"
              backgroundColor="brand.600"
              color="white"
              _hover={{backgroundColor: 'brand.700'}}
            />
          </Link>
        </Box>
        {
          presenceCountries.map(
            (countryCode: string) => <Link
              as={NavLink}
              to={`/intel/settings/country/${countryCode}`}
              key={countryCode}
            >
              <CountryCard isoCode={countryCode} countryName={getCountryName(countryCode)} />
            </Link>,
          )
        }
      </>
      }
    </Box>
  </Card>;
};
