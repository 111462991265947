import React from 'react';

import {
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import CreateAdminUserModal from './UpdateOrCreateAdminUserModal';


export default function AdminListMenu(props: { [x: string]: any }) {
  const {onRefresh} = props;

  const {isOpen: isOpenCreateModal, onOpen: onOpenCreateModal, onClose: onCloseCreateModal} = useDisclosure();

  return <>
    <Button
      variant='photonLight'
      onClick={() => onOpenCreateModal()}
    >
      Add
    </Button>
    <CreateAdminUserModal
      isOpen={isOpenCreateModal}
      onClose={
        (success) => {
          onCloseCreateModal();
          if (success) {
            onRefresh();
          }
        }
      }
      toCreate
    />
  </>;
}
