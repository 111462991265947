export const EditorStatus = {
  JUST_REGISTERED: 'justRegUser',
  ACTIVE: 'activeUser',
  INACTIVE: 'inactiveUser',
};

export const EditorStatusLabels = {
  [EditorStatus.JUST_REGISTERED]: 'Just registered',
  [EditorStatus.ACTIVE]: 'Active',
  [EditorStatus.INACTIVE]: 'Inactive',
};

export const ManageEditorOption = {
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
};
