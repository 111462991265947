import {
  Td,
  Tr,
} from '@chakra-ui/react';
import {CreativeEventType} from '../../constants';
import moment from 'moment';
import React from 'react';
import {CreativeEvent} from '../../types';


export const createEventComponent = (event: CreativeEvent) => {
  switch (event.event) {
    case CreativeEventType.REGISTRATION:
      return <Tr key={event.time.toString()}>
        <Td>Date of application</Td>
        <Td textAlign='center'>
          {moment(event.time).format('HH:mm DD.MM.YYYY')}
        </Td>
      </Tr>;
    case CreativeEventType.AUTO_ACCEPT_COC:
    case CreativeEventType.RESPONSE_COC:
      return <Tr key={event.time.toString()}>
        <Td>
          {event.event}
        </Td>
        <Td textAlign='center'>{moment(event.time).format('HH:mm DD.MM.YYYY')}</Td>
        <Td>{(event.details.consent ?? true) ? 'Accepted' : 'Refused'}</Td>
        <Td textAlign='center'>
          CertId: {event.details.certId}
        </Td>
      </Tr>;
    default:
      return <Tr key={event.time.toString()}>
        <Td>
          {event.event}
          {event.event === CreativeEventType.ACCEPT && event.details?.actions?.get('getRegistrationLink')?.()}
          {event.event === CreativeEventType.FINISH_REGISTRATION && event.details?.actions?.get('getConfirmationLink')?.()}
          {' '}
          {event.details?.number}
        </Td>
        <Td textAlign='center'>{moment(event.time).format('HH:mm DD.MM.YYYY')}</Td>
        <Td textAlign='center'>{event.details?.updatedBy?.name}</Td>
        <Td textAlign='center'>
          {
            event.details?.package?.name
            || event.details?.reason
            || (
              event.details?.timeOfCall
                ? moment(event.details?.timeOfCall).format('HH:mm - DD.MM.YYYY')
                : ' '
            )
          }
        </Td>
      </Tr>;
  }
};
