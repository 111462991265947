import React, {useCallback, useEffect, useState} from 'react';

import {useLocation, useSearchParams} from 'react-router-dom';

import {
  Box,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';

import {columnsDataProjects} from './variables/columnsData';
import ProjectListTable from './components/ProjectListTable';
import {ProjectListFilterTypes} from './types';
import {useGetProjects} from '../../api/projects';
import Bugsnag from '@bugsnag/js';
import LoadingModal from '../../components/LoadingModal';


export default function ProjectList() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const toast = useToast();

  const {
    from = moment().startOf('year').toDate(),
    to = moment().endOf('year').toDate(),
    creative = '',
    editor = '',
    search = '',
    contactSearch = '',
    client = '',
    city = '',
    packageId = '',
  } = Object.fromEntries(queryParams.entries());

  const isFlagged = Object.fromEntries(queryParams.entries()).isFlagged === 'true';
  const noEditor = Object.fromEntries(queryParams.entries()).noEditor === 'true';

  // eslint-disable-next-line
  let statuses = Object.fromEntries(queryParams.entries()).statuses || ([] as any);
  if (!Array.isArray(statuses)) {
    statuses = statuses.split(',');
  }

  // eslint-disable-next-line
  let country = Object.fromEntries(queryParams.entries()).country || ([] as any);
  if (!Array.isArray(country)) {
    country = country.split(',');
  }
  const [searchParams] = useSearchParams();

  const [sorting, setSorting] = useState({
    field: searchParams.get('sort') || 'shootingTime',
    order: parseInt(searchParams.get('sortOrder')) || -1,
  });

  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
  const [perPage, setPerPage] = useState(parseInt(searchParams.get('perPage')) || 10);
  const [filters, setFilters] = useState<ProjectListFilterTypes>({
    from: moment(from),
    to: moment(to),
    creative,
    editor,
    search,
    contactSearch,
    client,
    statuses,
    city,
    country,
    isFlagged,
    noEditor,
    packageId,
  });

  const [
    {
      data: projectsData,
      loading: getProjectsLoading,
      error: getProjectsError,
    },
    getProjects,
    cancelGetProjects,
  ] = useGetProjects();

  const callGetProjects = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: ProjectListFilterTypes,
    ) => {
      if (filters) {
        const transformedFilters = {
          ...filters,
          from: filters.from.format('YYYY.MM.DD'),
          to: filters.to.format('YYYY.MM.DD'),
        };

        try {
          await getProjects({
            params: {
              page,
              perPage,
              sorting,
              filters: transformedFilters,
            },
          });
        } catch (e) {
          Bugsnag.notify(e as Error);
        }
      }
    },
    [],
  );

  useEffect(
    () => {
      callGetProjects(page, perPage, sorting, filters);
      return cancelGetProjects;
    },
    [page, perPage, sorting],
  );

  useEffect(
    () => {
      if (getProjectsError) {
        toast(
          {
            title: 'Get projects error',
            description: getProjectsError.message,
            status: 'error',
            duration: 3000,
          },
        );
      }
    },
    [getProjectsError],
  );

  return (
    <Box mt='100px'>
      {
        getProjectsLoading
        && <LoadingModal />
      }
      <ProjectListTable
        columnsData={columnsDataProjects}
        tableData={projectsData?.data}
        total={projectsData?.total}
        page={page}
        perPage={perPage}
        setPerPage={setPerPage}
        setPage={setPage}
        callGetProjects={callGetProjects}
        setFilters={setFilters}
        filters={filters}
        setSorting={setSorting}
        sorting={sorting}
      />
    </Box>
  );
}
