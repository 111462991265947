import {
  Button,
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  useGetCreativeCerts,
  useGetAllCreativeCerts,
  useGetAllCreativeCertCSV,
} from 'api/staff-api';
import moment from 'moment';

import {useCallback, useEffect, useState} from 'react';
import BillingPeriodSelector from 'views/dashboard/components/BillingPeriodSelector';
import YearSelector from 'views/dashboard/components/YearSelector';
import {DownloadIcon} from '@chakra-ui/icons';
import {currencyFormatter} from 'utils/number-formatter';
import {downloadFile} from 'utils/download-file';
import Card from 'components/card/Card';
import useCountryList from 'hooks/useCountryList';


export default function CreativeCoc() {
  const [year, setYear] = useState(moment().format('YYYY'));
  const [period, setPeriod] = useState('');
  const {getCountryName} = useCountryList();


  const [{data: creativeCertsData}, getCreativeCerts] = useGetCreativeCerts();

  const [, getAllCreativeCerts] = useGetAllCreativeCerts();

  const [, getAllCreativeCertsCSV] = useGetAllCreativeCertCSV();

  const callGetCreativeCerts = useCallback(async (year: string, period: string) => {
    if (year && period) {
      try {
        await getCreativeCerts({
          params: {
            year,
            period,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const callGetAllCreativeCerts = useCallback(async (year: string, period: string) => {
    let result;
    if (year && period) {
      try {
        result = await getAllCreativeCerts({
          params: {
            year,
            period,
          },
          responseType: 'arraybuffer',
        });
        if (result && result.data) {
          downloadFile(
            result.data,
            `certificate_of_completion${year}_${period}.zip`,
            'application/zip',
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const callGetAllCreativeCertsCSV = useCallback(async (year: string, period: string) => {
    // eslint-disable-next-line
    let result: any;
    if (year && period) {
      try {
        result = await getAllCreativeCertsCSV({
          params: {
            year,
            period,
          },
          responseType: 'arraybuffer',
        });
        if (result && result.data) {
          downloadFile(
            result.data,
            `certificate_of_completion${year}_${period}.csv`,
            'application/octet-stream',
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  useEffect(
    () => {
      callGetCreativeCerts(year, period);
    },
    [year, period],
  );

  const isButtonDisabled
    = !year || !period || !creativeCertsData || creativeCertsData?.length === 0;

  return (
    <Card>
      <Text fontSize='18px' fontWeight='bold' m='10px'>
        Certificate of completion
      </Text>
      <Flex justifyContent='space-between'>
        <Flex>
          <YearSelector year={year} setYear={setYear} />
          <BillingPeriodSelector year={year} billingPeriod={period} setBillingPeriod={setPeriod} />
        </Flex>
        <Flex>
          <Button
            variant='photonMedium'
            onClick={() => callGetAllCreativeCertsCSV(year, period)}
            m='5px 10px'
            isDisabled={isButtonDisabled}
            w='130px'
          >
            Export
          </Button>
          <Button
            variant='photonLight'
            onClick={() => callGetAllCreativeCerts(year, period)}
            mt='5px'
            isDisabled={isButtonDisabled}
          >
            Download all
          </Button>
        </Flex>
      </Flex>
      <Flex justifyContent='center'>
        {creativeCertsData?.length > 0 ? (
          <TableContainer w='100%' mt='50px'>
            <Table variant='simple'>
              <Thead>
                <Tr borderBottom={`solid #E2E8F0`}>
                  <Th>Creative</Th>
                  <Th textAlign='center'>Country</Th>
                  <Th textAlign='center'>Billing period</Th>
                  <Th textAlign='center'>HUF</Th>
                  <Th textAlign='center'>EUR</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  // eslint-disable-next-line
                  creativeCertsData.map((row: any, i: string) => (
                    <Tr key={i}>
                      <Td>{row.creative}</Td>
                      <Td textAlign='center'>{getCountryName(row.country)}</Td>
                      <Td textAlign='center'>{row.date}</Td>
                      <Td>
                        {row?.certs?.HUF && (
                          <Flex justifyContent='center'>
                            <Text mr='10px'>
                              {row.certs.HUF.netValue
                              && `${currencyFormatter(row.certs.HUF.netValue, 'HUF')} HUF`}
                            </Text>
                            <Link isExternal href={row.certs.HUF.url}>{<DownloadIcon />}</Link>
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        {row?.certs?.EUR && (
                          <Flex justifyContent='center'>
                            <Text mr='10px'>
                              {row.certs.EUR.netValue
                              && `${currencyFormatter(row.certs.EUR.netValue, 'EUR')} EUR`}
                            </Text>
                            <Link isExternal href={row.certs.EUR.url}>{<DownloadIcon />}</Link>
                          </Flex>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Text textAlign={'center'} mt='50px'>
            There is no document available for this period.
          </Text>
        )}
      </Flex>
    </Card>
  );
}
