import {adminRoles} from '../../variables/roles';
import RoleGuard from '../../layouts/RoleGuard';
import MenuLayout from '../../layouts/MenuLayout';
import EditorList from './EditorList';
import {ListQueryProvider} from 'contexts/useListQueryContext';
import {EditorListFilterTypes} from './types';


export default function EditorListPage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <ListQueryProvider<EditorListFilterTypes>
        defaultFilters={{
          search: '',
          status: '',
          country: '',
        }}
      >
        <MenuLayout page={<EditorList />} />
      </ListQueryProvider>
    </RoleGuard>
  );
}
