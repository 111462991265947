import React, {KeyboardEvent, useEffect, useState} from 'react';

import {Box, Button, Checkbox, Flex, Grid, Input, InputGroup, InputRightElement, Text} from '@chakra-ui/react';
import {SingleDatepicker} from 'chakra-dayzed-datepicker';
import {TiDeleteOutline} from 'react-icons/ti';
import {ProjectListFilterTypes} from '../types';
import {AsyncSelect, Select} from 'chakra-react-select';
import {chakraStyles, countrySelectorStyles, DatePickerStyles, Option} from '../variables/styles';
import {useSearchParams} from 'react-router-dom';
import moment from 'moment';
import {useGetClientCountries} from 'api/clients';
import {filterStateOptions} from '../constants';
import useCountryList from 'hooks/useCountryList';
import {sortCountries} from 'utils/sort-countries';
import {getPackages} from 'api/packages';
import {citySelectorStyles} from 'views/creative/helpers/getCities';
import {getCurrentPackageName, loadPackageOptionsWithDebounce} from 'utils/load-package-options';


const textInputs = ['search', 'creative', 'editor', 'client', 'city', 'contactSearch'];

const RemoveInputContent = ({
  handleFilterChange,
  name,
}: {
  // eslint-disable-next-line
  handleFilterChange: Function;
  name: string;
}) => {
  return (
    <InputRightElement>
      <Button size='20' onClick={() => handleFilterChange('', name)}>
        <TiDeleteOutline size='20'/>
      </Button>
    </InputRightElement>
  );
};

const handleResetFilters = async (
  setFilters: React.Dispatch<React.SetStateAction<ProjectListFilterTypes>>,
  handleSearch: (resetFilters: ProjectListFilterTypes) => Promise<void>,
  filters: ProjectListFilterTypes,
  shouldResetDateFilters: boolean,
) => {
  const updatedFilters: ProjectListFilterTypes = {};

  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      if (key === 'statuses') {
        updatedFilters[key] = [];
      } else if (key === 'country') {
        updatedFilters[key] = [];
      } else if (key === 'from' && shouldResetDateFilters) {
        updatedFilters[key] = moment().startOf('year');
      } else if (key === 'to' && shouldResetDateFilters) {
        updatedFilters[key] = moment().endOf('year');
      } else if (key === 'isFlagged') {
        updatedFilters[key] = false;
      } else if (key === 'noEditor') {
        updatedFilters[key] = false;
      } else if (
        key === 'search'
        || key === 'contactSearch'
        || key === 'client'
        || key === 'creative'
        || key === 'editor'
        || key === 'city'
        || key === 'packageId'
      ) {
        updatedFilters[key] = '';
      }
    }
  }


  setFilters(updatedFilters);
  handleSearch(updatedFilters);
};

const ProjectListFilters = ({
  filters,
  callGetProjects,
  setFilters,
  Buttons,
  showFilters,
  isListFilter,
  setPage,
}: {
  filters: ProjectListFilterTypes;
  callGetProjects: (updatedFilters: ProjectListFilterTypes) => Promise<void>;
  setFilters: React.Dispatch<React.SetStateAction<ProjectListFilterTypes>>;
  // eslint-disable-next-line
  Buttons: any;
  showFilters: boolean;
  isListFilter?: boolean;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [{data: countries}, getCountries] = useGetClientCountries();
  const {getCountryName} = useCountryList();
  const [filterChanged, setFilterChanged] = useState(false);
  const sortedCountries = sortCountries(countries);
  const [showInactivePackages, setShowInactivePackages] = useState(false);
  const [packages, setPackages] = useState([]);
  const loadPackageOptions = loadPackageOptionsWithDebounce();


  useEffect(() => {
    const fetchData = async () => {
      getCountries();
      const packages = await getPackages();
      setPackages(packages);
    };

    fetchData();
  }, []);

  // eslint-disable-next-line
  const handleFilterChange = (e: any, name: string) => {
    let updatedValue = e;
    if (name === 'statuses') {
      // eslint-disable-next-line
      updatedValue = Array.isArray(e) ? e : ([e] as any);
    }

    const updatedFilters = {
      ...filters,
      [name]: updatedValue,
    };

    setFilters(updatedFilters);
    if (!textInputs.includes(name) || !e) {
      handleSearch(updatedFilters);
    }
    setFilterChanged(true);
  };

  const handleArrayFilterChange = (array: { value: string; label: string }[], name: string) => {
    const updatedArrayFilter = array.map((s) => s.value);
    handleFilterChange(updatedArrayFilter, name);
  };

  const setParams = (filters: ProjectListFilterTypes) => {
    Object.entries(filters).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams.toString());
  };

  const handleSearch = async (updatedFilters: ProjectListFilterTypes) => {
    if (filterChanged || updatedFilters !== filters) {
      if (isListFilter) {
        setPage(1);
      }
      callGetProjects(updatedFilters);
      setParams(updatedFilters);
      setFilterChanged(false);
    }
  };

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(filters);
    }
  };

  const isCurrentDay
    = moment(filters.from).startOf('day').format('DD.MM.YYYY') === moment().startOf('day').format('DD.MM.YYYY');

  return (
    <Box w='100%' mb={{base: '100px', xl: '20px'}}>
      {showFilters && (
        <Grid
          templateColumns='repeat(auto-fit, minmax(300px, 1fr))'
          gap='0px 10px'
          justifyItems='center'
          alignItems='center'
          p='0px'
        >
          {'search' in filters && (
            <Flex direction='column' mb={4}>
              <Text>Project name/ ID</Text>
              <InputGroup>
                {filters.search && (
                  <RemoveInputContent handleFilterChange={handleFilterChange} name={'search'}/>
                )}
                <Input
                  paddingLeft='30px'
                  variant='tableSearch'
                  placeholder='Search by project name/ ID'
                  onChange={(e) => handleFilterChange(e.target.value, 'search')}
                  value={filters.search}
                  onKeyDown={handleEnterPress}
                  onBlur={() => handleSearch(filters)}
                />
              </InputGroup>
            </Flex>
          )}
          {'client' in filters && (
            <Flex direction='column' mb={4}>
              <Text>Client</Text>
              <InputGroup>
                {filters.client && (
                  <RemoveInputContent handleFilterChange={handleFilterChange} name={'client'}/>
                )}
                <Input
                  paddingLeft='30px'
                  variant='tableSearch'
                  placeholder='Search by client'
                  onChange={(e) => handleFilterChange(e.target.value, 'client')}
                  value={filters.client}
                  onKeyDown={handleEnterPress}
                  onBlur={() => handleSearch(filters)}
                />
              </InputGroup>
            </Flex>
          )}
          {'creative' in filters && (
            <Flex direction='column' mb={4}>
              <Text>Photographer</Text>
              <InputGroup>
                {filters.creative && (
                  <RemoveInputContent handleFilterChange={handleFilterChange} name={'creative'}/>
                )}
                <Input
                  paddingLeft='30px'
                  variant='tableSearch'
                  placeholder='Search by photographer'
                  onChange={(e) => handleFilterChange(e.target.value, 'creative')}
                  value={filters.creative}
                  onKeyDown={handleEnterPress}
                  onBlur={() => handleSearch(filters)}
                />
              </InputGroup>
            </Flex>
          )}
          {'editor' in filters && (
            <Flex direction='column' mb={4}>
              <Text>Editor</Text>
              <InputGroup>
                {filters.editor && (
                  <RemoveInputContent handleFilterChange={handleFilterChange} name={'editor'}/>
                )}
                <Input
                  paddingLeft='30px'
                  variant='tableSearch'
                  placeholder='Search by editor'
                  onChange={(e) => handleFilterChange(e.target.value, 'editor')}
                  value={filters.editor}
                  onKeyDown={handleEnterPress}
                  onBlur={() => handleSearch(filters)}
                />
              </InputGroup>
            </Flex>
          )}
          {'contactSearch' in filters && (
            <Flex direction='column' mb={4}>
              <Text>Contact person</Text>
              <InputGroup>
                {filters.contactSearch && (
                  <RemoveInputContent handleFilterChange={handleFilterChange} name={'contactSearch'}/>
                )}
                <Input
                  paddingLeft='30px'
                  variant='tableSearch'
                  placeholder='Name/email'
                  onChange={(e) => handleFilterChange(e.target.value, 'contactSearch')}
                  value={filters.contactSearch}
                  onKeyDown={handleEnterPress}
                  onBlur={() => handleSearch(filters)}
                />
              </InputGroup>
            </Flex>
          )}
          {'city' in filters && (
            <Flex direction='column' mb={4}>
              <Text>City</Text>
              <InputGroup>
                {filters.city && (
                  <RemoveInputContent handleFilterChange={handleFilterChange} name={'city'}/>
                )}
                <Input
                  paddingLeft='30px'
                  variant='tableSearch'
                  placeholder='Search by city'
                  onChange={(e) => handleFilterChange(e.target.value, 'city')}
                  value={filters.city}
                  onKeyDown={handleEnterPress}
                  onBlur={() => handleSearch(filters)}
                />
              </InputGroup>
            </Flex>
          )}
          {'country' in filters && (
            <Flex direction='column' mb={4} width='300px'>
              <Text>Country</Text>
              <Select
                isMulti
                options={sortedCountries?.map((c: string) => ({value: c, label: getCountryName(c)}))}
                placeholder='Select countries'
                onChange={
                  // eslint-disable-next-line
                  (e: any) => handleArrayFilterChange(e, 'country')
                }
                closeMenuOnSelect={false}
                value={filters.country?.map((c) => ({value: c, label: getCountryName(c)}))}
                chakraStyles={countrySelectorStyles}
              />
            </Flex>
          )}

          {
            isListFilter
            && <Flex direction='column' mb={4} width='300px'>
              <Flex justifyContent='space-between'>
                <Text>{'From date (shooting time)'}</Text>
                <Text
                  fontWeight={isCurrentDay && 'bold'}
                  opacity={!isCurrentDay && '0.7'}
                  color={'#6b52ff'}
                  fontSize='12px'
                  cursor='pointer'
                  textDecoration='underline'
                  pt='5px'
                  onClick={() => !isCurrentDay && handleFilterChange(moment(), 'from')}
                >
                  {'Today'}
                </Text>
              </Flex>
              <SingleDatepicker
                key={filters.from.toString()}
                date={filters.from.toDate()}
                onDateChange={(date) => {
                  handleFilterChange(moment(date), 'from');
                }}
                propsConfigs={DatePickerStyles}
                configs={{firstDayOfWeek: 1}}
              />
            </Flex>
          }

          {'to' in filters && isListFilter && (
            <Flex direction='column' mb={4} width='300px'>
              <Text>{'To date (shooting time)'}</Text>
              <SingleDatepicker
                date={filters.to.toDate()}
                onDateChange={(date) => handleFilterChange(moment(date), 'to')}
                propsConfigs={DatePickerStyles}
                configs={{firstDayOfWeek: 1}}
              />
            </Flex>
          )}
          {'statuses' in filters && (
            <Flex direction='column' mb={4} width='300px'>
              <Text>Status</Text>
              <Select
                isMulti
                options={isListFilter ? filterStateOptions : filterStateOptions.slice(0, -1)}
                components={{Option}}
                placeholder='Select statuses'
                onChange={
                  // eslint-disable-next-line
                  (e: any) => handleArrayFilterChange(e, 'statuses')
                }
                closeMenuOnSelect={false}
                value={filters.statuses?.map((s) => ({value: s, label: s}))}
                chakraStyles={chakraStyles}
              />
            </Flex>
          )}
          {
            filters.packageId !== undefined
            && <Flex direction='column' mb={4}>
              <Flex justifyContent='space-between'>
                <Text>Package name:</Text>
                <Checkbox
                  size={'sm'}
                  isChecked={showInactivePackages}
                  onChange={(e) => {
                    setShowInactivePackages(e.target.checked);
                  }}
                >
                  <Text fontSize='13px'>
                    Show inactive packages
                  </Text>
                </Checkbox>
              </Flex>

              <InputGroup zIndex={1}>
                <AsyncSelect
                  placeholder='Select package'
                  loadOptions={(searchText, callback) =>
                    loadPackageOptions(searchText, callback, showInactivePackages, getPackages)
                  }
                  onChange={(e) => handleFilterChange(e.value, 'packageId')}
                  chakraStyles={citySelectorStyles}
                  isClearable={!!filters.packageId}
                  components={{
                    DropdownIndicator: () => null,
                    ClearIndicator: () => (
                      <RemoveInputContent
                        handleFilterChange={handleFilterChange}
                        name={'packageId'}
                      />
                    ),
                  }}
                  value={
                    filters.packageId
                      ? {
                        label: getCurrentPackageName(filters.packageId, packages),
                        value: filters.packageId,
                      }
                      : {label: 'Select package name', value: ''}
                  }
                />
              </InputGroup>
            </Flex>
          }
          {'isFlagged' in filters && (
            <Flex mt='5px' direction='column'>
              <Checkbox
                size={'lg'}
                isChecked={filters.isFlagged}
                onChange={(e) => handleFilterChange(e.target.checked, 'isFlagged')}
              >
                Show only flagged projects
              </Checkbox>
            </Flex>
          )}
          {'noEditor' in filters && (
            <Flex mt='5px' direction='column'>
              <Checkbox
                size={'lg'}
                isChecked={filters.noEditor}
                onChange={(e) => handleFilterChange(e.target.checked, 'noEditor')}
              >
                Waiting for editor
              </Checkbox>
            </Flex>
          )}
        </Grid>
      )}
      <Flex justifyContent='space-between' m='0px 20px' alignItems='center'>
        {showFilters && (
          <Button
            onClick={() =>
              handleResetFilters(
                setFilters,
                handleSearch,
                filters,
                isListFilter,
              )
            }
            variant='photonMedium'
            ml={{base: '80px', lg: '15px'}}
            mt={{base: '20px', lg: '10px'}}
            w='130px'
          >
            Reset filters
          </Button>
        )}
        {<Buttons/>}
      </Flex>
    </Box>
  );
};

export default ProjectListFilters;
