import React from 'react';
import MenuLayout from '../../layouts/MenuLayout';
import RoleGuard from '../../layouts/RoleGuard';
import {adminRoles} from '../../variables/roles';
import PhotoroomTemplates from './PhotoroomTemplates';


export default function PhotoroomTemplatesPage() {
  return (
    <RoleGuard page roles={[adminRoles.RolePhotonAdmin.key, adminRoles.RolePhotonSuperAdmin.key]}>
      <MenuLayout page={<PhotoroomTemplates />} />
    </RoleGuard>
  );
}
