import {
  Button,
  Flex,
  Box,
  Link,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from '@chakra-ui/react';
import {useEffect} from 'react';
import React from 'react';
import {
  ManagePackageOption,
  PackageStatus,
} from '../constants';
import config from 'config';
import {CiMenuKebab} from 'react-icons/ci';
import {useManagePackage} from 'api/packages';
import Bugsnag from '@bugsnag/js';
import {PackageListFilterTypes} from '../types';


const PackageManager = ({
  packageId,
  status,
  refetch,
}: {
  packageId: string;
  status: string;
  refetch:(updatedFilters?: PackageListFilterTypes) => void;
}) => {
  const [{error}, managePackage] = useManagePackage(packageId);
  const toast = useToast();

  const handleManagePackage = async (type: string) => {
    try {
      const res = await managePackage({data: {type}});
      toast({
        title: res.data.message,
        status: 'success',
        duration: 3000,
      });
      refetch();
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  useEffect(() => {
    if (error) {
      toast({
        title: error.response?.data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [error, toast]);

  return (
    <Box w='100%'>
      <Flex>
        <Link
          href={`${config.oldAdminUrl}/#/enterprise-packages/${packageId}/show`}
          isExternal
        >
          <Button variant='photonLight' mt='0px'>
            View
          </Button>
        </Link>
        <Link href={`${config.oldAdminUrl}/#/enterprise-packages/${packageId}`} target='_blank'>
          <Button variant='photonMedium'>Edit</Button>
        </Link>
        <Popover>
          <PopoverTrigger>
            <Button ml='5px'>
              <CiMenuKebab />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverCloseButton />
            <PopoverBody mt='20px'>
              <Flex direction='column'>
                <Button
                  isDisabled={status === PackageStatus.ENABLED}
                  onClick={() => handleManagePackage(ManagePackageOption.ACTIVATE)}
                >
                  Activate
                </Button>
                <Button
                  isDisabled={status !== PackageStatus.ENABLED}
                  onClick={() => handleManagePackage(ManagePackageOption.CANCEL)}
                >
                  Cancel
                </Button>
                <Button
                  isDisabled={status === PackageStatus.ENABLED}
                  onClick={() => handleManagePackage(ManagePackageOption.DELETE)}
                >
                  Delete
                </Button>
                <Button>Duplicate</Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </Box>
  );
};
export default PackageManager;
