export const ProjectPrice: Record<string, string> = {
  CLIENT_PRICE: 'clientPrice',
  CREATIVE_PRICE: 'creativePrice',
  EDITOR_PRICE: 'editorPrice',
  CREATIVE_FEE: 'creativeFee',
  CLIENT_CANCELLATION_FEE: 'clientCancellationFee',
  CREATIVE_LATE_FEE: 'creativeLateFee',
  CREATIVE_CONTRACTUAL_FEE: 'creativeContractualFee',
};

type ValueOf<T> = T[keyof T];
export type ProjectPriceType = ValueOf<typeof ProjectPrice>;

export const ProjectState: Record<string, string> = {
  CREATED: 'created',
  STARTED: 'started',
  ON_HOLD: 'on-hold',
  IN_PROGRESS: 'in-progress',
  PHOTOGRAPHER_EDITING: 'photographer-editing',
  AI_POST_PRODUCTION: 'ai-post-production',
  EDITOR_POST_PRODUCTION: 'editor-post-production',
  DELIVERED: 'delivered',
  COMPLAIN_IN_PROGRESS: 'complain-in-progress',
  FINISHED: 'finished',
  CANCELED: 'canceled',
  DELETED: 'deleted',
  POST_PROCESSING: 'post-processing',
  POST_PROCESSING_ADMIN_CONFIRM: 'post-processing-admin-confirm',
  RETOUCHING: 'retouching',
};

export const filterStateOptions: {
  value: string,
  label: string
}[] = Object.values(ProjectState).slice(0, -3).map((value: string) => ({value, label: value}));

export const ProjectEvent: Record<string, string> = {
  ADDITIONAL_PRICE_ITEM: 'additionalPriceItem',
  PRICE_CORRECTION: 'priceCorrection',
  PROJECT_DATA_MODIFICATION: 'projectDataModification',
  CREATIVE_LATE_FEE: 'creativeLateFee',
  CANCEL_BY_CREATIVE: 'cancelByCreative',
  FEE_REMOVAL: 'feeRemoval',
  APPLY: 'apply',
  EDITOR_APPLY: 'editorApply',
  STILL_TAKE: 'stillTake',
};

export const DATE_CHANGE_REASON: Record<string, { value: string, label: string }> = {
  CLIENT: {value: 'client', label: 'The client/venue'},
  PHOTON: {value: 'photon', label: 'Photon'},
};

export const ContractualPenaltyReason: Record<string, string>={
  LATE_ARRIVAL: 'late-arrival',
  EARLY_LEAVE: 'early-leave',
  OTHER: 'other',
};

export const contractualPenaltyReasons = [
  {value: ContractualPenaltyReason.LATE_ARRIVAL, label: 'the photographer was late for the photo shoot'},
  {
    value: ContractualPenaltyReason.EARLY_LEAVE,
    label: 'the photographer left the location before the end of the shooting',
  },
  {value: ContractualPenaltyReason.OTHER, label: 'other'},
];

export const ClientCancellationReasonCategory: Record<string, string> = {
  LOGISTICAL_ISSUES: 'logisticalIssues',
  COMMUNICATION_ISSUES: 'communicationIssues',
  UNEXPECTED_CIRCUMSTANCES: 'unexpectedCircumstances',
  PHOTOS_NOT_NEEDED: 'photosNotNeeded',
  OTHER: 'other',
  PHOTON: 'photon',
};

export const LogisticalIssues: Record<string, string> = {
  NO_OWNER_PRESENT: 'ownerNotPresent',
  NOBODY_PRESENT: 'nobodyPresent',
  NOT_ENOUGH_STAFF: 'notEnoughStaff',
  NO_INGREDIENTS: 'noIngredients',
  FORGOT: 'forgot',
};

export const CommunicationIssues: Record<string, string> = {
  UPSET_WITH_CLIENT: 'upsetWithClient',
  UNAWARE_OF_SHOOT: 'unawareOfShoot',
  THOUGHT_ONLY_MENU: 'thoughtOnlyMenu',
};

export const UnexpectedCircumstances: Record<string, string> = {
  TECHNICAL: 'technical',
  ILLNESS: 'illness',
  BAD_TIMING: 'badTiming',
  POOR_CONDITIONS: 'poorConditions',
};

export const PhotosNotNeeded: Record<string, string> = {
  ALREADY_HAVE_PHOTOS: 'alreadyHavePhotos',
  HIGH_COSTS: 'highCosts',
};

export const clientCancellationMainCategories: Record<string, string[]> = {
  [ClientCancellationReasonCategory.LOGISTICAL_ISSUES]: Object.values(LogisticalIssues),
  [ClientCancellationReasonCategory.COMMUNICATION_ISSUES]: Object.values(CommunicationIssues),
  [ClientCancellationReasonCategory.UNEXPECTED_CIRCUMSTANCES]: Object.values(UnexpectedCircumstances),
  [ClientCancellationReasonCategory.PHOTOS_NOT_NEEDED]: Object.values(PhotosNotNeeded),
  [ClientCancellationReasonCategory.OTHER]: [],
  [ClientCancellationReasonCategory.PHOTON]: [],
};

export const clientCancellationReasonLabels: Record<string, string> = {
  [ClientCancellationReasonCategory.LOGISTICAL_ISSUES]: 'Logistical issues',
  [ClientCancellationReasonCategory.COMMUNICATION_ISSUES]: 'Communication issues',
  [ClientCancellationReasonCategory.UNEXPECTED_CIRCUMSTANCES]: 'Unexpected circumstances',
  [ClientCancellationReasonCategory.PHOTOS_NOT_NEEDED]: 'Photos not needed',
  [ClientCancellationReasonCategory.OTHER]: 'Other',
  [ClientCancellationReasonCategory.PHOTON]: 'Photon',
  [LogisticalIssues.NO_OWNER_PRESENT]: 'No owner present',
  [LogisticalIssues.NOBODY_PRESENT]: 'Nobody present',
  [LogisticalIssues.NOT_ENOUGH_STAFF]: 'Not enough staff',
  [LogisticalIssues.NO_INGREDIENTS]: 'No ingredients',
  [LogisticalIssues.FORGOT]: 'Forgot',
  [CommunicationIssues.UPSET_WITH_CLIENT]: 'Upset with client',
  [CommunicationIssues.UNAWARE_OF_SHOOT]: 'Unaware of shoot',
  [CommunicationIssues.THOUGHT_ONLY_MENU]: 'They thought we’d only shoot the menu, not the food',
  [UnexpectedCircumstances.TECHNICAL]: 'Technical issue',
  [UnexpectedCircumstances.ILLNESS]: 'Illness',
  [UnexpectedCircumstances.BAD_TIMING]: 'Bad timing',
  [UnexpectedCircumstances.POOR_CONDITIONS]: 'Poor conditions',
  [PhotosNotNeeded.ALREADY_HAVE_PHOTOS]: 'Already have photos',
  [PhotosNotNeeded.HIGH_COSTS]: 'Costs too high',
};
export const ContactCall: Record<string, string> = {
  NO_CALL_YET: 'noCallYet',
  SUCCESFULL_CALL: 'successfullCall',
  FAILED_CALL: 'failedCall',
};

export const contactCallColors: Record<string, string> = {
  [ContactCall.NO_CALL_YET]: '#718096',
  [ContactCall.SUCCESFULL_CALL]: '#08cf08',
  [ContactCall.FAILED_CALL]: '#fa0202',
};

export const contactCallLabels: Record<string, string> = {
  [ContactCall.NO_CALL_YET]: 'Waiting for a call',
  [ContactCall.SUCCESFULL_CALL]: 'Venue reached',
  [ContactCall.FAILED_CALL]: 'Not responding',
};

export const onboardingOrPackageStatus = {
  REGISTRATION_NOT_FINISHED: 'registration-not-finished',
  NOT_ON_PACKAGE: 'not-on-package',
};
