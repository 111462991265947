import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useClipboard,
} from '@chakra-ui/react';
import moment from 'moment';
import {useCallback} from 'react';
import {LuCopyCheck, LuCopy} from 'react-icons/lu';


export default function ClientUserManage(props: { clientUserData: any }) {
  const {clientUserData} = props;
  const {onCopy, hasCopied} = useClipboard(clientUserData.plus?.brand?.regLink);


  const copyRegistrationLinkButton = useCallback(() => {
    return !!clientUserData.plus.brand.regLink
          && (
            <Button ml='5px' onClick={onCopy} h='25px' >
              {hasCopied ? <LuCopyCheck size={20} /> : <LuCopy size={20} />}
            </Button>
          );
  }, [clientUserData]);


  return <Box mt='50px'>
    <Box bg='white' borderRadius='20px' p='20px' h='30%' m='20px' w='400px'>
      <Text fontWeight='bold' fontSize='20px'>
              Events
      </Text>

      <Table size='sm' m='10px'>
        <Thead textAlign='center'>
          <Tr borderBottom='solid #E2E8F0 2px'>
            <Th textAlign='left'>Event</Th>
            <Th textAlign='center'>Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {clientUserData.plus?.brand?.invitedAt
                  && <Tr>
                    <Td>
                        Invitation
                      {copyRegistrationLinkButton()}
                    </Td>
                    <Td textAlign='center'>
                      {moment(clientUserData.plus.brand.invitedAt).format('HH:mm DD.MM.YYYY')}
                    </Td>
                  </Tr>
          }
          <Tr>
            <Td>Setting password</Td>
            <Td textAlign='center'>
              {moment(clientUserData.createdAt).format('HH:mm DD.MM.YYYY')}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  </Box>;
}

