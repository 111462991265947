import {useCallback, useEffect} from 'react';
import {Box, Button, Link, Text} from '@chakra-ui/react';
import {NavLink} from 'react-router-dom';
import {useGetEditors} from 'api/editors';
import {EditorListFilterTypes} from './types';
import {Cell} from 'react-table';
import EditorListFilters from './components.tsx/EditorListFilters';
import {EditorStatusLabels} from './constants';
import EditorListMenu from './components.tsx/EditorListMenu';
import useCountryList from 'hooks/useCountryList';
import {clearFilters} from 'utils/clear-filters';
import ListTable from 'components/ListTable';
import {useListQueryContext} from 'contexts/useListQueryContext';

export default function EditorList() {
  const {getCountryName} = useCountryList();

  const {
    pageNumber,
    perPage,
    filters,
    setFilters,
    updateFilterSearchParams,
    sorting,
    setPageNumber,
  } = useListQueryContext<EditorListFilterTypes>();

  const [
    {data: editorsData, loading},
    getEditors,
  ] = useGetEditors();


  const callGetEditors = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: EditorListFilterTypes,
    ) => {
      try {
        const updatedFilters = clearFilters(filters);
        await getEditors({
          params: {
            page,
            perPage,
            sorting,
            filters: updatedFilters,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getEditors],
  );

  const handleSearch =( updatedFilters?: EditorListFilterTypes)=> {
    callGetEditors(1, perPage, sorting, updatedFilters || filters);
    setPageNumber(1);
    updateFilterSearchParams(updatedFilters);
  };

  useEffect(() => {
    callGetEditors(pageNumber, perPage, sorting, filters);
  }, [pageNumber, perPage, sorting]);


  const columnsDataEditors = {
    name: {
      columnDef: {
        Header: 'Name',
        accessor: 'name',
      },
    },
    email: {
      columnDef: {
        Header: 'Email',
        accessor: 'email',
      },
    },
    phone: {
      columnDef: {
        Header: 'Phone',
        accessor: 'phone',
      },
    },
    status: {
      columnDef: {
        Header: 'Status',
        accessor: 'status',
      },
      // eslint-disable-next-line
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text color='black' fontWeight='bold'>
            {EditorStatusLabels[cell.value]}
          </Text>
        );
      },
    },
    country: {
      columnDef: {
        Header: 'Country',
        accessor: 'country',
      },
      // eslint-disable-next-line
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text color='black' fontWeight='bold'>
            {getCountryName(cell.value)}
          </Text>
        );
      },
    },
    _id: {
      columnDef: {
        Header: 'Actions',
        accessor: '_id',
        width: 250,
      },
      // eslint-disable-next-line
      renderer: (cell: Cell<any, any>) => {
        return (
          <Link as={NavLink} to={`/intel/editor/${cell.value}`}>
            <Button variant='photonLight'>View</Button>
          </Link>
        );
      },
    },
  };

  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      {sorting&& <ListTable
        variant='underlineRows'
        menuComp={<EditorListMenu onRefresh={handleSearch}/>}
        label={'Editors'}
        columnsData={Object.values(columnsDataEditors).map(
          // eslint-disable-next-line
          (column: any) => column.columnDef,
        )}
        renderersData={columnsDataEditors}
        tableData={editorsData?.data}
        total={editorsData?.total}
        filterComp={
          <EditorListFilters
            filters={filters}
            setFilters={setFilters}
            handleSearch={handleSearch}
          />
        }
        isLoading={loading}
      />}
    </Box>
  );
}
