
export const adminRoles = {
  RolePhotonJury: {
    key: 'jury',
    label: 'Jury',
  },
  RolePhotonManager: {
    key: 'photonAdminManagerRole',
    label: 'Manager',
  },
  RolePhotonAdmin: {
    key: 'admin',
    label: 'Administrator',
  },
  RolePhotonSuperAdmin: {
    key: 'photonAdminSuperAdminRole',
    label: 'SuperAdmin',
  },
};
