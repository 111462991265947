export const textStyles= {
  components: {
    Text: {
      variants: {
        stat: {
          color: 'grey',
          fontSize: '15px',
          ml: '5px',
        },
        title: {
          fontSize: '30px',
          fontWeight: 'bold',
          marginBottom: '20px',
        },
      },
    },
  },
};
