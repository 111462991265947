import React, {useEffect} from 'react';
import {
  Button,
  Grid,
  Flex,
  InputGroup,
  Text,
  Box,
  Select,
  InputRightElement,
} from '@chakra-ui/react';
import {TiDeleteOutline} from 'react-icons/ti';
import {useSearchParams} from 'react-router-dom';
import {
  citySelectorStyles,
  useLoadCityOptionsWithDebounce,
} from 'views/creative/helpers/getCities';
import {AsyncSelect} from 'chakra-react-select';
import useCountryList from 'hooks/useCountryList';
import {CountryItem} from 'services/country-list';


export type RecruitmentListFilterTypes = {
  country: any;
  city: string;
  status: string;
};

const RemoveInputContent = ({
  handleFilterChange,
  name,
}: {
  handleFilterChange: Function;
  name: string;
}) => {
  return (
    <InputRightElement>
      <Button size='20' onClick={() => handleFilterChange('', name)}>
        <TiDeleteOutline size='20' />
      </Button>
    </InputRightElement>
  );
};

const handleResetFilters = async (
  setFilters: React.Dispatch<React.SetStateAction<RecruitmentListFilterTypes>>,
  handleSearch: (updatedFilters: RecruitmentListFilterTypes) => Promise<void>,
) => {
  const updatedFilters = {city: '', country: '', status: ''};
  await setFilters(updatedFilters);
  await handleSearch(updatedFilters);
};

const RecruitmentListFilters = ({
  filters,
  handleSearch,
  setFilters,
}: {
  filters: RecruitmentListFilterTypes;
  handleSearch: (updatedFilters: RecruitmentListFilterTypes) => Promise<void>;
  setFilters: React.Dispatch<React.SetStateAction<RecruitmentListFilterTypes>>;
}) => {
  const loadCityOptionsWithDebounce = useLoadCityOptionsWithDebounce();
  const [, setSearchParams] = useSearchParams();
  const {countryList} = useCountryList();


  const handleFilterChange = (value: string | Date, name: keyof RecruitmentListFilterTypes) => {
    let updatedFilters: Partial<RecruitmentListFilterTypes> = {...filters, [name]: value};

    if (name === 'country') {
      updatedFilters = {...filters, city: '', [name]: value};
    } else {
      updatedFilters = {...filters, [name]: value};
    }
    setFilters(updatedFilters as RecruitmentListFilterTypes);
    handleSearch(updatedFilters as RecruitmentListFilterTypes);
  };

  useEffect(() => {
    setSearchParams((params) => {
      Object.entries(filters).forEach(([key, value]) => {
        params.set(key, encodeURIComponent(value));
      });
      return params;
    });
  }, [filters, setSearchParams]);

  const getCountryValue = () => {
    let country;
    if (filters.city) {
      country = filters.city.split(', ')[1];
    } else {
      country = filters.country;
    }
    return country;
  };

  return (
    <Box w='100%' >
      <Grid
        templateColumns='repeat(auto-fit, minmax(320px, 1fr))'
        gap='0px 0px'
        justifyItems='center'
        alignItems='center'
        p='0px'
      >
        {
          <Flex direction='column' mb={4}>
            <Text>City:</Text>
            <InputGroup zIndex={1}>
              <AsyncSelect
                placeholder='Select city'
                loadOptions={loadCityOptionsWithDebounce}
                onChange={(e: any) => handleFilterChange(e.value, 'city')}
                chakraStyles={citySelectorStyles}
                getOptionValue={(option: any) => option.value}
                getOptionLabel={(option: any) => option.label}
                isClearable={!!filters.city}
                value={
                  filters.city
                    ? {value: filters.city, label: filters.city}
                    : {value: '', label: 'Select city'}
                }
                components={{
                  DropdownIndicator: () => null,
                  ClearIndicator: () => (
                    <RemoveInputContent handleFilterChange={handleFilterChange} name={'city'} />
                  ),
                }}
              />
            </InputGroup>
          </Flex>
        }

        {
          <Flex direction='column' mb={4} width='300px'>
            <Text>Country:</Text>
            <Select
              onChange={(e) => handleFilterChange(e.target.value, 'country')}
              placeholder='Select country'
              value={getCountryValue()}
            >
              {countryList.map((country: CountryItem) => (
                <option key={country.value} value={country.value}>
                  {country.label}
                </option>
              ))}
            </Select>
          </Flex>
        }

        {
          <Flex direction='column' mb={4} width='300px'>
            <Text>Status:</Text>
            <Select
              onChange={(e) => handleFilterChange(e.target.value, 'status')}
              placeholder='Select status'
              value={filters.status}
            >
              <option key={'active'} value={'active'}>
                Active
              </option>
              <option key={'inactive'} value={'inactive'}>
                Inactive
              </option>
            </Select>
          </Flex>
        }
        <Flex justifyContent={{base: 'center', md: 'start'}} w='300px'>
          <Button
            onClick={() => handleResetFilters(setFilters, handleSearch)}
            variant='photonMedium'
            w='130px'
          >
            Reset filters
          </Button>
        </Flex>
      </Grid>
    </Box>
  );
};
export default RecruitmentListFilters;
