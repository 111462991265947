/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {Box, Button, Flex, FormControl, FormLabel, useToast, Text} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {useUpdateProject} from 'api/projects';
import {useParams} from 'react-router-dom';
import CreativeHandler from './CreativeHandler';
import EditorHandler from './EditorHandler';
import {CustomFieldStyle} from '../variables/styles';
import {CustomField} from './ProjectBasicData';
import {useFormContext} from 'contexts/useFormContext';


const ContributorBoxStyle = {
  flexDirection: 'column',
  p: '10px',
  m: '5px',
  mb: '20px',
  w: {base: '90vw', lg: '420px'},
  maxW: '420px',
};


export default function ProjectContributors(props: { projectData: any; refetch: Function }) {
  const {projectData, refetch} = props;
  const {projectId} = useParams();
  const {setIsFormDirty, shouldReset, setShouldReset} = useFormContext();
  const toast = useToast();
  const [editField, setEditField] = useState('');

  const defaultValues = {contact: projectData.contact};
  const {
    handleSubmit,
    register,
    getValues,
    formState: {isDirty},
    reset,
  } = useForm({
    defaultValues,
  });
  useEffect(() => {
    if (isDirty) {
      setShouldReset(false);
      setIsFormDirty(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    if (shouldReset) reset(defaultValues);
  }, [shouldReset]);

  const [{loading: updateProjectInProgress}, updateProject] = useUpdateProject(projectId);


  const handleSaveClick = async (data: any) => {
    try {
      await updateProject({data});
      toast({
        title: 'Project updated',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      // @ts-ignore
      toast({title: error.response.data.message, status: 'error'});
    }
    refetch();
    setIsFormDirty(false);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <Flex justifyContent='space-around' direction={{base: 'column', lg: 'row'}}>
          <Flex direction='column' alignItems={{base: 'center', lg: 'start'}}>
            <Flex sx={ContributorBoxStyle}>
              <Text align='center' fontWeight='bold'>
                Project Creator
              </Text>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Flex {...CustomFieldStyle}>
                  <Text>{projectData.creatorObj.name}</Text>
                </Flex>
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Flex {...CustomFieldStyle}>
                  <Text>{projectData.creatorObj.email}</Text>
                </Flex>
              </FormControl>
              <FormControl>
                <FormLabel>Phone</FormLabel>
                <Flex {...CustomFieldStyle}>
                  <Text>{projectData.creatorObj.phone}</Text>
                </Flex>
              </FormControl>
            </Flex>
            <Flex sx={ContributorBoxStyle}>
              <Flex justifyContent='center'>
                <Text align='center' fontWeight='bold'>
                  Photographer
                </Text>
                <Box right='10px'>
                  <CreativeHandler project={projectData} refetch={refetch} />
                </Box>
              </Flex>
              {projectData.photographer && (
                <>
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Flex {...CustomFieldStyle}>
                      <Text>{projectData.photographerObj.name}</Text>
                    </Flex>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Flex {...CustomFieldStyle}>
                      <Text>{projectData.photographerObj.email}</Text>
                    </Flex>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Flex {...CustomFieldStyle}>
                      <Text>{projectData.photographerObj.phone}</Text>
                    </Flex>
                  </FormControl>
                </>
              )}
            </Flex>
          </Flex>

          <Flex direction='column' alignItems={{base: 'center', lg: 'start'}}>
            <Flex sx={ContributorBoxStyle}>
              <Text align='center' fontWeight='bold'>
                Contact person
              </Text>
              <CustomField
                label='Name'
                id='contact.name'
                register={register}
                getValues={getValues}
                setEditField={setEditField}
                editField={editField}
                shouldReset={shouldReset}
                defaultValue={defaultValues.contact.name}  
              />
              <CustomField
                label='Email'
                id='contact.email'
                register={register}
                getValues={getValues}
                setEditField={setEditField}
                editField={editField}
                shouldReset={shouldReset}
                defaultValue={defaultValues.contact.email}  
              />
              <CustomField
                label='Phone'
                id='contact.phone'
                register={register}
                getValues={getValues}
                setEditField={setEditField}
                editField={editField}
                shouldReset={shouldReset}
                defaultValue={defaultValues.contact.phone}  
              />
            </Flex>

            {projectData.manualEditingEnabled && (
              <Flex sx={ContributorBoxStyle}>
                <Flex justifyContent='center'>
                  <Text align='center' fontWeight='bold'>
                    Editor
                  </Text>
                  <Box right='10px'>
                    <EditorHandler project={projectData} refetch={refetch} />
                  </Box>
                </Flex>
                {projectData.editor && (
                  <>
                    <FormControl>
                      <FormLabel>Name</FormLabel>
                      <Flex {...CustomFieldStyle}>
                        <Text>{projectData.editorObj.name}</Text>
                      </Flex>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Email</FormLabel>
                      <Flex {...CustomFieldStyle}>
                        <Text>{projectData.editorObj.email}</Text>
                      </Flex>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Phone</FormLabel>
                      <Flex {...CustomFieldStyle}>
                        <Text>{projectData.editorObj.phone}</Text>
                      </Flex>
                    </FormControl>
                  </>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex justifyContent='center' m='50px'>
          <Button isLoading={updateProjectInProgress} type='submit' variant='photonDark' w='200px'>
            Save
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
