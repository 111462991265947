import {ClientUserListFilterTypes} from 'views/client-user/types';
import {ClientListFilterTypes} from 'views/clients/types';
import {CreativeListFilterTypes} from 'views/creative/types';
import {EditorListFilterTypes} from 'views/editor/types';
import {PhotoroomTemplateFilterTypes} from 'views/photoroom-templates/PhotoroomTemplateFilters';
import {ProjectListFilterTypes} from 'views/projects/types';
import {RecruitmentListFilterTypes} from 'views/recruitment/RecruitmentListFilters';

export const clearFilters = (
  filters:
      | ProjectListFilterTypes
      | CreativeListFilterTypes
      | RecruitmentListFilterTypes
      | PhotoroomTemplateFilterTypes
      | ClientUserListFilterTypes
      | EditorListFilterTypes
      | ClientListFilterTypes,
) => {
  return Object.fromEntries(
    Object.entries(filters).map(([key, value]) => [
      key,
      value === '' || (Array.isArray(value) && value.length === 0) ? undefined : value,
    ]),
  );
};
