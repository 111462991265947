import React, {useCallback, useEffect, useState} from 'react';

import {Box} from '@chakra-ui/react';

import CreativeListTable from './components/CreativeListTable';
import {columnsDataNewApplicants} from './variables/columnsData';
import {useGetNewApplicantCreatives} from '../../api/staff-api';
import {CreativeListFilterTypes} from './types';
import {useLocation, useSearchParams} from 'react-router-dom';
import {decodeBooleanParam, decodeStringArrayParam, decodeStringParam} from 'utils/decode-url-params';
import {CreativeTableType} from './constants';

export default function NewApplicantCreativeList() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    country = '',
    service = '',
  } = Object.fromEntries(queryParams.entries());

  const status = decodeStringArrayParam(queryParams, 'status');
  const tags = decodeStringArrayParam(queryParams, 'tags');
  const workingArea = decodeStringArrayParam(queryParams, 'workingArea');
  const homeTown = decodeStringParam(queryParams, 'homeTown');
  const search = decodeStringParam(queryParams, 'search');
  const adminFlag = decodeBooleanParam(queryParams, 'adminFlag');
  const notBookedYet = decodeBooleanParam(queryParams, 'notBookedYet');


  const [
    {data: creativesData},
    getCreatives,
  ] = useGetNewApplicantCreatives();

  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
  const [perPage, setPerPage] = useState(parseInt(searchParams.get('perPage')) || 10);
  const [sorting, setSorting] = useState({
    field: searchParams.get('sort') || 'createdAt',
    order: parseInt(searchParams.get('sortOrder')) || -1,
  });
  const [filters, setFilters] = useState<CreativeListFilterTypes>({
    search,
    country,
    workingArea,
    service,
    status,
    homeTown,
    adminFlag,
    tags,
    notBookedYet,
  });

  const callGetCreatives = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: CreativeListFilterTypes,
    ) => {
      try {
        await getCreatives({
          params: {
            page,
            perPage,
            sorting,
            filters,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getCreatives],
  );

  useEffect(() => {
    callGetCreatives(page, perPage, sorting, filters);
  }, [page, perPage, sorting]);

  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      <CreativeListTable
        columnsData={columnsDataNewApplicants}
        tableData={creativesData?.data}
        total={creativesData?.total}
        page={page}
        perPage={perPage}
        setPerPage={setPerPage}
        setPage={setPage}
        filters={filters}
        setFilters={setFilters}
        callBack={callGetCreatives}
        type={CreativeTableType.NEW_APPLICANTS}
        setSorting={setSorting}
        sorting={sorting}
      />
    </Box>
  );
}
