import React, {useCallback, useEffect} from 'react';
import {
  NavLink,
  useParams,
} from 'react-router-dom';
import {Box, Button, Flex, Link, Stat, StatLabel, StatNumber, Text} from '@chakra-ui/react';
import CreativeSubTable from './CreativeSubTable';
import {columnsDataCreativeProjects, columnsDataCreativeRating} from '../variables/columnsData';
import {useGetCreativeRatingData} from '../../../api/staff-api';
import Bugsnag from '@bugsnag/js';


// eslint-disable-next-line
export default function CreativeRating(props: { creativeData: any }) {
  const {creativeId} = useParams();
  const {creativeData} = props;

  const [
    {data: creativeRatingData},
    getCreativeRatingData,
  ] = useGetCreativeRatingData(creativeId as string);

  const callGetCreativeRatingData = useCallback(
    async () => {
      try {
        await getCreativeRatingData();
      } catch (e) {
        console.log(e);
        Bugsnag.notify(e as Error);
      }
    },
    [getCreativeRatingData],
  );

  useEffect(
    () => {
      callGetCreativeRatingData();
    },
    [],
  );

  return (
    creativeRatingData && (
      <Flex direction='column' justifyContent='center' alignItems='center'>
        <Flex
          justifyContent='space-between'
          margin='10px'
          w={{base: 'auto', md: '1000px'}}
          direction={{base: 'column', md: 'row'}}
        >
          <Stat>
            <StatLabel>Average rating</StatLabel>
            <StatNumber>{creativeData?.averageRating}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Finished projects</StatLabel>
            <StatNumber>{creativeRatingData?.finishedProjectCountByCreative}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Current month's projects</StatLabel>
            <StatNumber>{creativeRatingData?.currentMonthProjectCount}</StatNumber>
          </Stat>
        </Flex>
        <Box m='20px 0px'>
          <Flex
            justifyContent='space-between'
            w={{base: 'auto', md: '1000px'}}
            m='0px 20px'
            ml={{base: '300px', md: '0px'}}
          >
            <Text fontWeight='bold' fontSize='18px'>
              Ratings
            </Text>
            <Button variant='photonMedium'>
              <Link
                as={NavLink}
                to={`/intel/creatives/ratings?creative=${encodeURIComponent(creativeData.name)}`}
              >
                All ratings
              </Link>
            </Button>
          </Flex>
          <CreativeSubTable
            columnsData={columnsDataCreativeRating}
            tableData={creativeRatingData.ratedProjects}
            total={creativeRatingData.ratedProjects.length}
            creativeData={creativeData}
          />
        </Box>
        <Box m='20px 0px'>
          <Flex justifyContent='space-between' m='0px 20px'>
            <Text fontWeight='bold' fontSize='18px'>
              Projects
            </Text>
            <Button variant='photonMedium'>
              <Link
                as={NavLink}
                to={`/intel/projects/list?creative=${encodeURIComponent(creativeData.name)}`}
              >
                All projects
              </Link>
            </Button>
          </Flex>
          <CreativeSubTable
            columnsData={columnsDataCreativeProjects}
            tableData={creativeRatingData.allProjectsOfCreative}
            total={creativeRatingData.allProjectsOfCreative.length}
            creativeData={creativeData}
          />
        </Box>
      </Flex>
    )
  );
}
