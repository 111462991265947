import MenuLayout from 'layouts/MenuLayout';
import RoleGuard from 'layouts/RoleGuard';
import React from 'react';
import {adminRoles} from 'variables/roles';
import ClientList from './ClientList';
import {ListQueryProvider} from 'contexts/useListQueryContext';
import {ClientListFilterTypes} from './types';

export default function ClientListPage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <ListQueryProvider<ClientListFilterTypes>
        defaultFilters={{
          search: '',
          status: '',
          country: '',
          industry: '',
        }}
      >
        <MenuLayout page={<ClientList />} />
      </ListQueryProvider>
    </RoleGuard>
  );
}
