export const UserStatus: Record<string, string> = {
  JUST_REGISTERED: 'justRegUser',
  INVITED: 'invitedUser',
  ACTIVE: 'activeUser',
  DELETED: 'deletedUser',
};

export const UserStatusLabels = {
  [UserStatus.JUST_REGISTERED]: 'Just registered',
  [UserStatus.INVITED]: 'Invited',
  [UserStatus.ACTIVE]: 'Active',
  [UserStatus.DELETED]: 'Deleted',
};


export const UserRoles = {
  OWNER: 'ownerPlus',
  MANAGER: 'managerPlus',
  ADMIN: 'adminPlus',
};

export const UserRoleLabels = {
  [UserRoles.OWNER]: 'Owner',
  [UserRoles.MANAGER]: 'Manager',
  [UserRoles.ADMIN]: 'Admin',
};
