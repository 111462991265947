import React, {useEffect, useState} from 'react';
import {Box, Button, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast} from '@chakra-ui/react';
import {useNavigate, useParams} from 'react-router-dom';
import {useFormContext} from 'contexts/useFormContext';
import useCountryList from 'hooks/useCountryList';
import {useDeleteEditor, useGetEditor, useManageUserStatus} from 'api/editors';
import EditorBillingData from './components.tsx/EditorBilling';
import EditorBasicData from './components.tsx/EditorBasicData';
import {EditorStatus, EditorStatusLabels, ManageEditorOption} from './constants';
import EditorManage from './components.tsx/EditorManage';
import Bugsnag from '@bugsnag/js';

const tabLabels = [
  'Basic data',
  'Billing',
  'Manage',
];

export default function Editor() {
  const {handleTabChange} = useFormContext();
  const {editorId} = useParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const {getCountryName} = useCountryList();

  const [{data: editorData}, getEditor] = useGetEditor(editorId);
  const toast = useToast();
  const navigate = useNavigate();

  const [{error: manageEditorError, loading: manageEditorLoading}, manageEditor] = useManageUserStatus(
    editorId as string,
  );

  const [{error: deleteEditorError}, deleteEditor] = useDeleteEditor(
    editorId as string,
  );

  const handleManage = async (type: string)=>{
    try {
      await manageEditor({data: {type}});
      toast({
        title: 'Editor status updated succesfully',
        status: 'success',
        duration: 3000,
      });
      getEditor();
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  const handleDelete = async ()=>{
    try {
      await deleteEditor();
      toast({
        title: 'Editor deleted succesfully',
        status: 'success',
        duration: 3000,
      });
      navigate('/intel/editors', {replace: true});
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };


  useEffect(() => {
    if (manageEditorError) {
      toast({
        title: 'Could not update editor',
        status: 'error',
        duration: 3000,
      });
    }
  }, [manageEditorError]);

  useEffect(() => {
    if (deleteEditorError) {
      toast({
        title: 'Could not delete editor',
        status: 'error',
        duration: 3000,
      });
    }
  }, [deleteEditorError]);
  const manageOptionValue = editorData?.status === EditorStatus.INACTIVE ? ManageEditorOption.ACTIVATE : ManageEditorOption.DEACTIVATE;


  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      {editorData && (
        <>
          <Flex justifyContent='space-between' direction={{base: 'column', md: 'row'}}
          >
            <Flex
              bg='white'
              alignItems='center'
              w={{base: '90vw', md: '500px'}}
              borderRadius='20px'
              m='10px'
            >
              <Box m='10px 20px' w='80%'>
                <Text fontSize='25px' fontWeight='bold'>
                  {editorData.name}
                </Text>
                <Text>{editorData.email}</Text>
                <Text>{editorData.phone}</Text>
                <Flex justifyContent='space-between' w='90%' alignItems='center'>
                  <Text>{getCountryName(editorData.editor.billing?.address?.country)}</Text>
                </Flex>
              </Box>
              <Text w= '150px' alignSelf='end' mb='10px'>{EditorStatusLabels[editorData.status]}</Text>
            </Flex>
            {selectedTabIndex === 2
              && <Flex m='10px' direction={{base: 'row', md: 'column'}} justifyContent='space-around'>
                <Button
                  onClick={()=>handleManage(manageOptionValue)}
                  isLoading= {manageEditorLoading}
                  variant= {editorData.status === EditorStatus.INACTIVE ? 'photonLight' : 'photonDarkYellow' }
                  m= '0px 0px 10px'
                  w='150px'
                >
                  {manageOptionValue}
                </Button>
                <Button
                  onClick={handleDelete}
                  variant='photonDark'
                  w='150px'
                >
                    Delete
                </Button>
              </Flex>
            }
          </Flex>
          <Tabs index={selectedTabIndex}>
            <TabList ml='0px' mr='20px'>
              {tabLabels.map((label, index) => (
                <Tab
                  key={index}
                  w={{base: '50%', md: 'auto'}}
                  onClick={() => handleTabChange(index, setSelectedTabIndex)}
                >
                  {label}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel>
                <EditorBasicData editorData={editorData} />
              </TabPanel>
              <TabPanel>
                <EditorBillingData editorData={editorData} />
              </TabPanel>
              <TabPanel>
                <EditorManage editorData={editorData} refetch={getEditor}/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Box>
  );
}
