import React, {useState} from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  Select,
  Flex,
} from '@chakra-ui/react';
import {useDeactivateCreative} from 'api/staff-api';
import {deactivateReasons} from '../constants';


export default function DeactivateModal({
  creativeId,
  isOpen,
  onClose,
  refetch,
  getCreativePackages,
}: {
  isOpen: boolean;
  onClose: (successfulDelete?: boolean) => void;
  creativeId: string;
  // eslint-disable-next-line
  refetch: Function;
  // eslint-disable-next-line
  getCreativePackages: Function
}) {
  const toast = useToast();

  const [reason, setReason] = useState('');

  const [{loading}, deactivateCreative] = useDeactivateCreative(creativeId);

  const handleDeactivateCreative = async () => {
    try {
      await deactivateCreative({data: {reason}});
      toast({
        title: 'Creative deactivated successfully',
        status: 'success',
        duration: 3000,
      });
      await refetch();
      getCreativePackages();
      onClose();
    } catch {
      toast({
        title: 'Deactivated creative error',
        status: 'error',
        duration: 3000,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text align='center'>Please specify the cause of deactivation!</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent='center'>
            <Select
              placeholder='Select reason'
              value={reason}
              bg='white'
              onChange={(e) => setReason(e.target.value)}
              w='300px'
              m='5px'
            >
              {deactivateReasons?.map((reason: { value: string; label: string }) => {
                return (
                  <option value={reason.value} key={reason.value}>
                    {reason.label}
                  </option>
                );
              })}
            </Select>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex w='100%' justifyContent='center'>
            <Button mr='5px' w='140px' variant='photonMedium' onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              w='140px'
              variant='photonDark'
              onClick={handleDeactivateCreative}
              isLoading={loading}
              isDisabled={!reason}
            >
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
