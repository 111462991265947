import {useState} from 'react';


export const getItem = (keyName: string) => {
  return window.localStorage.getItem(keyName);
};

const setItem = (keyName: string, keyValue: string) => {
  window.localStorage.setItem(keyName, keyValue);
};

// eslint-disable-next-line
export const useLocalStorage = (keyName: string, defaultValue: any) => {
  const [storedValue, setStoredValue] = useState(
    () => {
      try {
        const value = getItem(keyName);
        if (value) {
          return JSON.parse(value);
        } else {
          setItem(keyName, JSON.stringify(defaultValue));
          return defaultValue;
        }
      } catch {
        return defaultValue;
      }
    },
  );

  // eslint-disable-next-line
  const setValue = (newValue: any) => {
    try {
      setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      console.error('*** useLocalStorage: ', err);
    }
    setStoredValue(newValue);
  };

  const resetValue = () => {
    try {
      setItem(keyName, JSON.stringify(defaultValue));
    } catch (err) {
      console.error('*** useLocalStorage: ', err);
    }
    setStoredValue(defaultValue);
  };

  return [storedValue, setValue, resetValue];
};
