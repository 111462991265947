export const currencyFormatter = (value: number, currency: string) => {
  const formattedNumber
    = currency === 'HUF'
      ? new Intl.NumberFormat('hu-HU').format(value)
      : new Intl.NumberFormat('en-EN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);

  return formattedNumber;
};
