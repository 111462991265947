import React from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Flex,
  Text,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {isValidPhoneNumber} from 'libphonenumber-js';
import {useCreateEditor} from 'api/editors';
import Bugsnag from '@bugsnag/js';

const formSchema = z.object({
  name: z.string().min(3, 'Must be at least 3 characters').max(255, 'Must be less than 255 characters'),
  email: z.string().email('Invalid email address'),
  phone: z.string().refine(
    (phoneNumber) => isValidPhoneNumber(phoneNumber),
    'Not a valid phone number.',
  ),
});

export default function CreateEditorModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (successfulUpdate?: boolean) => void;
}) {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: {errors, isSubmitting},
    reset,
  } = useForm({
    resolver: zodResolver(formSchema),
  });

  const [{error}, createEditor] = useCreateEditor();

  React.useEffect(() => {
    if (error) {
      toast({
        title: 'Create was unsuccessful!',
        description: error.response?.data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [error, toast]);

  const onSubmit = async (formData: any) => {
    try {
      await createEditor({data: formData});
      toast({
        title: 'Editor created successfully!',
        status: 'success',
        duration: 3000,
      });
      onClose(true);
      reset();
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Editor</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex
              justifyContent="space-around"
              direction="column"
              alignItems="center"
              mt="20px"
              w="400px"
            >
              <FormControl id="name" mb={4} isInvalid={!!errors.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Add name"
                  {...register('name')}
                />
                <Text color="red">{errors?.name?.message.toString()}</Text>

              </FormControl>

              <FormControl id="email" mb={4} isInvalid={!!errors.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  placeholder="Add email"
                  {...register('email')}
                />
                <Text color="red">{errors?.email?.message.toString()}</Text>
              </FormControl>

              <FormControl id="phone" mb={4} isInvalid={!!errors.phone}>
                <FormLabel>Phone</FormLabel>
                <Input
                  placeholder="Add phone number"
                  {...register('phone')}
                />
                <Text color="red">{errors?.phone?.message.toString()}</Text>
              </FormControl>
            </Flex>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            variant="photonDark"
          >
            Save
          </Button>
          <Button
            variant="photonMedium"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
