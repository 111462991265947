import React, {useEffect, useMemo, useState} from 'react';

import {
  Box,
  Button,
  Flex,
  Link,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  Wrap,
} from '@chakra-ui/react';
import {useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import {GrLinkNext, GrLinkPrevious} from 'react-icons/gr';
import {NavLink, useSearchParams} from 'react-router-dom';

import Card from 'components/card/Card';
import ProfilePicture from './ProfilePicture';
import {CreativeEvent, CreativeListFilterTypes} from '../types';
import moment from 'moment';
import {BiSolidDownArrowAlt, BiSolidUpArrowAlt} from 'react-icons/bi';
import {useManageCreative, useUpdateCreative} from 'api/staff-api';
import {FaRegCheckCircle} from 'react-icons/fa';
import {TiDeleteOutline} from 'react-icons/ti';
import HiringModal from './HiringModal';
import CreativeListFilters from './ListFilters';
import {
  statusColors,
  newApplicantStates,
  deactivateReasons,
  CreativeEventType,
  tagColors,
  offAndBlacklistReasons,
  otherAndVacationReasons,
  CreativeTableType,
} from '../constants';
import useCountryList from 'hooks/useCountryList';
import ClipboardIcon from 'components/ClipbordIcon';
import {AiFillStar} from 'react-icons/ai';
import Bugsnag from '@bugsnag/js';


export type Row = {
  // eslint-disable-next-line
  original: any;
};

const getTitle = (type: string) => {
  if (type === CreativeTableType.ACTIVE) {
    return 'Onboarded photographers';
  }
  if (type === CreativeTableType.INACTIVE) {
    return 'Deactivated photographers';
  }
  if (type === CreativeTableType.NEW_APPLICANTS) {
    return 'New applicants';
  }
  if (
    type === CreativeTableType.OFF_ONBOARDED
    || type === CreativeTableType.OFF_NEW
  ) {
    return 'Off photographers';
  }
};

export default function CreativeListTable(props: {
  // eslint-disable-next-line
  columnsData: any;
  // eslint-disable-next-line
  tableData: any;
  total: number;
  page: number;
  perPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  // eslint-disable-next-line
  callBack: any;
  filters: CreativeListFilterTypes;
  setFilters: React.Dispatch<React.SetStateAction<CreativeListFilterTypes>>;
  type: string;
  setSorting: React.Dispatch<React.SetStateAction<{ field: string; order: number }>>;
  sorting: { field: string; order: number };
}) {
  const {
    columnsData,
    tableData,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    callBack: callGetCreatives,
    filters,
    setFilters,
    type,
    setSorting,
    sorting,
  } = props;

  const toast = useToast();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData || [], [tableData]);
  const pageSizes = [5, 10, 25, 50, 100];
  const {getCountryName} = useCountryList();

  const [showFilters] = useState(true);
  // eslint-disable-next-line
  const [actualCreative, setActualCreative] = useState<any>({});
  const [, setSearchParams] = useSearchParams();
  const [creativeId, setCreativeId] = useState('');
  const [creativeStatus, setCreativeStatus] = useState('');
  const [judgement, setJudgment] = useState('');
  const {
    isOpen: isHiringModalOpen,
    onOpen: openHiringModal,
    onClose: closeHiringModal,
  } = useDisclosure();
  const [{error: manageCreativeError}] = useManageCreative();
  const [{error: updateCreativeError}, updateCreative] = useUpdateCreative(actualCreative._id);


  useEffect(() => {
    if (manageCreativeError || updateCreativeError) {
      toast({
        title: 'Could not update photographer',
        status: 'error',
        duration: 3000,
      });
    }
  }, [manageCreativeError, updateCreativeError]);

  ;

  useEffect(() => {
    setSearchParams((params) => {
      params.set('page', page.toString());
      return params;
    });
  }, [page]);

  useEffect(() => {
    setPageSize(perPage);
    setSearchParams((params) => {
      params.set('perPage', perPage.toString());
      return params;
    });
  }, [perPage]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: pageData,
    prepareRow,
    setPageSize,
    state: {pageSize},
  } = tableInstance;

  const startRow = total !== 0 ? (page - 1) * perPage + 1 : 0;
  const endRow = Math.min(page * perPage, total);
  const actualEndRow = Math.min(endRow, total);

  const setFilterSearchParams = (filters: CreativeListFilterTypes) => {
    setSearchParams((params) => {
      Object.entries(filters).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          const encodedValues = value
            .map((v) => encodeURIComponent(v.replace(/,/g, '-')))
            .join(',');
          params.set(key, encodedValues);
        } else {
          params.set(key, encodeURIComponent(value));
        }
      });
      return params;
    });
  };

  const handlePageSizeChange = (e: number) => {
    setPage(1);
    setPerPage(e);
  };

  // eslint-disable-next-line
  const handleAdminFlagChange = async (value: boolean, creative: any) => {
    setActualCreative(creative);
    try {
      await updateCreative({data: {adminFlag: value}});
      toast({
        title: 'Photographer updated',
        status: 'success',
        duration: 3000,
      });
      callGetCreatives(page, perPage, sorting, filters);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  const handleSearch = async (updatedFilters?: CreativeListFilterTypes) => {
    try {
      if (updatedFilters) {
        callGetCreatives(page, perPage, sorting, updatedFilters);
      } else {
        callGetCreatives(page, perPage, sorting, filters);
      }
    } catch (e) {
      console.log(e);
    }
    setFilterSearchParams(updatedFilters);
    setPage(1);
  };

  const handleFilterChange = (value: string | Date | string[] | boolean, name: keyof CreativeListFilterTypes) => {
    let updatedValue = value;
    if (name === 'status') {
      // eslint-disable-next-line
      updatedValue = Array.isArray(value) ? value : ([value] as any);
    }
    const updatedFilters: Partial<CreativeListFilterTypes> = {...filters, [name]: updatedValue};
    setFilters(updatedFilters as CreativeListFilterTypes);

    if (name !== 'search' || !value) {
      handleSearch(updatedFilters as CreativeListFilterTypes);
    }
  };

  // eslint-disable-next-line
  const handleSort = (column: any) => {
    const columnId = column.id;
    const clickedColumn = headerGroups
      .flatMap((group) => group.headers)
      .find((column) => column.id === columnId);

    if (clickedColumn && column.sorting) {
      const isColumnAlreadySorted = sorting.field === columnId;
      const order = isColumnAlreadySorted ? sorting.order * -1 : -1;

      setSearchParams((params) => {
        params.set('sort', columnId);
        params.set('order', order as unknown as string);
        return params;
      });
      setPage(1);
      setSorting({field: columnId, order: order});
    }
  };


  return (
    <Card flexDirection='column' w='100%' px='0px'>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
          {getTitle(type)}
        </Text>
      </Flex>
      <Flex>
        {
          showFilters
          && <CreativeListFilters
            handleFilterChange={handleFilterChange}
            filters={filters}
            handleSearch={handleSearch}
            setFilters={setFilters}
            type={type}
            deactivateReasons={
              [
                CreativeTableType.OFF_ONBOARDED,
                CreativeTableType.OFF_NEW,
              ].includes(type) ? offAndBlacklistReasons : otherAndVacationReasons
            }
          />
        }
      </Flex>

      {total > 0 ? (
        <Box overflowX='scroll'>
          <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {
                    // eslint-disable-next-line
                    headerGroup.headers.map((column: any, index: number) => {
                      return (
                        <Th
                          pe='10px'
                          key={index}
                          borderColor={borderColor}
                          textAlign='center'
                          onClick={() => handleSort(column)}
                        >
                          <Flex
                            justify='space-between'
                            fontSize={{sm: '10px', lg: '12px'}}
                            color='gray.400'
                            justifyContent='center'
                            alignItems='center'
                          >
                            {column.render('Header')}
                            {column.sorting && (
                              <Flex direction='column'>
                                {sorting.field === column.id && sorting.order > 0 ? (
                                  <BiSolidUpArrowAlt size='20px' color='#6B52FFB3'/>
                                ) : (
                                  <BiSolidUpArrowAlt opacity={0.5} size='20px'/>
                                )}
                                {sorting.field === column.id && sorting.order < 0 ? (
                                  <BiSolidDownArrowAlt size='20px' color='#6B52FFB3'/>
                                ) : (
                                  <BiSolidDownArrowAlt opacity={0.5} size='20px'/>
                                )}
                              </Flex>
                            )}
                          </Flex>
                        </Th>
                      );
                    })}
                </Tr>
              ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
              {pageData.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    onMouseEnter={() => setActualCreative(row.original)}
                  >
                    {row.cells.map((cell, index) => {
                      const currentRow: Row = cell.row;
                      let data;
                      if (cell.column.id === 'name') {
                        data = (
                          <Flex alignItems="center" justifyContent="start" w='100%' minW='200px' m='0px'>
                            <Box minW="55px" height="55px">
                              <ProfilePicture name={currentRow.original?.name}/>
                            </Box>
                            <Link as={NavLink} to={`/intel/creative/${currentRow.original._id}`}>
                              <Text ml="10px" fontWeight="bold" color="black">
                                {cell.value}
                              </Text>
                            </Link>
                            <ClipboardIcon value={cell.value}/>
                          </Flex>
                        );
                      } else if (cell.column.id === 'action') {
                        data = (
                          <Flex align='center'>
                            <Link as={NavLink} to={`/intel/creative/${currentRow.original._id}`}>
                              <Button variant='photonLight'>View</Button>
                            </Link>
                          </Flex>
                        );
                      } else if (cell.column.id === 'createdAt') {
                        data = (
                          <Flex align='center'>
                            <Text me='10px' color={textColor} fontWeight='700'>
                              {moment(cell.value).format('DD.MM.YYYY')}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.id === 'manage') {
                        data = (
                          <Flex align='center'>
                            <Link as={NavLink} to={`/intel/creative/${currentRow.original._id}`}>
                              <Button variant='photonLight'>Manage</Button>
                            </Link>
                          </Flex>
                        );
                      } else if (cell.column.id === 'hiring') {
                        data = !currentRow.original.events?.some(
                          (e: CreativeEvent) => e.event === CreativeEventType.ACCEPT,
                        ) && (
                          <Flex align='center' justifyContent={'space-around'} w='100%'>
                            <Box color='#a6a3ff' _hover={{color: '#6b52ff'}}>
                              <FaRegCheckCircle
                                size={25}
                                onClick={() => {
                                  setCreativeId(currentRow.original._id);
                                  setCreativeStatus(currentRow.original.status);
                                  openHiringModal();
                                  setJudgment('accepted');
                                }}
                              />
                            </Box>
                            <Box color='#a6a3ff' _hover={{color: '#6b52ff'}}>
                              <TiDeleteOutline
                                onClick={() => {
                                  setCreativeId(currentRow.original._id);
                                  openHiringModal();
                                  setJudgment('rejected');
                                }}
                                size={32}
                              />
                            </Box>
                          </Flex>
                        );
                      } else if (cell.column.id === 'status') {
                        data = (
                          <Flex justifyContent='center'>
                            <Box
                              bg={statusColors[cell.value]?.primary}
                              borderRadius='20px'
                              p='5px 10px'
                              color={statusColors[cell.value]?.secondary}
                              fontWeight='bold'
                              textAlign='center'
                            >
                              <Text>
                                {
                                  newApplicantStates.find((state) => state.value === cell.value)
                                    ?.label
                                }
                              </Text>
                            </Box>
                          </Flex>
                        );
                      } else if (cell.column.id === 'tags') {
                        data = (
                          <Wrap w='180px' spacing="4px">
                            {
                              cell.value?.map(
                                (l: string) => <Flex
                                  key={l}
                                  bg={tagColors[l].primary}
                                  borderRadius="20px"
                                  padding="5px 10px"
                                  color={tagColors[l].secondary}
                                >
                                  <Text fontWeight="bold">{l}</Text>
                                </Flex>,
                              )
                            }
                          </Wrap>
                        );
                      } else if (cell.column.id === 'isInactive') {
                        data = (
                          <Flex>
                            <Text
                              fontWeight='bold'
                              color='black'
                              align='center'
                              bg={cell.value ? '#ffb03b' : '#3cc427'}
                              p='10px'
                              borderRadius='20px'
                              w='80px'
                            >
                              {cell.value ? 'Inactive' : 'Active'}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.id === 'deactivateReason') {
                        data = (
                          <Flex justifyContent='center'>
                            <Text fontWeight='bold' color='black'>
                              {deactivateReasons.find((r) => r.value === cell.value)?.label}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.id === 'billingAddressObj.country') {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {getCountryName(cell.value)}
                          </Text>
                        );
                      } else if (cell.column.id === 'adminFlag') {
                        data = (
                          <Box w='30px'>
                            <Button
                              variant='no-hover'
                              cursor='pointer'
                              bg='white'
                              onClick={() => {
                                handleAdminFlagChange(
                                  !cell.value,
                                  currentRow.original,
                                );
                              }}
                            >
                              <AiFillStar
                                color={cell.value ? '#6b52ff' : 'lightgrey'}
                                size='25'
                              />
                            </Button>
                          </Box>
                        );
                      } else {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value}
                          </Text>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{sm: '14px'}}
                          minW={{sm: '150px', md: '200px', lg: 'auto'}}
                          borderColor='lightgrey'
                          p='10px 20px'
                        >
                          <Flex justifyContent='center'>{data}</Flex>
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Text align='center'> There is no data to display </Text>
      )}

      <Flex justify='space-between' m='10px' px='10px'>
        <Flex>
          <Select
            value={pageSize}
            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
            variant='photonMedium'
            w='75px'
          >
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex>
          <Button onClick={() => setPage(page - 1)} bg='white' isDisabled={!page || page === 1}>
            <GrLinkPrevious/>
          </Button>
          <Flex justify='center' mx='10px' mt='6px'>
            <Text color='gray.400'>
              {startRow}-{actualEndRow} of {total}
            </Text>
          </Flex>
          <Button
            onClick={() => setPage(page + 1)}
            bg='white'
            isDisabled={!page || endRow === total}
          >
            <GrLinkNext/>
          </Button>
        </Flex>
      </Flex>
      {creativeId && (
        <HiringModal
          creativeId={creativeId}
          judgement={judgement}
          isOpen={isHiringModalOpen}
          onClose={closeHiringModal}
          refetch={handleSearch}
          status={creativeStatus}
        />
      )}
    </Card>
  );
}
