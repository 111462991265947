import {Box, Button, Flex, FormControl, FormLabel, Text, useToast} from '@chakra-ui/react';
import {Controller, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useFormContext} from 'contexts/useFormContext';
import {useUpdateEditor} from 'api/editors';
import {MdModeEdit, MdOutlineDone} from 'react-icons/md';
import {IoCloseSharp} from 'react-icons/io5';
import {CustomField} from 'views/projects/components/ProjectBasicData';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';
import {CustomFieldStyle, datePickerStyles} from 'views/projects/variables/styles';


export default function EditorBasicData(props: { editorData: any }) {
  const {setIsFormDirty, shouldReset, setShouldReset} = useFormContext();
  const [editField, setEditField] = useState('');
  const {editorData} = props;
  const {editorId} = useParams();
  const toast = useToast();


  const editorBasicDataSchema = z.object({
    name: z.string().min(1, 'Name is required'),
    email: z.string().min(1, 'Email is required').email('Invalid email format'),
    phone: z.string().min(1, 'Phone is required'),
    birthDate: z.date(),
  });

  type EditorBasicData = z.infer<typeof editorBasicDataSchema>;

  const defaultValues = {
    name: editorData.name,
    email: editorData.email,
    phone: editorData.phone,
    birthDate: editorData.birthDate,
  };

  const {
    handleSubmit,
    register,
    formState: {isDirty, errors},
    reset,
    getValues,
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues,
    resolver: zodResolver(editorBasicDataSchema),
  });

  const [
    {
      error: updateEditorError,
      loading,
    },
    updateEditor,
  ] = useUpdateEditor(editorId as string);

  useEffect(
    () => {
      if (updateEditorError) {
        toast({
          title: 'Sikertelen mentés',
          status: 'error',
          duration: 3000,
        });
      }
    },
    [updateEditorError],
  );

  const handleSaveClick = async (data: EditorBasicData) => {
    await updateEditor({data});
    setIsFormDirty(false);
    toast({
      title: 'Update was successfull',
      status: 'success',
      duration: 3000,
    });
  };

  useEffect(() => {
    if (isDirty) {
      setShouldReset(false);
      setIsFormDirty(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    if (shouldReset) reset(defaultValues);
  }, [shouldReset]);

  const watchBirthdate = watch('birthDate');

  return <Box mt='50px'>
    <form onSubmit={handleSubmit(handleSaveClick)}>
      <Flex
        justifyContent='space-around'
        alignItems={{base: 'center', md: 'start'}}
        direction={{base: 'column', md: 'row'}}
      >
        <Flex direction='column' w={{base: '80%', md: '400px'}}>
          <CustomField
            label='Name'
            id='name'
            register={register}
            getValues={getValues}
            setEditField={setEditField}
            editField={editField}
            errors={errors}
            shouldReset={shouldReset}
            defaultValue={defaultValues.name}
          />
          <CustomField
            label='Email'
            id='email'
            register={register}
            getValues={getValues}
            setEditField={setEditField}
            editField={editField}
            errors={errors}
            shouldReset={shouldReset}
            defaultValue={defaultValues.email}
          />
          <CustomField
            label='Phone'
            id='phone'
            register={register}
            getValues={getValues}
            setEditField={setEditField}
            editField={editField}
            errors={errors}
            shouldReset={shouldReset}
            defaultValue={defaultValues.phone}
          />
          <FormControl isInvalid mb='10px'>
            <FormLabel display='flex' justifyContent={'space-between'}>
              {'Date of birth'}
            </FormLabel>

            <Flex h='40px'>
              {
                editField === 'birthDate'
                && <Controller
                  name={'birthDate'}
                  control={control}
                  render={() => (
                    <Box
                      as={DateTimePicker}
                      clearIcon={null}
                      calendarIcon={null}
                      disableClock
                      showLeadingZeros
                      format="dd-MM-yyyy"
                      value={watchBirthdate}
                      minDate={moment('1951.01.01').toDate()}
                      maxDate={moment().toDate()}
                      onChange={(newValue: Date | null) => {
                        if (newValue) {
                          setValue('birthDate', moment(newValue).startOf('d').utc(true).toDate(), {shouldDirty: true});
                        }
                      }}
                      sx={{
                        ...datePickerStyles,
                        'height': '40px',
                        'width': '400px',
                        'border-radius': '15px',
                        'font-size': '16px',
                        'padding-left': '15px',
                        'border': '1px solid rgb(224, 229, 242)',
                        'margin-bottom': '15px',
                        '@media (max-width: 768px)': {
                          width: '100%',
                        },
                      }}
                    />
                  )}
                />
              }
              {
                editField !== 'birthDate'
                && <>
                  <Text {...CustomFieldStyle}>{moment(watchBirthdate).format('DD.MM.YYYY')}</Text>
                  <Button ml='5px' onClick={() => setEditField('birthDate')}>
                    <MdModeEdit size={20}/>
                  </Button>
                </>
              }
            </Flex>
          </FormControl>

          <Flex justifyContent={'space-between'}>
            <FormLabel>Newsletter</FormLabel>
            {editorData.newsletter ? <MdOutlineDone/> : <IoCloseSharp/>}
          </Flex>
          <Flex justifyContent={'space-between'}>
            <FormLabel>Privacy Notice</FormLabel>
            {editorData.pn ? <MdOutlineDone/> : <IoCloseSharp/>}
          </Flex>
          <Flex justifyContent={'space-between'}>
            <FormLabel>Terms & Conditions</FormLabel>
            {editorData.tandc ? <MdOutlineDone/> : <IoCloseSharp/>}
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent='center' m='50px'>
        <Button isLoading={loading} type='submit' variant='photonDark' w='200px'>
          Save
        </Button>
      </Flex>
    </form>
  </Box>;
}
