import {useCallback, useEffect, useRef} from 'react';
import {
  useBeforeUnload,
  useLocation,
  Outlet,
  unstable_usePrompt as usePrompt,
} from 'react-router-dom';
import {useFormContext} from 'contexts/useFormContext';


const confirmMessage = 'You have unsaved changes! Are you sure you want to leave?';

export const FormLayout = () => {
  const {isFormDirty, setIsFormDirty} = useFormContext();
  const location = useLocation();
  const prevPathRef = useRef(location.pathname);

  const handleBeforeUnload = useCallback(
    // eslint-disable-next-line
    (e?: any) => {
      if (isFormDirty) {
        const userConfirmed = window.confirm(confirmMessage);
        if (!userConfirmed) {
          e.preventDefault?.();
          e.returnValue = '';
          return;
        }

        setIsFormDirty(false);
      }
    },
    [isFormDirty],
  );

  useBeforeUnload(handleBeforeUnload);

  usePrompt({
    when: isFormDirty && prevPathRef.current === location.pathname,
    message: confirmMessage,
  });

  useEffect(() => {
    prevPathRef.current = location.pathname;
    setIsFormDirty(false);
  }, [location]);

  return <Outlet />;
};
