import {Box, Flex, Link, Tag} from '@chakra-ui/react';
import SimpleTable from 'components/SimpleTable';
import {NavLink} from 'react-router-dom';
import {Cell} from 'react-table';
import {Column, Contact} from '../types';


export default function BusyCreativesTable({data, label}: { data: Contact[]; label: string }) {
  const columnsData : Record<string, Column> = {
    name: {
      columnDef: {
        Header: 'Photographer',
        accessor: 'name',
      },
      renderer: (cell: Cell<any>) => (
        <Link as={NavLink} to={`/intel/creative/${cell.row.original._id}`} fontWeight='bold' color='black'>
          {cell.value}
        </Link>
      ),
    },
    homeTown: {
      columnDef: {
        Header: 'Home town',
        accessor: 'homeTown',
      },
    },
    workingArea: {
      columnDef: {
        Header: 'Cities',
        accessor: 'workingArea',
      },
      renderer: (cell: Cell) => (
        <Flex align='center' gap={1} wrap='wrap'>
          {cell.value.map((t: {name: string}) => (
            <Tag size='sm' fontSize='sm' fontWeight='700' key={t.name} bg='#a6a3ff'>
              {t.name}
            </Tag>
          ))}
        </Flex>
      ),
    },
    projects: {
      columnDef: {
        Header: 'Conflicted projects',
        accessor: 'projects',
      },
      renderer: (cell: Cell) => (
        <Flex align='center' gap={1} wrap='wrap'>
          {cell.value.map((p: {_id: string, projectName: string}) => (
            <Link as={NavLink} to={`/intel/project/${p._id}`} textDecor='underline' color='#a6a3ff' mr='10px'>
              {p.projectName}
            </Link>
          ))}
        </Flex>
      ),
    },
  };

  return (
    <Box mb='30px'>
      <SimpleTable
        variant='underlineRows'
        label={label}
        columnsData={Object.values(columnsData).map((column: any) => column.columnDef)}
        renderersData={columnsData}
        tableData={data}
        total={data?.length}
      />
    </Box>
  );
}
