import React, {useEffect} from 'react';
import {
  Box,
  useToast,
} from '@chakra-ui/react';

import {useCreateCountrySettings} from '../../../api/presence-countries';
import CountrySettingsForm from './CountrySettingsForm';


export default function NewCountrySettings() {
  const toast = useToast();

  const [{loading, error}, createCountrySettings] = useCreateCountrySettings();

  useEffect(
    () => {
      if (error) {
        toast({
          title: 'Error creating country settings.',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [error],
  );

  return <Box
    pt={{base: '50px', md: '80px', xl: '130px'}}
    display='flex'
    justifyContent="center"
  >
    <CountrySettingsForm loading={loading} submit={createCountrySettings}/>
  </Box>;
};
