import axios, {AxiosError} from 'axios';

import config from '../../config';
import {getItem} from '../../hooks/useLocalStorage';


export const getAuthorizationToken = (): string|undefined => {
  const userCode = getItem('user');
  const user = userCode && JSON.parse(userCode);

  return user?.token;
};

export default function ServerClient(url?: string) {
  const customAxios = axios.create(
    {baseURL: url || config.apiUrl},
  );
  customAxios.interceptors.request.use(
    async (config) => {
      const token = getAuthorizationToken();
      if (token) {
        config.headers.authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  customAxios.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      if (error?.response?.status === 401) {
        window.location.assign(`${window.location.origin}/login`);
      }

      throw error;
    },
  );

  return customAxios;
};
