import React from 'react';

import MenuLayout from '../../../layouts/MenuLayout';
import RoleGuard from '../../../layouts/RoleGuard';

import {adminRoles} from '../../../variables/roles';
import Ratings from './Ratings';


export default function CreativeRatingsPage() {
  return <RoleGuard
    page
    roles={
      [
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]
    }
  >
    <MenuLayout page={<Ratings />} />
  </RoleGuard>;
}
