import {
  Box,
  FormControl,
  FormLabel,
  Flex,
  Text,
} from '@chakra-ui/react';
import {MdOutlineDone} from 'react-icons/md';
import {IoCloseSharp} from 'react-icons/io5';
import {CustomFieldStyle} from 'views/projects/variables/styles';
import {UserRoleLabels} from '../constants';


export default function ClientUserBasicData(props: { clientUserData: any }) {
  const {clientUserData} = props;

  return <Box mt='50px'>
    <Flex
      justifyContent='space-around'
      alignItems={{base: 'center', md: 'start'}}
      direction={{base: 'column', md: 'row'}}
    >
      <Flex direction='column'>
        <FormControl id='name' mb={4}>
          <FormLabel>Name</FormLabel>
          <Flex {...CustomFieldStyle}>
            <Text>{clientUserData.name}</Text>
          </Flex>
        </FormControl>

        <FormControl id='email' mb={4}>
          <FormLabel>Email</FormLabel>
          <Flex {...CustomFieldStyle}>
            <Text>{clientUserData.email}</Text>
          </Flex>
        </FormControl>

        <FormControl id='phone' mb={4}>
          <FormLabel>Phone</FormLabel>
          <Flex {...CustomFieldStyle}>
            <Text>{clientUserData.phone}</Text>
          </Flex>
        </FormControl>

        {
          clientUserData.plus?.brand
                && <FormControl alignItems='center' mb={4}>
                  <FormLabel>Role</FormLabel>
                  <Flex {...CustomFieldStyle}>
                    <Text>{UserRoleLabels[clientUserData.plus?.brand?.role]}</Text>
                  </Flex>
                </FormControl>
        }
        {
          clientUserData.plus?.brands && clientUserData.plus.brands.length > 0 && (
            <>
              <FormLabel>Roles</FormLabel>
              {
                clientUserData.plus?.brands.map((b: { name: string, role: string }) => (
                  <FormControl alignItems='center' mb={4} key={b.name}>
                    <Flex {...CustomFieldStyle} mb='0px'>
                      <Flex>
                        <Text>{`${b.name} - ${UserRoleLabels[b.role]}`}</Text>
                      </Flex>
                    </Flex>
                  </FormControl>
                ))
              }
            </>
          )
        }
      </Flex>
      <Flex direction='column'>
        <FormControl alignItems='center' mb={4}>
          <FormLabel>Language preference</FormLabel>
          <Flex {...CustomFieldStyle}>
            <Text>{clientUserData.preferences?.lang === 'hu' ? 'Hungarian' : 'English'}</Text>
          </Flex>
        </FormControl>
        <Flex justifyContent={'space-between'}>
          <FormLabel>Newsletter</FormLabel>
          {clientUserData.newsletter ? <MdOutlineDone/> : <IoCloseSharp />}
        </Flex>
        <Flex justifyContent={'space-between'}>
          <FormLabel>Privacy Notice</FormLabel>
          {clientUserData.pn ? <MdOutlineDone/> : <IoCloseSharp />}
        </Flex>
        <Flex justifyContent={'space-between'}>
          <FormLabel>Terms & Conditions</FormLabel>
          {clientUserData.tandc ? <MdOutlineDone/> : <IoCloseSharp />}
        </Flex>
      </Flex>
    </Flex>
  </Box>;
}

