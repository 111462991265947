import React, {useEffect, useState} from 'react';

import {useLocation, useSearchParams} from 'react-router-dom';

import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';

import OpenRatings from './OpenRatings';
import ClosedRatings from './ClosedRatings';
import {ProjectRatingFilters} from 'views/dashboard/types';


export default function Ratings() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    creative = '',
    editor = '',
    rating = '',
    search = '',
    from,
    to,
  } = Object.fromEntries(queryParams.entries());

  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
  const [perPage, setPerPage] = useState(parseInt(searchParams.get('perPage')) || 10);
  const [filters, setFilters] = useState<ProjectRatingFilters>({
    from: from ? new Date(from) : new Date('2023.01.01'),
    to: to ? new Date(to) : new Date(),
    creative,
    editor,
    rating,
    search,
  });
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    setSelectedTab(parseInt(searchParams.get('tab')) || 0);
  }, [searchParams]);

  return <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
    <Text fontSize='25px' fontWeight='bold' m='20px'>
      Creative ratings
    </Text>
    <Tabs index={selectedTab} onChange={handleTabChange}>
      <TabList ml='15px'>
        <Tab>Open</Tab>
        <Tab>Closed</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <OpenRatings
            selectedTab={selectedTab}
            filters={filters}
            setFilters={setFilters}
            perPage={perPage}
            setPerPage={setPerPage}
            setPage={setPage}
            page={page}
          />
        </TabPanel>
        <TabPanel>
          <ClosedRatings
            selectedTab={selectedTab}
            filters={filters}
            setFilters={setFilters}
            perPage={perPage}
            setPerPage={setPerPage}
            setPage={setPage}
            page={page}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  </Box>;
}
