import useAxios from './basic/use-axios';


export const useGetUser = () => useAxios({url: '/admin/user/profile'});

export const useGetClientUsers = () =>
  useAxios(
    {
      url: '/admin/client-user/list',
      method: 'GET',
    },
  );

export const useGetClientUser = (userId: string) =>
  useAxios(
    {
      url: `/admin/client-user/${userId}`,
      method: 'GET',
    },
  );

export const useUpdateClientUser = (userId: string) =>
  useAxios(
    {
      url: `/admin/client-user/${userId}`,
      method: 'PUT',
    },
    {manual: true},
  );

export const useGetInvitedUsers = () =>
  useAxios(
    {
      url: '/admin/client-user/invited/list',
      method: 'GET',
    },
  );

export const useGetDeletedUsers = () =>
  useAxios(
    {
      url: '/admin/client-user/deleted/list',
      method: 'GET',
    },
  );

