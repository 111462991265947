import {Select} from '@chakra-ui/react';

function generateYears() {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear; year >= 2022; year--) {
    years.push(year.toString());
  }
  return years;
}

export default function YearSelector(props: { year: string; setYear: Function }) {
  const {year, setYear} = props;
  const years = generateYears();
  return (
    <Select
      placeholder='Year'
      onChange={(e) => setYear(e.target.value)}
      value={year}
      w='150px'
      m='5px'
      bg='white'
    >
      {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </Select>
  );
}
