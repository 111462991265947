import React from 'react';

import MenuLayout from '../../../layouts/MenuLayout';
import RoleGuard from '../../../layouts/RoleGuard';

import {adminRoles} from '../../../variables/roles';
import TransfersList from './TransfersList';


export default function TransfersListPage() {
  return <RoleGuard
    page
    roles={
      [
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]
    }
  >
    <MenuLayout page={<TransfersList />} />
  </RoleGuard>;
}
