import {useMemo, useEffect} from 'react';

import {
  Button,
  Flex,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import moment from 'moment';
import React from 'react';
import Card from '../../../components/card/Card';
import {NavLink} from 'react-router-dom';


export type Row = {
  // eslint-disable-next-line
  original: any;
};

export default function CreativeSubTable(props: {
  // eslint-disable-next-line
  columnsData: any;
  // eslint-disable-next-line
  tableData: any;
  total: number;
  // eslint-disable-next-line
  creativeData: any;
}) {
  const {columnsData, tableData, total, creativeData} = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: pageData,
    prepareRow,
    setPageSize,
  } = tableInstance;

  useEffect(() => {
    setPageSize(total);
  }, [total]);

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  return (
    <Card flexDirection='column' overflowX='scroll' height='450px' margin='10px'>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {pageData.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  const currentRow: Row = cell.row;
                  let data;
                  if (
                    cell.column.id === 'dateOfRating'
                    || cell.column.id === 'shootingTime'
                    || cell.column.id === 'createdAt'
                  ) {
                    data = (
                      <Flex align='center'>
                        <Text me='10px' color={textColor} fontWeight='700'>
                          {moment(cell.value).format('YYYY.MM.DD.  HH:mm')}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.id === 'action') {
                    data = (
                      <Flex align='center'>
                        <Button w='80px' variant='photonLight' m='0px'>
                          <Link
                            as={NavLink}
                            textDecoration='none'
                            to={`/intel/project/${currentRow.original._id}`}
                            target='_blank'
                          >
                            View
                          </Link>
                        </Button>
                      </Flex>
                    );
                  } else if (cell.column.id === 'photographerReview.message') {
                    data = (
                      <Flex align='center'>
                        <Text me='10px' color={textColor} fontWeight='700'>
                          {cell.value ?? '-'}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.id === 'viewRating') {
                    data = (
                      <Flex align='center'>
                        <Button w='80px' variant='photonLight' m='0px'>
                          <Link
                            textDecoration='none'
                            as={NavLink}
                            to={`/intel/creatives/ratings?creative=${encodeURIComponent(
                              creativeData.name,
                            )}&search=${encodeURIComponent(currentRow.original._id)}&tab=${
                              currentRow.original.adminRatingReview ? 1 : 0
                            }`}
                          >
                            View
                          </Link>
                        </Button>
                      </Flex>
                    );
                  } else {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{sm: '14px'}}
                      minW={{sm: '150px', md: '200px', lg: 'auto'}}
                      borderColor='transparent'
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
