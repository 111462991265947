import React, {useCallback, useEffect, useState} from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import {Select} from 'chakra-react-select';
import {useGetClientCountries, useGetClientNames} from 'api/clients';
import {useAddCreativeToPackage, useGetClientPackages} from 'api/packages';
import {chakraStyles} from 'views/projects/variables/styles';
import useCountryList from 'hooks/useCountryList';
import {sortCountries} from 'utils/sort-countries';

export default function AddCreativeToPackageModal({
  creativeData,
  isOpen,
  onClose,
}: {
  // eslint-disable-next-line
  creativeData: any;
  isOpen: boolean;
  // eslint-disable-next-line
  onClose: Function;
}) {
  const defaultCountry = {value: '', label: 'Select country'};
  const defaultPackage = {value: '', label: 'Select package'};
  const defaultClient = {value: '', label: 'Select client'};
  const toast = useToast();
  const [country, setCountry] = useState(defaultCountry);
  const [client, setClient] = useState(defaultClient);
  const [pack, setPackage] = useState(defaultPackage);
  const [{data: clientNames}, getClientNames] = useGetClientNames();
  const [{data: clientCountries}] = useGetClientCountries();
  const [{data: packages}, getPackages] = useGetClientPackages();
  const [{error: addCreativeToPackageError}, addCreativeToPackage] = useAddCreativeToPackage();
  const {getCountryName} = useCountryList();
  const sortedCountries = sortCountries(clientCountries);


  const callGetClientNames = useCallback(async () => {
    try {
      await getClientNames({
        params: {
          country: country.value,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [getClientNames, client, country]);

  const callGetPackages = useCallback(async () => {
    try {
      await getPackages({
        params: {
          clientName: client.value,
          creativeId: creativeData._id,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [getPackages, client]);

  useEffect(() => {
    callGetClientNames();
  }, [callGetClientNames]);

  useEffect(() => {
    callGetPackages();
    setPackage(defaultPackage);
  }, [callGetPackages, client]);

  const handleAddCreative = () => {
    try {
      addCreativeToPackage({
        data: {creativeId: creativeData._id, packageId: pack.value},
      });
      toast({
        title: 'Photographer successfully added to package',
        status: 'success',
        duration: 3000,
      });
    } catch {
      // handled
    }
    onClose(true);
    setClient(defaultClient);
    setPackage(defaultPackage);
  };

  useEffect(() => {
    if (addCreativeToPackageError) {
      toast({
        title: 'Adding photographer to package was unsuccessfull!',
        status: 'error',
        duration: 3000,
      });
    }
  }, [addCreativeToPackageError]);

  return (
    <Modal size={{base: 'sm', md: '5xl'}} isOpen={isOpen} onClose={() => onClose()}>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Assign to package</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Flex direction={{base: 'column', md: 'row'}} justifyContent='space-around'>
            <Flex direction='column'>
              <Text>Country:</Text>
              <Select
                options={sortedCountries?.map((x: string) => {
                  return {value: x, label: getCountryName(x)};
                })}
                onChange={
                  // eslint-disable-next-line
                  (option: any) => {
                    setCountry(option);
                  }
                }
                closeMenuOnSelect
                value={{value: country.value, label: country.label}}
                chakraStyles={chakraStyles}
              />
            </Flex>

            <Flex direction='column'>
              <Text>Client:</Text>
              <Select
                options={clientNames?.map((x: string) => {
                  return {value: x, label: x};
                })}
                onChange={
                  // eslint-disable-next-line
                  (option: any) => {
                    setClient(option);
                  }
                }
                closeMenuOnSelect
                value={{value: client.value, label: client.label}}
                chakraStyles={chakraStyles}
                isDisabled={!country.value}
              />
            </Flex>

            <Flex direction='column'>
              <Text>Package:</Text>
              <Select
                options={packages?.map((x: { _id: string; name: string; status: string }) => ({
                  value: x._id,
                  label: `${x.name} ${x.status === 'disabled' ? '(D)' : ''}`,
                }))}
                onChange={
                  // eslint-disable-next-line
                  (option: any) => setPackage(option)
                }
                closeMenuOnSelect
                value={{value: pack.value, label: pack.label}}
                chakraStyles={chakraStyles}
                isDisabled={!client.value}
              />
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='red'
            variant='photonLight'
            mr={3}
            onClick={() => handleAddCreative()}
            isDisabled={!pack.value}
          >
            Add
          </Button>
          <Button variant='photonMedium' w='100px' onClick={() => onClose()}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
