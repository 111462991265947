import React from 'react';
import {adminRoles} from '../../variables/roles';
import RoleGuard from '../../layouts/RoleGuard';
import MenuLayout from '../../layouts/MenuLayout';
import ClientUser from './ClientUser';


export default function ClientUserPage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <MenuLayout page={<ClientUser />} />
    </RoleGuard>
  );
}
