import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Flex,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react';
import {useParams} from 'react-router-dom';
import {useActivateClient, useGetClient} from 'api/clients';
import useCountryList from 'hooks/useCountryList';
import ClientBillingData from './components/ClientBillingData';
import ClientBasicData from './components/ClientBasicData';
import ClientManage from './components/ClientManage';
import {ClientStatus, clientStatusLabel, projectCategoryLabels} from './constants';
import LoadingModal from '../../components/LoadingModal';

export default function Client() {
  const {clientId} = useParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const {getCountryName} = useCountryList();
  const toast = useToast();

  const [
    {data: clientData, loading: getClientLoading, error: getClientError},
    getClientData,
  ] = useGetClient(clientId);
  const [
    {error: activateClientError, loading: activateLoading},
    activateClient,
  ] = useActivateClient(clientId);

  const handleTabChange = (index: number) => {
    setSelectedTabIndex(index);
  };

  const handleActivateClick = async () => {
    try {
      await activateClient();
      toast({
        title: 'Company activated',
        status: 'success',
        duration: 3000,
      });
      getClientData();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(
    () => {
      if (activateClientError) {
        toast(
          {
            title: 'Update was unsuccessful!',
            status: 'error',
            duration: 3000,
          },
        );
      }
      if (getClientError) {
        toast(
          {
            title: 'Cannot get client information!',
            status: 'error',
            duration: 3000,
          },
        );
      }
    },
    [activateClientError, toast, getClientError],
  );

  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      {
        getClientLoading
        && <LoadingModal />
      }
      {
        clientData
        && !getClientLoading
        && <>
          <Flex justifyContent='space-between' alignItems='center'>
            <Flex
              bg='white'
              alignItems='center'
              w={{base: '90vw', md: '500px'}}
              borderRadius='20px'
              m='10px'
            >
              <Box m='10px 20px' w='80%'>
                <Text fontSize='25px' fontWeight='bold'>
                  {clientData.name}
                </Text>
                <Text>{clientData.email}</Text>
                <Text>{clientData.phone}</Text>
                <Text>{getCountryName(clientData.country)}</Text>
                <Text>industry: {projectCategoryLabels[clientData.industryCategory]}</Text>
              </Box>
              <Text mt='60px' mr='20px'>
                {clientStatusLabel[clientData.adminVerified ? ClientStatus.ACTIVE : ClientStatus.INACTIVE]}
              </Text>
            </Flex>
            {
              clientData.adminVerified
                ? <Link href={clientData.storage?.url} _hover={{textDecoration: 'none'}} target='_blank'>
                  <Button as="a" variant='photonDark'>Client drive</Button>
                </Link>
                : <Button
                  isLoading={activateLoading}
                  onClick={() => handleActivateClick()}
                  variant='photonLight'
                  w='200px'
                >
                  Activate
                </Button>
            }
          </Flex>
          <Tabs index={selectedTabIndex} onChange={handleTabChange}>
            <TabList ml='0px' mr='20px'>
              <Tab>Basic data</Tab>
              <Tab>Billing</Tab>
              <Tab>Manage</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ClientBasicData clientData={clientData}/>
              </TabPanel>

              <TabPanel>
                <ClientBillingData clientData={clientData} />
              </TabPanel>

              <TabPanel>
                <ClientManage clientData={clientData} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      }
    </Box>
  );
}
