import {
  Button,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  useGetAllCreativeInvoiceZip,
  useGetCreativeInvoices,
  useDownloadCreativeInvoice,
} from 'api/staff-api';
import moment from 'moment';
import {useCallback, useEffect, useState} from 'react';
import BillingPeriodSelector from 'views/dashboard/components/BillingPeriodSelector';
import YearSelector from 'views/dashboard/components/YearSelector';
import {DownloadIcon} from '@chakra-ui/icons';
import {currencyFormatter} from 'utils/number-formatter';
import {downloadFile} from 'utils/download-file';
import Card from 'components/card/Card';
import {useSearchParams} from 'react-router-dom';
import useCountryList from 'hooks/useCountryList';
import {Spinner} from '@chakra-ui/react';
import Bugsnag from '@bugsnag/js';


export default function Invoices() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [year, setYear] = useState(searchParams.get('year') || moment().format('YYYY'));
  const [period, setPeriod] = useState(searchParams.get('period') || '');
  const [currentSid, setCurrentSid] = useState(null);
  const {getCountryName} = useCountryList();


  const [{loading}, getAllCreativeInvoiceZip] = useGetAllCreativeInvoiceZip();
  const [{data: creativeInvoiceData}, getCreativeInvoices] = useGetCreativeInvoices();
  const [{loading: isDownloading}, downloadCreativeInvoice] = useDownloadCreativeInvoice();

  const callGetClientInvoices = useCallback(async (year: string, period: string) => {
    if (year && period) {
      try {
        await getCreativeInvoices({
          params: {
            year,
            period,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const callGetAllClientInvoiceZip = useCallback(async (year: string, period: string) => {
    let result;
    if (year && period) {
      try {
        result = await getAllCreativeInvoiceZip({
          params: {
            year,
            period,
          },
          responseType: 'arraybuffer',
        });
        if (result && result.data) {
          downloadFile(result.data, `creative_invoices${year}_${period}.zip`, 'application/zip');
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const handleDownloadInvoice = async (driveSid: string, creative: string) => {
    try {
      setCurrentSid(driveSid);
      const result = await downloadCreativeInvoice({
        params: {driveSid},
        responseType: 'arraybuffer',
      });

      if (result && result.data) {
        const fileName = `${creative}_${year}_${period}_invoice.pdf`.replaceAll(' ', '_').toLowerCase();
        downloadFile(result.data, fileName, 'application/pdf');
      }
    } catch (e) {
      Bugsnag.notify(e as Error);
    } finally {
      setCurrentSid(null);
    }
  };

  useEffect(() => {
    callGetClientInvoices(year, period);
  }, [year, period, setSearchParams, searchParams]);


  const isButtonDisabled
    = !year || !period || !creativeInvoiceData || creativeInvoiceData?.length === 0;

  const DownloadIconButton = ({
    driveSid,
    creative,
  }: {
      driveSid: string;
      creative: string;
    }) => {
    return isDownloading && currentSid === driveSid ? (
      <Spinner size="sm" m="12px" />
    ) : (
      <IconButton
        aria-label="Copy name"
        isDisabled={isDownloading}
        icon={<DownloadIcon />}
        onClick={() => handleDownloadInvoice(driveSid, creative)}
        bg="transparent"
        _hover={{
          bg: 'transparent',
          transform: 'scale(1.2)',
        }}
        color="current"
        transition="transform 0.2s, color 0.2s"
      />
    );
  };

  return (
    <Card>
      <Text fontSize='18px' fontWeight='bold' m='10px'>
        Invoices
      </Text>
      <Flex justifyContent='space-between'>
        <Flex>
          <YearSelector year={year} setYear={setYear} />
          <BillingPeriodSelector year={year} billingPeriod={period} setBillingPeriod={setPeriod} />
        </Flex>
        <Button
          variant='photonLight'
          onClick={() => callGetAllClientInvoiceZip(year, period)}
          mt='5px'
          isDisabled={isButtonDisabled}
          isLoading={loading}
        >
          Download all
        </Button>
      </Flex>
      <Flex justifyContent='center'>
        {creativeInvoiceData?.length > 0
          && <TableContainer w='100%' mt='50px'>
            <Table variant='simple'>
              <Thead>
                <Tr borderBottom={`solid #E2E8F0`}>
                  <Th>Creative</Th>
                  <Th textAlign='center'>Country</Th>
                  <Th textAlign='center'>Billing period</Th>
                  <Th textAlign='center'>Invoice number</Th>
                  <Th textAlign='center'>HUF</Th>
                  <Th textAlign='center'>EUR</Th>
                </Tr>
              </Thead>
              <Tbody>
                {creativeInvoiceData.map((row: any, i: string) => (
                  <Tr key={i}>
                    <Td>{row.creative}</Td>
                    <Td textAlign='center'>{getCountryName(row.country)}</Td>
                    <Td textAlign='center'>{row.date}</Td>
                    <Td textAlign='center'>
                      {row.certs.HUF?.billNo || row.certs.EUR?.billNo}
                    </Td>
                    <Td>
                      {row?.certs?.HUF && (
                        <Flex justifyContent='center' alignItems='center'>
                          <Text mr='10px'>
                            {row.certs.HUF.netSum
                              && `${currencyFormatter(row.certs.HUF.netSum, 'HUF')} HUF`}
                          </Text>
                          <DownloadIconButton
                            driveSid={row.certs.HUF.driveSid}
                            creative={row.creative}
                          />
                        </Flex>
                      )}
                    </Td>
                    <Td>
                      {row?.certs?.EUR && (
                        <Flex justifyContent='center' alignItems='center'>
                          <Text mr='10px'>
                            {row.certs.EUR.netSum
                              && `${currencyFormatter(row.certs.EUR.netSum, 'EUR')} EUR`}
                          </Text>
                          <DownloadIconButton
                            driveSid={row.certs.EUR.driveSid}
                            creative={row.creative}
                          />
                        </Flex>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        }
        {creativeInvoiceData?.length === 0
          && <Text textAlign={'center'} mt='50px'>
            There is no document available for this period.
          </Text>
        }
      </Flex>
    </Card>
  );
}
