import {
  Button,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Flex,
  Select,
  Text,
  Input,
  Textarea,
} from '@chakra-ui/react';
import {useState} from 'react';
import {useCancelProject, useCancelProjectByCreative} from '../../../api/projects';
import {ImCancelCircle} from 'react-icons/im';
import {
  clientCancellationMainCategories,
  ClientCancellationReasonCategory,
  clientCancellationReasonLabels,
  ProjectState,
} from '../constants';


const CancelProjectButton = ({
  project,
  refetchTableData,
  withButton,
}: {
  // eslint-disable-next-line
  project: any;
  // eslint-disable-next-line
  refetchTableData: Function;
  withButton?: boolean;
}) => {
  const {isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1} = useDisclosure();
  const {isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2} = useDisclosure();
  const toast = useToast();
  const [reason, setReason] = useState<string>('');
  const [reasonCategory, setReasonCategory] = useState<string>('');
  const [photoAccessUrl, setPhotoAccessUrl] = useState<string | undefined>();
  const [cancelBy, setCancelBy] = useState<string>('');

  const [{error: cancelProjectError, loading: cancelProjectLoading}, cancelProject]
    = useCancelProject(project._id);
  const [
    {error: cancelProjectByCreativeError, loading: cancelByCreativeLoading},
    cancelProjectByCreative,
  ] = useCancelProjectByCreative(project._id);

  const handleCancelProject = async () => {
    if (cancelBy === 'client') {
      await cancelProject({
        data: {
          reason,
          photoAccessUrl,
        },
      });
    } else {
      await cancelProjectByCreative({
        data: {
          reason,
        },
      });
    }

    if (cancelProjectError || cancelProjectByCreativeError) {
      toast({
        title: 'Error during project cancellation',
        status: 'error',
        duration: 3000,
      });
    } else {
      toast({
        title: 'Project cancelled succesfully',
        status: 'success',
        duration: 3000,
      });
    }
    refetchTableData();

    onClose2();
    onClose1();
  };

  const handleSelectCancellation = (type: string) => {
    setCancelBy(type);
    onOpen2();
  };


  return (
    <Box>
      {
        ![
          ProjectState.DELIVERED,
          ProjectState.FINISHED,
          ProjectState.DELIVERED,
          ProjectState.CANCELED,
        ].includes(project.status) && (
          <Flex
            w={withButton ? '200px' : '50px'}
            h='50px'
            justifyContent='center'
            alignItems='center'
            color='#a6a3ff'
            _hover={{color: '#6b52ff'}}
          >
            {withButton ? (
              <Button variant='photonDark' w='200px' cursor='pointer' onClick={onOpen1}>
                Cancel
              </Button>
            ) : (
              <ImCancelCircle
                size='25px'
                cursor='pointer'
                onClick={onOpen1}
                className='cancel-icon'
              />
            )}
          </Flex>
        )}

      <Modal isOpen={isOpen1} onClose={onClose1} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader> Choose on whose behalf the project will be canceled</ModalHeader>
          <ModalBody display='flex' justifyContent='space-around' mb='20px'>
            <Button
              w='200px'
              variant='photonMedium'
              mr={3}
              onClick={() => handleSelectCancellation('creative')}
            >
              Cancel by creative
            </Button>
            <Button
              w='200px'
              variant='photonMedium'
              mr={3}
              onClick={() => handleSelectCancellation('client')}
            >
              Cancel by client
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen2} onClose={onClose2} size={{base: 'xs', md: 'xl'}}>
        <ModalOverlay />
        <ModalContent minWidth={{base: '300px', md: '800px'}}>
          <ModalHeader> Provide the reason of the cancellation!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {cancelBy === 'creative'
              ? <Textarea onChange={(e) => setReason(e.target.value)}></Textarea>
              :<Flex
                mb={4}
                w='100%'
                justifyContent={{base: 'center', md: 'space-around'}}
                alignItems={{base: 'center', md: 'start'}}
                direction={{base: 'column', md: 'row'}}
              >
                <Flex direction='column' mb='10px'>
                  <Text>Cancellation category:</Text>
                  <Select
                    onChange={(e) => {
                      setReasonCategory(e.target.value);
                      setReason('');
                    }
                    }
                    placeholder='Select reason category'
                    value={reasonCategory}
                    w='300px'
                  >
                    {
                      Object.keys(clientCancellationMainCategories).map((reason: string) => (
                        <option key={reason} value={reason}>
                          {clientCancellationReasonLabels[reason]}
                        </option>
                      ))
                    }
                  </Select>
                </Flex>
                {
                  reasonCategory !== ClientCancellationReasonCategory.OTHER
                && reasonCategory !== ClientCancellationReasonCategory.PHOTON
                && <Flex direction='column'>
                  <Text>Reason for the cancellation:</Text>
                  <Select
                    onChange={(e) => setReason(e.target.value)}
                    placeholder='Select reason'
                    value={reason}
                    w='300px'
                    disabled={!reasonCategory}
                  >
                    {
                      clientCancellationMainCategories[reasonCategory]?.map((reason: string) => (
                        <option key={reason} value={reason}>
                          {clientCancellationReasonLabels[reason]}
                        </option>
                      ))
                    }
                  </Select>
                  {
                    reason === 'alreadyHavePhotos'
                    && <Input
                      w='300px'
                      mt='10px'
                      justifySelf='center'
                      placeholder='Enter the URL where the photos are accessible!'
                      onChange={(e)=>setPhotoAccessUrl(e.target.value)}>
                    </Input>
                  }
                </Flex>
                }
                {
                  (
                    reasonCategory === ClientCancellationReasonCategory.OTHER
                    || reasonCategory === ClientCancellationReasonCategory.PHOTON
                  ) && <Input
                    w='300px'
                    mt='20px'
                    justifySelf='center'
                    placeholder='Specify the reason for the cancellation!'
                    onChange={(e)=>setReason(e.target.value)}>
                  </Input>
                }
              </Flex>
            }
          </ModalBody>
          <ModalFooter>
            <Button variant='photonMedium' mr={3} onClick={onClose2}>
              Back
            </Button>
            <Button
              variant='photonDark'
              mr={3}
              onClick={handleCancelProject}
              isDisabled={!reason}
              isLoading={cancelByCreativeLoading || cancelProjectLoading}
            >
              Finish cancellation
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default CancelProjectButton;
