import {Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text} from '@chakra-ui/react';

import {useState} from 'react';
import CostReports from './components/CostReports';
import Invoices from './components/Invoices';

export default function ClientFinances() {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      <Text fontSize='25px' fontWeight='bold' m='20px'>
        Client finances
      </Text>
      <Tabs index={selectedTab} onChange={handleTabChange}>
        <TabList ml='15px'>
          <Tab>Cost reports</Tab>
          <Tab>Invoices</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CostReports />
          </TabPanel>
          <TabPanel>
            <Invoices />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
