import useAxios from './basic/use-axios';


export const useGetUnmatchedCities = () => useAxios(
  {
    url: `/admin/unmatchedCities`,
    method: 'GET',
  },
);

export const useMatchCity = () => useAxios(
  {
    url: `/admin/matchCity`,
    method: 'POST',
  },
  {manual: true},
);

export const useDeleteMatchCityRecord = (recordId: string) => useAxios(
  {
    url: `/admin/unmatchedCities/${recordId}`,
    method: 'DELETE',
  },
  {manual: true},
);
