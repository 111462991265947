import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';

import {MdOutlineRemoveRedEye} from 'react-icons/md';
import {RiEyeCloseLine} from 'react-icons/ri';
import {useToast} from '@chakra-ui/react';

import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import DefaultAuth from 'layouts/auth/Default';

import illustration from 'assets/img/auth/auth.png';
import {useAuth} from 'hooks/useAuth';
import Input from '../../components/form/Input';
import {login as callLogin} from '../../api/auth-api';
import {useLocalStorage} from '../../hooks/useLocalStorage';


const schema = z.object({
  email: z.string().email('A valid email address is required'),
  password: z.string().min(1, {message: 'Password required'}),
});

type FormValues = z.infer<typeof schema>;

const defaultValues: FormValues = {
  email: '',
  password: '',
};

function SignIn() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(schema),
  });
  const toast = useToast();
  const navigate = useNavigate();

  const textColor = useColorModeValue('navy.700', 'white');
  const buttonVariant = useColorModeValue('photonDark', 'photonLight');
  const textColorSecondary = 'gray.400';

  const {login, user} = useAuth();

  const [show, setShow] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [previousPath, , resetPreviousPath] = useLocalStorage('previousPath', '/intel/projects');

  const handleClick = () => setShow(!show);

  const onSubmit = async (data: FormValues) => {
    setSubmitting(true);

    try {
      const adminUser = await callLogin(data.email, data.password);
      login(adminUser);

      if (
        previousPath
        && ['no-permission', 'login'].every(
          (urlPart) => !previousPath.includes(urlPart),
        )
      ) {
        navigate(previousPath, {replace: true});
      } else {
        navigate('/intel/projects', {replace: true});
      }
      resetPreviousPath();
    // eslint-disable-next-line
    } catch (e: any) {
      toast.closeAll();

      const message = e?.response?.data?.error_message
        || 'You entered an incorrect username or password.';

      toast({
        title: message,
        description: 'Please try again!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setSubmitting(false);
  };

  React.useEffect(() => {
    if (user) {
      if (['adminPhoton', 'superAdminPhoton'].includes(user.role)) {
        navigate('/intel/dashboard', {replace: true});
        return;
      } else {
        navigate('/intel/creatives/list', {replace: true});
      }
    }
  }, [user, navigate]);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{base: '100%', md: 'max-content'}}
        w='100%'
        mx={{base: 'auto', lg: '0px'}}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{base: '30px', md: '60px'}}
        px={{base: '25px', md: '0px'}}
        mt={{base: '40px', md: '14vh'}}
        flexDirection='column'
      >
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text mb='36px' ms='4px' color={textColorSecondary} fontWeight='400' fontSize='md'>
            Enter your email and password to login!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{base: '100%', md: '420px'}}
          maxW='100%'
          backgroundColor='transparent'
          borderRadius='15px'
          mx={{base: 'auto', lg: 'unset'}}
          me='auto'
          mb={{base: '20px', md: 'auto'}}
          as='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            error={errors.email}
            field='email'
            registerProps={register('email')}
            required={true}
            type='email'
            placeholder='mail@photon.rocks'
            inputStyles={{
              variant: 'auth',
              fontSize: 'sm',
              ms: {base: '0px', md: '0px'},
              mb: '24px',
              size: 'lg',
            }}
          >
            Email
          </Input>
          <Input
            error={errors.password}
            field='password'
            required={true}
            type={show ? 'text' : 'password'}
            registerProps={register('password')}
            inputStyles={{
              fontSize: 'sm',
              mb: '24px',
              size: 'lg',
              variant: 'auth',
            }}
            rightAddon={
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{cursor: 'pointer'}}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            }
          >
            Password
          </Input>

          <Button
            type='submit'
            fontSize='sm'
            variant={buttonVariant}
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            isLoading={isSubmitting}
            loadingText='Please wait...'
          >
            Sign In
          </Button>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
