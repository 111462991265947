import React from 'react';

import MenuLayout from '../../layouts/MenuLayout';
import RoleGuard from '../../layouts/RoleGuard';

import {adminRoles} from '../../variables/roles';
import Recruitment from './Recruitment';


export default function RecruitmentPage() {
  return (
    <RoleGuard page roles={[adminRoles.RolePhotonAdmin.key, adminRoles.RolePhotonSuperAdmin.key]}>
      <MenuLayout page={<Recruitment />} />
    </RoleGuard>
  );
}
