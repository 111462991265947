import React, {useCallback, useEffect, useState} from 'react';

import {Box, Tabs, TabList, TabPanels, Tab, TabPanel} from '@chakra-ui/react';

import CreativeListTable from './components/CreativeListTable';
import {columnsDataInactiveCreative} from './variables/columnsData';
import {useGetOffCreatives} from '../../api/staff-api';
import {CreativeListFilterTypes} from './types';
import {useLocation, useSearchParams} from 'react-router-dom';
import {decodeBooleanParam, decodeStringArrayParam} from 'utils/decode-url-params';
import {CreativeTableType} from './constants';

export default function OffCreativeList() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    search = '',
    country = '',
    service = '',
    deactivateReason = '',
  } = Object.fromEntries(queryParams.entries());
  const workingArea = decodeStringArrayParam(queryParams, 'workingArea');
  const adminFlag = decodeBooleanParam(queryParams, 'adminFlag');

  const [
    {data: creativesData},
    getCreatives,
  ] = useGetOffCreatives();

  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
  const [perPage, setPerPage] = useState(parseInt(searchParams.get('perPage')) || 10);
  const [sorting, setSorting] = useState({
    field: searchParams.get('sort') || 'name',
    order: parseInt(searchParams.get('sortOrder')) || 1,
  });
  const [filters, setFilters] = useState<CreativeListFilterTypes>({
    search,
    country,
    workingArea,
    service,
    deactivateReason,
    adminFlag,
  });

  const callGetCreatives = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: CreativeListFilterTypes,
    ) => {
      try {
        await getCreatives({
          params: {
            page,
            perPage,
            sorting,
            filters,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getCreatives],
  );

  useEffect(() => {
    callGetCreatives(page, perPage, sorting, filters);
  }, [page, perPage, sorting]);

  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      <Tabs>
        <TabList>
          <Tab>Onboarded</Tab>
          <Tab>New</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <CreativeListTable
              columnsData={columnsDataInactiveCreative}
              tableData={creativesData?.activeCreatives}
              total={creativesData?.totalActiveCreatives}
              page={page}
              perPage={perPage}
              setPerPage={setPerPage}
              setPage={setPage}
              filters={filters}
              setFilters={setFilters}
              callBack={callGetCreatives}
              type={CreativeTableType.OFF_ONBOARDED}
              setSorting={setSorting}
              sorting={sorting}
            />
          </TabPanel>

          <TabPanel>
            <CreativeListTable
              columnsData={columnsDataInactiveCreative}
              tableData={creativesData?.newCreatives}
              total={creativesData?.totalNewCreatives}
              page={page}
              perPage={perPage}
              setPerPage={setPerPage}
              setPage={setPage}
              filters={filters}
              setFilters={setFilters}
              callBack={callGetCreatives}
              type={CreativeTableType.OFF_NEW}
              setSorting={setSorting}
              sorting={sorting}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
