import MenuLayout from 'layouts/MenuLayout';
import RoleGuard from 'layouts/RoleGuard';
import {adminRoles} from 'variables/roles';
import Client from './Client';


export default function ClientPage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <MenuLayout page={<Client />} />
    </RoleGuard>
  );
}
