import {createContext, ReactElement, useCallback, useContext, useMemo, useState} from 'react';


const FormContext = createContext(null);
const confirmMessage = 'You have unsaved changes! Are you sure you want to leave?';

export const FormProvider = ({children}: { children: ReactElement }) => {
  const [isFormDirty, setFormDirty] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);

  const handleTabChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    (index: number, setSelectedTabIndex: Function) => {
      if (!isFormDirty) {
        setSelectedTabIndex(index);
        return;
      }

      const userConfirmed = window.confirm(confirmMessage);
      if (!userConfirmed) {
        return;
      }

      setFormDirty(false);
      setShouldReset(true);

      setSelectedTabIndex(index);
    },
    [isFormDirty],
  );

  const setIsFormDirty = useCallback(
    (isDirty: boolean) => {
      setFormDirty(isDirty);
    },
    [],
  );

  const value = useMemo(
    () =>
      ({
        isFormDirty,
        setIsFormDirty,
        shouldReset,
        setShouldReset,
        handleTabChange,
      }),
    [isFormDirty],
  );

  return <FormContext.Provider value={value}>
    {children}
  </FormContext.Provider>;
};

export const useFormContext = () => useContext(FormContext);
