import {useState} from 'react';

import {
  Button,
  Flex,
  Text,
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Input,
} from '@chakra-ui/react';

import {IoMdAddCircleOutline} from 'react-icons/io';
import {TfiExchangeVertical} from 'react-icons/tfi';
import {TbArrowsExchange2} from 'react-icons/tb';
import {MdDelete} from 'react-icons/md';

import {useGetEditorsOfPackage} from '../../../api/packages';
import {useChangeEditorOnProject} from '../../../api/projects';

type editorType = { name: string; _id: string };

const EditorHandler = ({project, refetch}: { project: any; refetch: Function }) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();
  const [newEditor, setNewEditor] = useState<{ name: string; _id: string }>();
  const [{data: editorsData, error: getEditorsError}, getEditors] = useGetEditorsOfPackage(
    project.packageId,
  );

  const [{loading}, changeEditor] = useChangeEditorOnProject(
    project._id,
  );

  const handleSelectEditor = (editor: editorType) => {
    setNewEditor(editor);
    onOpen();
  };

  const handleChangeEditorOnProject = async () => {
    try {
      await changeEditor({
        data: {
          editor: newEditor?._id ?? null,
        },
      });
      toast({
        title: 'Editor updated successfully',
        status: 'success',
        duration: 3000,
      });
      refetch();
    } catch {
      toast({
        title: 'Error during adding editor to project',
        status: 'error',
        duration: 3000,
      });
    }
    refetch();
    onClose();
  };

  const handleInputChange = async (value: any) => {
    try {
      await getEditors({params: {name: value}});
    } catch {
      console.log(getEditorsError);
    }
  };

  const filteredEditors
    = editorsData
    && editorsData.filter((editor: editorType) => editor.name !== project.editorObj.name);

  return (
    <Box>
      {project.manualEditingEnabled && (
        <Popover>
          <PopoverTrigger>
            {!project.editor ? (
              <Flex onClick={() => getEditors()} cursor='pointer' justifyContent='center' ml='5px'>
                <IoMdAddCircleOutline size='20' />
              </Flex>
            ) : (
              <Box onClick={() => getEditors()} w='20px' m='5px' cursor='pointer'>
                <TfiExchangeVertical size='20' />
              </Box>
            )}
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody>
              <Box minHeight='30px'>
                {project.editorObj.name && (
                  <Flex h='40px' alignItems='center'>
                    <Text fontWeight='bold'>{project.editorObj.name}</Text>
                    <Box ml='10px' onClick={handleChangeEditorOnProject} cursor='pointer'>
                      <MdDelete size={20} />
                    </Box>
                  </Flex>
                )}
                <Input
                  onChange={(e) => handleInputChange(e.target.value)}
                  h='30px'
                  mb='10px'
                  placeholder='Search by name...'
                ></Input>
                {filteredEditors && filteredEditors.length > 0
                  ? filteredEditors.map((editor: editorType) => (
                    <Text
                      _hover={{fontWeight: 'bold'}}
                      onClick={() => handleSelectEditor(editor)}
                      h='25px'
                      cursor='pointer'
                    >
                      {editor.name}
                    </Text>
                  ))
                  : `Ther's no other editor on this package`}
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent minWidth='1000px'>
          <ModalHeader>Are you sure, you want to change the editor on this project?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {newEditor && (
              <Flex justifyContent='center' alignItems='center'>
                <Text fontSize='20px' m='10px' fontWeight='bold'>
                  {project.editorObj.name ?? 'Empty spot'}
                </Text>
                <TbArrowsExchange2 size='80' color='#6b52ff' />
                <Text fontSize='20px' m='10px' fontWeight='bold'>
                  {newEditor.name}
                </Text>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant='photonMedium' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant='photonDark'
              mr={3}
              onClick={handleChangeEditorOnProject}
              isLoading={loading}
            >
              Change
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default EditorHandler;
