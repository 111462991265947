import {ReactJSXElement} from '@emotion/react/types/jsx-namespace';

import {useCallback} from 'react';

import {Navigate, useLocation} from 'react-router-dom';

import {useLocalStorage} from '../hooks/useLocalStorage';
import {useAuth} from '../hooks/useAuth';


export default function RoleGuard(
  {
    children,
    roles,
    page,
    additionalCondition = true,
  }: {
    children: ReactJSXElement | ReactJSXElement[],
    roles?: string[],
    page?: boolean,
    additionalCondition?: boolean,
  },
) {
  const {
    user,
  } = useAuth();
  const [previousPath, setPreviousPath] = useLocalStorage('previousPath', '/intel/projects');
  const location = useLocation();

  const hasRequiredRoles = useCallback(
    // eslint-disable-next-line
    (roles: string[], user: any) => roles
      && roles.some((role) => user?.roles?.includes(role)),
    [],
  );

  const goOn = hasRequiredRoles(roles, user) && additionalCondition;
  if (!goOn && location.pathname !== previousPath) {
    setPreviousPath(location.pathname);
  }

  return <>
    {
      goOn
      && children
    }
    {
      !goOn
      && page
      && <Navigate to="/no-permission" replace={true}/>
    }
  </>;
}
